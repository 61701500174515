/*
 * jQuery FlexSlider v2.6.4
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */
.flex-direction-nav a, .flex-direction-nav a:before {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}

.flex-container a:hover, .flex-slider a:hover {
  outline: 0;
}

.flex-control-nav, .flex-direction-nav, .slides, .slides > li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

.flexslider {
  padding: 0;
  position: relative;
  zoom: 1;
  border-radius: 4px;
  -webkit-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .flexslider .slides {
  display: block;
}

* html .flexslider .slides {
  height: 1%;
}

.no-js .flexslider .slides > li:first-child {
  display: block;
}

.flexslider .slides {
  zoom: 1;
}

.flexslider .slides img {
  width: 100%;
  display: block;
  height: auto;
  -moz-user-select: none;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.carousel li {
  margin-right: 5px;
}

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.flex-direction-nav a:before {
  font-family: flexslider-icon;
  font-size: 40px;
  display: inline-block;
  content: '\f001';
}

.flex-direction-nav a.flex-next:before {
  content: '\f002';
}

.flex-direction-nav .flex-prev {
  left: -50px;
}

.flex-direction-nav .flex-next {
  right: -50px;
  text-align: right;
}

.flexslider:hover .flex-direction-nav .flex-prev {
  opacity: .7;
  left: 10px;
}

.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}

.flexslider:hover .flex-direction-nav .flex-next {
  opacity: .7;
  right: 10px;
}

.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}

.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: .8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}

.flex-pauseplay a:before {
  font-family: flexslider-icon;
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play:before {
  content: '\f003';
}

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}

.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
}

.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}

.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }
}

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  -webkit-transition: height .5s ease-in-out;
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform .1s ease;
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  -webkit-transform: scale(1.3, 1.3);
          transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  -webkit-transition-duration: 50ms;
          transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  -webkit-transition-delay: 50ms;
          transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  -webkit-transition-duration: .1s;
          transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  -webkit-transition-duration: .15s;
          transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  -webkit-transition-delay: .15s;
          transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  -webkit-transition-duration: .2s;
          transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  -webkit-transition-delay: .2s;
          transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  -webkit-transition-duration: .25s;
          transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  -webkit-transition-delay: .25s;
          transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  -webkit-transition-duration: .3s;
          transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  -webkit-transition-delay: .3s;
          transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  -webkit-transition-duration: .35s;
          transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  -webkit-transition-delay: .35s;
          transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  -webkit-transition-duration: .4s;
          transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  -webkit-transition-delay: .4s;
          transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  -webkit-transition-duration: .45s;
          transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  -webkit-transition-delay: .45s;
          transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  -webkit-transition-duration: .5s;
          transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  -webkit-transition-delay: .5s;
          transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  -webkit-transition-duration: .55s;
          transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  -webkit-transition-delay: .55s;
          transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  -webkit-transition-duration: .6s;
          transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  -webkit-transition-delay: .6s;
          transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  -webkit-transition-duration: .65s;
          transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  -webkit-transition-delay: .65s;
          transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  -webkit-transition-duration: .7s;
          transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  -webkit-transition-delay: .7s;
          transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  -webkit-transition-duration: .75s;
          transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  -webkit-transition-delay: .75s;
          transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  -webkit-transition-duration: .8s;
          transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  -webkit-transition-delay: .8s;
          transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  -webkit-transition-duration: .85s;
          transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  -webkit-transition-delay: .85s;
          transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  -webkit-transition-duration: .9s;
          transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  -webkit-transition-delay: .9s;
          transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  -webkit-transition-duration: .95s;
          transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  -webkit-transition-delay: .95s;
          transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  -webkit-transition-duration: 1.05s;
          transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.05s;
          transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  -webkit-transition-duration: 1.1s;
          transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  -webkit-transition-duration: 1.15s;
          transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.15s;
          transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  -webkit-transition-duration: 1.2s;
          transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  -webkit-transition-duration: 1.25s;
          transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.25s;
          transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  -webkit-transition-duration: 1.3s;
          transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  -webkit-transition-duration: 1.35s;
          transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.35s;
          transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  -webkit-transition-duration: 1.4s;
          transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  -webkit-transition-duration: 1.45s;
          transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.45s;
          transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  -webkit-transition-duration: 1.5s;
          transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  -webkit-transition-duration: 1.55s;
          transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.55s;
          transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  -webkit-transition-duration: 1.6s;
          transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  -webkit-transition-duration: 1.65s;
          transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.65s;
          transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  -webkit-transition-duration: 1.7s;
          transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  -webkit-transition-duration: 1.75s;
          transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.75s;
          transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  -webkit-transition-duration: 1.8s;
          transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.8s;
          transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  -webkit-transition-duration: 1.85s;
          transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.85s;
          transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  -webkit-transition-duration: 1.9s;
          transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.9s;
          transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  -webkit-transition-duration: 1.95s;
          transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.95s;
          transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  -webkit-transition-duration: 2.05s;
          transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.05s;
          transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  -webkit-transition-duration: 2.1s;
          transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.1s;
          transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  -webkit-transition-duration: 2.15s;
          transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.15s;
          transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  -webkit-transition-duration: 2.2s;
          transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.2s;
          transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  -webkit-transition-duration: 2.25s;
          transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.25s;
          transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  -webkit-transition-duration: 2.3s;
          transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.3s;
          transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  -webkit-transition-duration: 2.35s;
          transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.35s;
          transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  -webkit-transition-duration: 2.4s;
          transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.4s;
          transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  -webkit-transition-duration: 2.45s;
          transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.45s;
          transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  -webkit-transition-duration: 2.5s;
          transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.5s;
          transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  -webkit-transition-duration: 2.55s;
          transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.55s;
          transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  -webkit-transition-duration: 2.6s;
          transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.6s;
          transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  -webkit-transition-duration: 2.65s;
          transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.65s;
          transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  -webkit-transition-duration: 2.7s;
          transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.7s;
          transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  -webkit-transition-duration: 2.75s;
          transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.75s;
          transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  -webkit-transition-duration: 2.8s;
          transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.8s;
          transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  -webkit-transition-duration: 2.85s;
          transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.85s;
          transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  -webkit-transition-duration: 2.9s;
          transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.9s;
          transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  -webkit-transition-duration: 2.95s;
          transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.95s;
          transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  -webkit-transition-duration: 3s;
          transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  -webkit-transition-delay: 3s;
          transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
          transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
          transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
          transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
          transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
          transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
          transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
          transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
          transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: opacity,transform;
  transition-property: opacity,transform,-webkit-transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  -webkit-transform: translate(0);
      -ms-transform: translate(0);
          transform: translate(0);
}

[data-aos=fade-up] {
  -webkit-transform: translateY(100px);
      -ms-transform: translateY(100px);
          transform: translateY(100px);
}

[data-aos=fade-down] {
  -webkit-transform: translateY(-100px);
      -ms-transform: translateY(-100px);
          transform: translateY(-100px);
}

[data-aos=fade-right] {
  -webkit-transform: translate(-100px);
      -ms-transform: translate(-100px);
          transform: translate(-100px);
}

[data-aos=fade-left] {
  -webkit-transform: translate(100px);
      -ms-transform: translate(100px);
          transform: translate(100px);
}

[data-aos=fade-up-right] {
  -webkit-transform: translate(-100px, 100px);
      -ms-transform: translate(-100px, 100px);
          transform: translate(-100px, 100px);
}

[data-aos=fade-up-left] {
  -webkit-transform: translate(100px, 100px);
      -ms-transform: translate(100px, 100px);
          transform: translate(100px, 100px);
}

[data-aos=fade-down-right] {
  -webkit-transform: translate(-100px, -100px);
      -ms-transform: translate(-100px, -100px);
          transform: translate(-100px, -100px);
}

[data-aos=fade-down-left] {
  -webkit-transform: translate(100px, -100px);
      -ms-transform: translate(100px, -100px);
          transform: translate(100px, -100px);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: opacity,transform;
  transition-property: opacity,transform,-webkit-transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  -webkit-transform: translate(0) scale(1);
      -ms-transform: translate(0) scale(1);
          transform: translate(0) scale(1);
}

[data-aos=zoom-in] {
  -webkit-transform: scale(0.6);
      -ms-transform: scale(0.6);
          transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  -webkit-transform: translateY(100px) scale(0.6);
      -ms-transform: translateY(100px) scale(0.6);
          transform: translateY(100px) scale(0.6);
}

[data-aos=zoom-in-down] {
  -webkit-transform: translateY(-100px) scale(0.6);
      -ms-transform: translateY(-100px) scale(0.6);
          transform: translateY(-100px) scale(0.6);
}

[data-aos=zoom-in-right] {
  -webkit-transform: translate(-100px) scale(0.6);
      -ms-transform: translate(-100px) scale(0.6);
          transform: translate(-100px) scale(0.6);
}

[data-aos=zoom-in-left] {
  -webkit-transform: translate(100px) scale(0.6);
      -ms-transform: translate(100px) scale(0.6);
          transform: translate(100px) scale(0.6);
}

[data-aos=zoom-out] {
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  -webkit-transform: translateY(100px) scale(1.2);
      -ms-transform: translateY(100px) scale(1.2);
          transform: translateY(100px) scale(1.2);
}

[data-aos=zoom-out-down] {
  -webkit-transform: translateY(-100px) scale(1.2);
      -ms-transform: translateY(-100px) scale(1.2);
          transform: translateY(-100px) scale(1.2);
}

[data-aos=zoom-out-right] {
  -webkit-transform: translate(-100px) scale(1.2);
      -ms-transform: translate(-100px) scale(1.2);
          transform: translate(-100px) scale(1.2);
}

[data-aos=zoom-out-left] {
  -webkit-transform: translate(100px) scale(1.2);
      -ms-transform: translate(100px) scale(1.2);
          transform: translate(100px) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  -webkit-transform: translate(0);
      -ms-transform: translate(0);
          transform: translate(0);
}

[data-aos=slide-up] {
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
}

[data-aos=slide-down] {
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
}

[data-aos=slide-right] {
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%);
}

[data-aos=slide-left] {
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%);
}

[data-aos^=flip][data-aos^=flip] {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

[data-aos=flip-left] {
  -webkit-transform: perspective(2500px) rotateY(-100deg);
          transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  -webkit-transform: perspective(2500px) rotateY(0);
          transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  -webkit-transform: perspective(2500px) rotateY(100deg);
          transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  -webkit-transform: perspective(2500px) rotateY(0);
          transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  -webkit-transform: perspective(2500px) rotateX(-100deg);
          transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  -webkit-transform: perspective(2500px) rotateX(0);
          transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  -webkit-transform: perspective(2500px) rotateX(100deg);
          transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  -webkit-transform: perspective(2500px) rotateX(0);
          transform: perspective(2500px) rotateX(0);
}

/*# sourceMappingURL=aos.css.map*/
.ba-slider {
  position: relative;
}

.ba-slider img {
  position: absolute;
  width: 100%;
}

.ba-slider-image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ba-slider-before-image {
  z-index: 5;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.ba-control {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  overflow: hidden;
}

.ba-control-area {
  position: absolute;
  z-index: 1;
  width: 26px;
  height: 100%;
  left: 0;
  margin-left: -13px;
}

.ba-control-bar {
  width: 4px;
  height: 100%;
  margin: 0 auto;
  background: #fff;
}

.ba-left-arrow, .ba-right-arrow, .ba-control-handle {
  top: 50%;
  position: absolute;
  display: none;
}

.ba-left-arrow, .ba-right-arrow {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  margin-top: -5px;
}

.ba-left-arrow {
  left: -4px;
  border-right: 5px solid #fff;
}

.ba-right-arrow {
  right: -4px;
  border-left: 5px solid #fff;
}

.ba-control-handle {
  height: 60px;
  width: 8px;
  margin: -30px 0 0 -4px;
  left: 50%;
  background-color: #f90;
  cursor: move;
}

/*====================================
=            GRID GLOBALS            =
====================================*/
/*=====  End of GRID GLOBALS  ======*/
/*===================================
=            TRANSITIONS            =
===================================*/
/*=====  End of TRANSITIONS  ======*/
/*=================================
=            STRUCTURE            =
=================================*/
/*=====  End of STRUCTURE  ======*/
/*==============================
=            COLORS            =
==============================*/
/*=====  End of COLORS  ======*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

header,
main,
footer {
  float: left;
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*:after, *:before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.headerWrap,
.mainWrap,
.footerWrap {
  position: relative;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
div,
ul,
input,
blockquote,
span,
button,
i,
b,
em,
strong {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

body {
  overflow-x: hidden;
  position: relative;
}

/*=====================================
=            MEDIA QUERIES            =
=====================================*/
/*=====  End of MEDIA QUERIES  ======*/
/*==============================
=            MIXINS            =
==============================*/
/*=====  End of MIXINS  ======*/
/*========================================
=            EXTEND SELECTORS            =
========================================*/
.container,
.row {
  *zoom: 1;
}

.container:before,
.row:before, .container:after,
.row:after {
  content: " ";
  display: table;
}

.container:after,
.row:after {
  clear: both;
}

.home_banner_wrapper, .buttons_holder a:after, .btn_out a:after, .middle_column {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.headerWrap,
.mainWrap,
.footerWrap, .main_header, .block_section, .home_banner_section, .overlay, .home_services_section, .section_title, .home_projects_section, .projects_big_thumb, .projects_item, .case_studies_wrapper, .case_studies_boxes, .box, .box__thumb, .box__content, .our_clients_section, .our_clients_wrapper, .our_clients_holder, .our_clients_slider, .our_projects, .our_projects ul, .client_box, .home_insight_section, .home_insight_wrapper, .insight_box, .insight_box__thumb, .insight_box__content, .insight_box__author, .our_process_big_thumb, .ceo_wrapper, .home_bottom_banner_section, .home_bottom_banner_section .home_banner_thumb, .paged_after, .full_height_wrapper, .contact_title, .case_studies_info, .case_studies_article, .blog_slider_wrapper, .article_wrapper, .abous_us_item, .services_list, .services_list ul, .services_list li, .services_list_items, .projects_list, .case_studies_sub_wrapper, .bar_title, .right_content, .iphones_holder, .bottom_footer, .bottom_footer .line_gotop_holder {
  float: left;
  width: 100%;
  clear: both;
}

.home_banner_section .home_banner_thumb img, .box__thumb img, .our_clients_section .home_banner_thumb img, .client_box__thumb img, .insight_box__thumb img, .ceo_thumb img, .home_bottom_banner_section .home_banner_thumb img, .blog_slider_wrapper .home_banner_thumb img, .single_banner .insight_box__author__thumb img, .insight_box__author__thumb img, .main_footer .home_banner_thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*=====  End of EXTEND SELECTORS  ======*/
.column-1 {
  width: 8.33333%;
}

.column-2 {
  width: 16.66667%;
}

.column-3 {
  width: 25%;
}

.column-4 {
  width: 33.33333%;
}

.column-5 {
  width: 41.66667%;
}

.column-6 {
  width: 50%;
}

.column-7 {
  width: 58.33333%;
}

.column-8 {
  width: 66.66667%;
}

.column-9 {
  width: 75%;
}

.column-10 {
  width: 83.33333%;
}

.column-11 {
  width: 91.66667%;
}

.column-12 {
  width: 100%;
}

.wow {
  visibility: hidden;
}

[class*='column-'] {
  float: left;
  min-height: 1px;
}

.has_gutter [class*='column-'] {
  padding: 0 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .container {
    max-width: 732px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .container {
    max-width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1246px;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .show-mob {
    display: none !important;
  }
  .show-tab {
    display: none !important;
  }
  .show-tab-big {
    display: none !important;
  }
}

.hidden-mob {
  display: block;
}

.hidden {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .column-mob-1 {
    width: 8.33333%;
  }
  .column-mob-2 {
    width: 16.66667%;
  }
  .column-mob-3 {
    width: 25%;
  }
  .column-mob-4 {
    width: 33.33333%;
  }
  .column-mob-5 {
    width: 41.66667%;
  }
  .column-mob-6 {
    width: 50%;
  }
  .column-mob-7 {
    width: 58.33333%;
  }
  .column-mob-8 {
    width: 66.66667%;
  }
  .column-mob-9 {
    width: 75%;
  }
  .column-mob-10 {
    width: 83.33333%;
  }
  .column-mob-11 {
    width: 91.66667%;
  }
  .column-mob-12 {
    width: 100%;
  }
  .show-mob {
    display: block !important;
  }
  .hidden-mob {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .column-tab-1 {
    width: 8.33333%;
  }
  .column-tab-2 {
    width: 16.66667%;
  }
  .column-tab-3 {
    width: 25%;
  }
  .column-tab-4 {
    width: 33.33333%;
  }
  .column-tab-5 {
    width: 41.66667%;
  }
  .column-tab-6 {
    width: 50%;
  }
  .column-tab-7 {
    width: 58.33333%;
  }
  .column-tab-8 {
    width: 66.66667%;
  }
  .column-tab-9 {
    width: 75%;
  }
  .column-tab-10 {
    width: 83.33333%;
  }
  .column-tab-11 {
    width: 91.66667%;
  }
  .column-tab-12 {
    width: 100%;
  }
  .show-tab {
    display: block !important;
  }
  .hidden-tab {
    display: none !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .column-tab-big-1 {
    width: 8.33333%;
  }
  .column-tab-big-2 {
    width: 16.66667%;
  }
  .column-tab-big-3 {
    width: 25%;
  }
  .column-tab-big-4 {
    width: 33.33333%;
  }
  .column-tab-big-5 {
    width: 41.66667%;
  }
  .column-tab-big-6 {
    width: 50%;
  }
  .column-tab-big-7 {
    width: 58.33333%;
  }
  .column-tab-big-8 {
    width: 66.66667%;
  }
  .column-tab-big-9 {
    width: 75%;
  }
  .column-tab-big-10 {
    width: 83.33333%;
  }
  .column-tab-big-11 {
    width: 91.66667%;
  }
  .column-tab-big-12 {
    width: 100%;
  }
  .show-tablet-big {
    display: block !important;
  }
  .hidden-tablet-big {
    display: none !important;
  }
}

.is-full-width {
  width: 100vw !important;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  max-width: none;
}

.no_padding {
  padding: 0 !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

main {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.no-flexboxlegacy body {
  display: block !important;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: black;
  position: relative;
  overflow-x: hidden;
}

input,
textarea,
select,
option {
  font-family: 'Montserrat', sans-serif;
  opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder,
option::-webkit-input-placeholder {
  font-family: 'Montserrat', sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder,
option:-moz-placeholder {
  font-family: 'Montserrat', sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder,
option::-moz-placeholder {
  font-family: 'Montserrat', sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder,
option:-ms-input-placeholder {
  font-family: 'Montserrat', sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

.iphone input,
.iphone textarea,
.iphone select,
.iphone option {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0;
}

select::-ms-expand {
  display: none;
}

/*=================================
=            Normalize            =
=================================*/
mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

/* Do not show the outline on the skip link target. */
#main[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*=====  End of Normalize  ======*/
.headerWrap {
  padding: 14px 0;
  position: fixed;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  border-bottom: 1px solid transparent;
}

.main_header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.headerWrap.is--sticky {
  padding: 14px 0;
  background: rgba(26, 50, 68, 0.96);
  border-bottom-color: rgba(26, 50, 68, 0.82);
}

.logo_wrapper {
  width: 165px;
  height: 34px;
  float: left;
  margin-top: 4px;
}

.right_header {
  float: right;
}

.right_header .hamburger {
  float: right;
  cursor: pointer;
}

.right_header .hamburger:hover span:nth-child(2):after {
  width: 6px;
}

.right_header .hamburger:hover span:nth-child(2):before {
  width: 25px;
}

.right_header .hamburger:hover span:after {
  width: 6px;
}

.right_header .hamburger:hover span:before {
  width: 25px;
}

.right_header .hamburger span {
  float: left;
  clear: both;
  width: 35px;
  margin-bottom: 7px;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.right_header .hamburger span:last-child {
  margin-bottom: 0;
}

.right_header .hamburger span:nth-child(2):after {
  float: right;
}

.right_header .hamburger span:nth-child(2):before {
  float: left;
}

.right_header .hamburger span:after {
  content: "";
  width: 25px;
  height: 2px;
  -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  float: left;
  background-color: #fff;
}

.right_header .hamburger span:before {
  content: "";
  -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  float: right;
  width: 6px;
  height: 2px;
  background-color: #fff;
}

body.menu--active {
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  width: 100%;
  float: left;
}

.nav_wrapper {
  float: left;
  margin-top: 14px;
}

.nav_wrapper li {
  float: left;
  margin-right: 20px;
}

.nav_wrapper li a {
  font-size: 14px;
  font-weight: 400;
  float: left;
  padding: 0 10px;
  color: #fff;
  text-decoration: none;
  letter-spacing: 0;
}

.nav_wrapper li a:after {
  content: "";
  height: 2px;
  width: 0;
  display: block;
  -webkit-transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  margin-top: 2px;
  background-color: #1a3347;
}

.nav_wrapper li a:hover:after {
  width: 100%;
}

.list_links_header {
  float: right;
}

.list_links_header a {
  text-decoration: none;
  letter-spacing: 0;
  float: left;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 11px 12px;
  color: #26415e;
  width: 153px;
  height: 42px;
  border-radius: 3px;
  border: solid 1px #26415e;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.list_links_header a:before {
  content: "";
  background: url(../images/arrow_right_blue.svg) no-repeat;
  width: 12px;
  height: 12px;
  float: right;
  margin-top: 3px;
  margin-left: 3px;
}

.list_links_header a:hover {
  background-color: #26415e;
  color: #fff;
}

.list_links_header a:hover:before {
  background: url(../images/arrow_right_white_small.svg) no-repeat;
}

.white_header .headerWrap {
  background: #fff;
}

.white_header .nav_wrapper li a {
  color: #1A3347;
  font-weight: 500;
}

.white_header .list_links_header a {
  font-weight: 500;
}

.white_header .list_links_header a:after {
  background-color: rgba(60, 178, 184, 0.77);
}

.main_header.header-up .headerWrap {
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
}

@media only screen and (min-width: 1200px) {
  .headerWrap .menu-item-has-children {
    position: relative;
    padding-bottom: 10px;
  }
  .headerWrap .menu-item-has-children:hover ul {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    -webkit-transition: none;
    transition: none;
  }
  .headerWrap .menu-item-has-children ul {
    opacity: 0;
    visibility: hidden;
    width: 324px;
    float: left;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 36px;
    -webkit-box-shadow: 0px -5px 14px 0px rgba(0, 0, 0, 0.13);
            box-shadow: 0px -5px 14px 0px rgba(0, 0, 0, 0.13);
    -webkit-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.0s, z-index 0s linear 0.00s;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.0s, z-index 0s linear 0.00s;
  }
  .headerWrap .menu-item-has-children ul:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
  }
  .headerWrap .menu-item-has-children li {
    float: left;
    width: 100%;
    margin-right: 0;
  }
  .headerWrap .menu-item-has-children li a {
    float: left;
    padding: 26px 20px;
    height: 67px;
    font-size: 14px;
    color: #1a3347;
    background-color: #fff;
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
    padding-left: 74px;
    background-repeat: no-repeat;
    background-position: 20px;
    -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  }
  .headerWrap .menu-item-has-children li a:after {
    display: none;
  }
  .headerWrap .menu-item-has-children li a:hover {
    background-color: #f7f7f7;
  }
  .headerWrap .menu-item-has-children .web_software_item a {
    background-image: url(../images/web-dev-copy.svg);
  }
  .headerWrap .menu-item-has-children .integrations_item a {
    background-image: url(../images/integra.svg);
  }
  .headerWrap .menu-item-has-children .automation_item a {
    background-image: url(../images/settings-gears.svg);
  }
  .headerWrap .menu-item-has-children .cloud_item a {
    background-image: url(../images/cloud-computing.svg);
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .headerWrap .menu-item-has-children {
    position: relative;
    padding-bottom: 10px;
  }
  .headerWrap .menu-item-has-children:hover ul {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    -webkit-transition: none;
    transition: none;
  }
  .headerWrap .menu-item-has-children ul {
    opacity: 0;
    visibility: hidden;
    width: 324px;
    float: left;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 36px;
    -webkit-box-shadow: 0px -5px 14px 0px rgba(0, 0, 0, 0.13);
            box-shadow: 0px -5px 14px 0px rgba(0, 0, 0, 0.13);
    -webkit-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.0s, z-index 0s linear 0.00s;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.0s, z-index 0s linear 0.00s;
  }
  .headerWrap .menu-item-has-children ul:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
  }
  .headerWrap .menu-item-has-children li {
    float: left;
    width: 100%;
    margin-right: 0;
  }
  .headerWrap .menu-item-has-children li a {
    float: left;
    padding: 26px 20px;
    height: 67px;
    font-size: 14px;
    color: #1a3347;
    background-color: #fff;
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
    padding-left: 74px;
    background-repeat: no-repeat;
    background-position: 20px;
    -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  }
  .headerWrap .menu-item-has-children li a:after {
    display: none;
  }
  .headerWrap .menu-item-has-children li a:hover {
    background-color: #f7f7f7;
  }
  .headerWrap .menu-item-has-children .web_software_item a {
    background-image: url(../images/web-dev-copy.svg);
  }
  .headerWrap .menu-item-has-children .integrations_item a {
    background-image: url(../images/integra.svg);
  }
  .headerWrap .menu-item-has-children .automation_item a {
    background-image: url(../images/settings-gears.svg);
  }
  .headerWrap .menu-item-has-children .cloud_item a {
    background-image: url(../images/cloud-computing.svg);
  }
}

/* font-family: 'Rubik', sans-serif;
font-family: 'Source Sans Pro', sans-serif; */
body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.home_banner_section {
  height: 100%;
  position: relative;
}

.home_banner_section .home_banner_thumb {
  width: 100%;
  height: 100%;
  position: relative;
}

.home_banner_section .overlay {
  background-color: rgba(20, 31, 60, 0.7);
}

.home_banner_wrapper {
  float: left;
  text-align: center;
}

.home_banner_wrapper h1 {
  margin-top: 40px;
  color: #fff;
  line-height: 1.54em;
  font-size: 26px;
  font-weight: 200;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.home_banner_wrapper h1 b {
  font-size: 36px;
  font-weight: bold;
  display: block;
}

.home_banner_wrapper h1:after {
  content: "";
  display: block;
  width: 85px;
  height: 2px;
  background-color: #fff;
  margin-top: 47px;
}

.home_banner_wrapper p {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.25em;
  margin-top: 32px;
  color: #fff;
}

.buttons_holder {
  display: block;
  margin-top: 50px;
  text-align: center;
}

.buttons_holder a {
  width: 276px;
  padding: 21px 0;
  display: inline-block;
  margin-right: 57px;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
  border-radius: 3px;
  border-left: 2px solid #1FC887;
  border-right: 2px solid #00B7F3;
  position: relative;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  background-size: 101% 2px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=), url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
  background-image: -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(#1FC887), to(#00B7F3)), -webkit-gradient(linear, left top, right top, from(#1FC887), to(#00B7F3));
  background-image: linear-gradient(to right, #1FC887 0%, #00B7F3 100%), linear-gradient(to right, #1FC887 0%, #00B7F3 100%);
  z-index: 2;
}

.buttons_holder a:after {
  float: left;
  z-index: -1;
  content: " ";
  width: 0;
  height: 100%;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  background-size: 101%;
  background-image: -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(#1FC887), to(#00B7F3)), -webkit-gradient(linear, left top, right top, from(#1FC887), to(#00B7F3));
  background-image: linear-gradient(to right, #1FC887 0%, #00B7F3 100%), linear-gradient(to right, #1FC887 0%, #00B7F3 100%);
  -webkit-transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.buttons_holder a:hover:after {
  width: 100%;
}

.buttons_holder a:last-child {
  margin-right: 0;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.go_bottom {
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 28px;
  z-index: 13;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  background-size: 24px;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  padding: 7px 8px;
  width: 28px;
  height: 42px;
  border-radius: 14px;
  border: solid 3px #ffffff;
}

.go_bottom:after {
  content: " ";
  width: 7px;
  height: 12px;
  bottom: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  position: absolute;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.go_bottom:hover:after {
  bottom: 7px;
}

.green_btn {
  background-image: url(../images/down-arrow-green.svg);
}

.home_services_section {
  position: relative;
  padding: 70px 0 65px;
}

.base {
  left: 0px;
  top: 0px;
  position: absolute;
  height: 62px;
  width: 100%;
}

#base3 {
  left: 0;
}

.triangle {
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: -webkit-gradient(linear, right top, left bottom, color-stop(50%, rgba(26, 50, 68, 0.92)), color-stop(52%, transparent));
  background: -webkit-linear-gradient(right top, rgba(26, 50, 68, 0.92) 50%, transparent 52%);
  background: linear-gradient(to left bottom, rgba(26, 50, 68, 0.92) 50%, transparent 52%);
}

.triangle2 {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: -webkit-gradient(linear, left top, right bottom, color-stop(50%, rgba(26, 50, 68, 0.92)), color-stop(52%, transparent));
  background: -webkit-linear-gradient(left top, rgba(26, 50, 68, 0.92) 50%, transparent 52%);
  background: linear-gradient(to right bottom, rgba(26, 50, 68, 0.92) 50%, transparent 52%);
}

.gecko .triangle,
.no-object-fit .triangle {
  background: -webkit-gradient(linear, right top, left bottom, color-stop(50%, rgba(26, 50, 68, 0.92)), color-stop(50%, transparent));
  background: -webkit-linear-gradient(right top, rgba(26, 50, 68, 0.92) 50%, transparent 50%);
  background: linear-gradient(to left bottom, rgba(26, 50, 68, 0.92) 50%, transparent 50%);
}

.gecko .triangle2,
.no-object-fit .triangle2 {
  background: -webkit-gradient(linear, left top, right bottom, color-stop(50%, rgba(26, 50, 68, 0.92)), color-stop(50%, transparent));
  background: -webkit-linear-gradient(left top, rgba(26, 50, 68, 0.92) 50%, transparent 50%);
  background: linear-gradient(to right bottom, rgba(26, 50, 68, 0.92) 50%, transparent 50%);
}

.gecko .our_clients_section .triangle,
.no-object-fit .our_clients_section .triangle {
  background: -webkit-gradient(linear, right top, left bottom, color-stop(50%, #fff), color-stop(50%, transparent));
  background: -webkit-linear-gradient(right top, #fff 50%, transparent 50%);
  background: linear-gradient(to left bottom, #fff 50%, transparent 50%);
}

.gecko .our_clients_section .triangle2,
.no-object-fit .our_clients_section .triangle2 {
  background: -webkit-gradient(linear, left top, right bottom, color-stop(50%, #fff), color-stop(50%, transparent));
  background: -webkit-linear-gradient(left top, #fff 50%, transparent 50%);
  background: linear-gradient(to right bottom, #fff 50%, transparent 50%);
}

.section_title {
  text-align: center;
}

.section_title h1 {
  color: #fff;
  line-height: 1.2em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 36px;
  font-weight: bold;
}

.section_title h1:after {
  content: "";
  display: block;
  width: 62px;
  height: 3px;
  background-color: #fff;
  margin-top: 20px;
}

.section_title p {
  font-size: 14px;
  font-weight: 500;
  margin: 0 auto;
  margin-top: 22px;
  color: #fff;
  line-height: 1.72em;
  max-width: 813px;
}

.page_title h1 {
  color: #fff;
  line-height: 1.54em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 36px;
  font-weight: 300;
}

.page_title h1:after {
  display: none;
}

.page_title p {
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69em;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  max-width: 815px;
  margin-top: 16px;
}

.list_services2 {
  max-width: 1091px !important;
  display: block;
  margin: 0 auto;
  float: none;
  padding: 28px;
  text-align: center;
}

.list_services2__item {
  float: left;
  width: 237px;
  margin-top: 32px;
  margin-right: 28px;
  height: 250px;
}

.list_services2__item a {
  float: left;
  height: 100%;
  width: 100%;
  padding: 30px 19px;
  border: 1px solid #E8E8E8;
  border-radius: 3px;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.list_services2__item a:hover {
  border-color: transparent;
  background: #FFFFFF;
  -webkit-box-shadow: 0 17px 34px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 17px 34px 0 rgba(0, 0, 0, 0.08);
}

.list_services2__item:last-child {
  margin-right: 0;
}

.list_services2 .item_thumb {
  max-width: 92px;
  max-height: 80px;
  margin: 0 auto;
}

.list_services2 .item_content {
  width: 100%;
  margin-top: 25px;
  height: 60px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.list_services2 .item_content h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6em;
  color: #2B2B2B;
  letter-spacing: 0;
}

.list_services {
  padding: 0 50px 0 150px;
}

.list_services li:last-child h4:after {
  display: none;
}

.list_services__item {
  float: left;
  width: 25%;
  margin-top: 40px;
}

.list_services .item_thumb {
  width: 120px;
  height: 120px;
  border: 1px solid #fff;
  border-radius: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: -60px;
}

.list_services .item_content {
  width: 100%;
  margin-top: 20px;
  color: #fff;
}

.list_services .item_content h4 {
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.list_services .item_content h4:after {
  content: "";
  width: -webkit-calc(100% - 27px);
  width: calc(100% - 27px);
  height: 1px;
  margin-right: 9px;
  background-color: #fff;
  float: right;
  margin-top: 15px;
}

.list_services .item_content h4:before {
  content: "";
  float: left;
  width: 10px;
  margin-right: -10px;
  margin-top: 25px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 100%;
  background-color: #fff;
}

.list_services .item_content ul {
  float: left;
  margin-top: 12px;
  clear: both;
}

.list_services .item_content ul li {
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  font-weight: 200;
}

.list_services .item_content p {
  float: left;
  clear: both;
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  font-weight: 200;
}

.list_services .item_content p:first-of-type {
  margin-top: 22px;
}

.black_title h1 {
  color: #26415e;
}

.black_title a {
  color: #26415e;
}

.black_title p {
  color: #26415e;
}

.white_title h1 {
  color: #fff;
}

.white_title p {
  color: #fff;
}

.no_border h1:after {
  display: none;
}

.black_text p,
.black_text h1 {
  color: #162A39;
}

.colored_line_title h1:after {
  background-image: -webkit-linear-gradient(149deg, #43B9EA 0%, #47B9DD 6%, #4BBAC4 21%, #4DBAAF 36%, #4DBA9F 51%, #4DBB94 67%, #4DBC8D 83%, #4DBC8B 100%);
  background-image: linear-gradient(-59deg, #43B9EA 0%, #47B9DD 6%, #4BBAC4 21%, #4DBAAF 36%, #4DBA9F 51%, #4DBB94 67%, #4DBC8D 83%, #4DBC8B 100%);
}

.home_projects_section {
  position: relative;
  padding-top: 95px;
}

.home_projects_section .base {
  height: 20px;
}

.home_projects_section .triangle {
  background: -webkit-gradient(linear, right top, left bottom, color-stop(50%, #141f3c), color-stop(52%, transparent));
  background: -webkit-linear-gradient(right top, #141f3c 50%, transparent 52%);
  background: linear-gradient(to left bottom, #141f3c 50%, transparent 52%);
}

.home_projects_section .triangle2 {
  background: -webkit-gradient(linear, left top, right bottom, color-stop(50%, #141f3c), color-stop(52%, transparent));
  background: -webkit-linear-gradient(left top, #141f3c 50%, transparent 52%);
  background: linear-gradient(to right bottom, #141f3c 50%, transparent 52%);
}

.gecko .home_projects_section .base .triangle,
.no-object-fit .home_projects_section .base .triangle {
  background: -webkit-gradient(linear, right top, left bottom, color-stop(50%, #141f3c), color-stop(50%, transparent));
  background: -webkit-linear-gradient(right top, #141f3c 50%, transparent 50%);
  background: linear-gradient(to left bottom, #141f3c 50%, transparent 50%);
}

.gecko .home_projects_section .base .triangle2,
.no-object-fit .home_projects_section .base .triangle2 {
  background: -webkit-gradient(linear, left top, right bottom, color-stop(50%, #141f3c), color-stop(50%, transparent));
  background: -webkit-linear-gradient(left top, #141f3c 50%, transparent 50%);
  background: linear-gradient(to right bottom, #141f3c 50%, transparent 50%);
}

.projects_big_thumb {
  margin-top: 8px;
  padding-bottom: 104px;
}

.projects_big_thumb .clearleft .projects_item__content {
  padding-right: 50px;
}

.projects_big_thumb .pull-right .projects_item__content {
  padding-left: 50px;
}

.projects_item {
  margin-top: 84px;
}

.projects_item__content {
  float: left;
  width: 100%;
  min-height: 100px;
  position: relative;
}

.projects_item__content h3 {
  font-weight: 700;
  font-size: 20px;
  color: #1A3347;
  letter-spacing: 0;
  line-height: 1.14em;
}

.projects_item__content p {
  font-size: 16px;
  font-weght: 300;
  color: #162A39;
  letter-spacing: 0;
  line-height: 1.70em;
  margin-top: 5px;
}

.case_studies_wrapper {
  position: relative;
  background-color: #141f3c;
  padding: 60px 0 55px 0;
}

.case_studies_boxes {
  margin-top: 30px;
}

.case_studies_boxes .row {
  margin-left: -17px;
  margin-right: -17px;
}

.case_studies_boxes .column-6 {
  padding: 0 17px;
}

.box {
  height: 340px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.box:hover .box__content {
  bottom: 0;
}

.box:hover .box__content h3 {
  margin-bottom: 0;
}

.box__thumb {
  height: 100%;
}

.box__thumb img {
  font-family: 'object-fit:cover;';
}

.box__content {
  position: absolute;
  left: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  bottom: -94px;
  padding: 48px 40px;
  padding-right: 165px;
  height: auto;
  color: #fff;
  font-weight: 700;
}

.box__content p {
  text-transform: uppercase;
  font-size: 12px;
}

.box__content h3 {
  font-size: 24px;
  font-weight: 400;
  margin-top: 12px;
  line-height: 1.14em;
  margin-bottom: 30px;
  -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
}

.box__content a {
  margin-top: 30px;
  width: 209px;
  float: left;
  padding: 14px 13px;
  border: 2px solid #0098ff;
  border-radius: 3px;
  text-decoration: none;
  background-image: url(../images/right-long-arrow.svg);
  background-size: 33px 13px;
  background-repeat: no-repeat;
  background-position: 93%;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.box__content a:hover {
  background-position: 96%;
  background-color: #0098ff;
}

.box__content a span {
  font-weight: 600;
  color: #fff;
}

.box .hover_second h3 {
  font-size: 22px;
  font-weight: bold;
}

.box .hover_second a {
  border-color: #3FB29A;
}

.box .hover_second a:hover {
  background-color: #3FB29A;
}

.btn_out {
  position: relative;
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 70px;
}

.btn_out a {
  display: inline-block;
  margin: 0 auto;
  width: 276px;
  padding: 21px 19px;
  border: 2px solid #0098ff;
  border-radius: 3px;
  text-decoration: none;
  text-align: left;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  position: relative;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  background-size: 101% 2px;
  z-index: 2;
}

.btn_out a:after {
  float: left;
  z-index: -1;
  content: " ";
  width: 0;
  height: 100%;
  background: #0098ff;
  -webkit-transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.btn_out a:hover:after {
  width: 100%;
}

.btn_out a span {
  color: #fff;
  float: left;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  background-image: url(../images/right-long-arrow.svg);
  background-size: 33px 13px;
  background-repeat: no-repeat;
  background-position: 100%;
}

.btn_out.black_title a {
  border-color: #26415e;
}

.btn_out.black_title a:hover {
  background-color: #26415e !important;
}

.btn_out.black_title a:hover:after {
  display: none;
}

.btn_out.black_title a:hover span {
  color: #fff;
  background-image: url(../images/right-long-arrow.svg);
}

.btn_out.black_title a span {
  color: #26415e;
  background-image: url(../images/right-long-arrow-blue.svg);
}

/*----------  REMOVE STYLE  ----------*/
.our_clients_section {
  position: relative;
}

.our_clients_section .base {
  top: 0;
  height: 30px;
  z-index: 9;
}

.our_clients_section .triangle {
  background: -webkit-gradient(linear, right top, left bottom, color-stop(50%, #fff), color-stop(52%, transparent));
  background: -webkit-linear-gradient(right top, #fff 50%, transparent 52%);
  background: linear-gradient(to left bottom, #fff 50%, transparent 52%);
}

.our_clients_section .triangle2 {
  background: -webkit-gradient(linear, left top, right bottom, color-stop(50%, #fff), color-stop(52%, transparent));
  background: -webkit-linear-gradient(left top, #fff 50%, transparent 52%);
  background: linear-gradient(to right bottom, #fff 50%, transparent 52%);
}

.our_clients_section .home_banner_thumb {
  width: 100%;
  height: 100%;
  position: relative;
}

.our_clients_section .home_banner_thumb img {
  font-family: 'object-fit:cover;';
}

.our_clients_section .overlay {
  background: rgba(26, 50, 68, 0.96);
}

.our_clients_holder {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  margin-top: 40px;
}

.our_clients_holder h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size: 36px;
  color: #FFFFFF;
  line-height: 1.25em;
  max-width: 970px;
  margin: 0 auto;
}

.our_clients_holder p {
  margin-top: 20px;
  font-weight: 300;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  line-height: 1.67em;
}

.our_clients_holder img {
  max-width: 535px;
  margin: 0 auto;
  margin-top: 45px;
}

.our_clients_slider {
  position: relative;
  margin-bottom: 100px;
}

.our_clients_slider .overlay {
  background-color: #161A1D;
  opacity: .42;
}

.our_clients_slider .slides li {
  height: 700px;
  position: relative;
}

.our_clients_slider .flex-direction-nav {
  display: none;
}

.our_clients_slider .flex-control-nav {
  bottom: -100px;
  margin-top: -24px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  width: auto;
}

.our_clients_slider .flex-control-nav li {
  width: 80px;
  margin-right: 40px;
  margin-top: 44px;
}

.our_clients_slider .flex-control-nav li:last-child {
  margin-right: 0;
}

.our_clients_slider .flex-control-nav li img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  font-family: "object-fit:cover;";
  border-radius: 100%;
  background: #D8D8D8;
  position: relative;
  z-index: 3;
}

.our_clients_slider .flex-control-nav li.flex-active {
  position: relative;
}

.our_clients_slider .flex-control-nav li.flex-active:before {
  content: "";
  -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  width: 36px;
  height: 36px;
  background-color: #fff;
  display: block;
  position: absolute;
  top: -26px;
  left: 50%;
  z-index: 2;
  -webkit-transform: translateX(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}

.our_projects {
  margin-bottom: 60px;
}

.our_projects ul:first-child {
  margin-top: 38px;
}

.our_projects li {
  float: left;
  width: 25%;
  text-align: center;
  margin-top: 35px;
  min-height: 63px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 88px;
}

.our_projects li:nth-child(4n+1) {
  clear: left;
}

.our_projects .btn_out {
  margin-top: 50px;
}

.client_box__thumb {
  width: 174px;
  height: 174px;
  float: left;
  margin-right: 60px;
  position: relative;
}

.client_box__thumb span {
  display: block;
  background: url(../images/right-quote.svg) no-repeat;
  width: 80px;
  height: 65px;
  position: absolute;
  background-size: 80px 65px;
  top: 0;
  right: -26px;
}

.client_box__thumb img {
  border: 2px solid transparent;
  border-radius: 100%;
  font-family: 'object-fit:cover;';
}

.client_box__content {
  float: left;
  width: -webkit-calc(100% - (174px + 60px));
  width: calc(100% - (174px + 60px));
  margin-top: 25px;
}

.client_box__content h2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 27px;
  line-height: 1.15em;
  font-weight: 600;
  font-style: italic;
}

.client_box__content p {
  font-size: 12px;
  margin-top: 12px;
  font-weight: 300;
  margin-left: 3px;
}

.client_box a {
  text-decoration: none;
  color: #fff;
}

/*----------  END REMOVE STYLE  ----------*/
.home_insight_section {
  background: #F3F3F3;
  padding-top: 50px;
  padding-bottom: 50px;
}

.home_insight_section .row.has_gutter {
  margin-right: -17.5px;
  margin-left: -17.5px;
}

.home_insight_section .column-4 {
  padding: 0 17.5px;
}

.home_insight_wrapper .btn_out {
  margin-top: 60px;
}

.insight_box {
  background: #FFFFFF;
  border-radius: 6px;
  margin-top: 60px;
  min-height: 320px;
  position: relative;
}

.insight_box__thumb {
  height: 230px;
}

.insight_box__thumb img {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  font-family: "object-fit:cover;";
}

.insight_box__content {
  padding: 40px 38px;
}

.insight_box__content span {
  font-size: 12px;
  color: #1A3347;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  float: left;
  width: 100%;
}

.insight_box__content h3 {
  font-weight: bold;
  font-size: 22px;
  color: #1A3347;
  letter-spacing: 0;
  line-height: 1.14em;
}

.insight_box__content p {
  font-weight: 400;
  font-size: 18px;
  color: #838484;
  letter-spacing: 0;
  line-height: 1.72em;
  margin-top: 11px;
}

.insight_box__author {
  position: absolute;
  bottom: 20px;
}

.insight_box__author__thumb {
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 11px;
}

.insight_box__author__content {
  float: left;
  width: -webkit-calc(100% - 101px);
  width: calc(100% - 101px);
}

.insight_box__author__content h4 {
  font-weight: 500;
  font-size: 14px;
  color: #1A3347;
  letter-spacing: 0;
  line-height: 1.72em;
}

.insight_box__author__content span {
  font-weight: 500;
  font-size: 14px;
  color: #838484;
  letter-spacing: 0;
  line-height: 1.72em;
}

.analytics-platform {
  height: 755px;
  width: 100%;
  float: left;
}

.analytics-platform .visual {
  height: 100%;
}

html body .analytics-platform .visual {
  top: -30px !important;
}

.analytics-platform {
  zoom: 63%;
  z-index: 9;
  margin-bottom: -20px;
}

html.no-object-fit .wrapper {
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}

html.no-object-fit .animations-block .static-line2 {
  top: 210px;
}

html.no-object-fit .animations-block .line-1 {
  bottom: 240px;
}

html.no-object-fit .animations-block .static-line3 {
  top: 150px;
}

.imageJS_section {
  background: #fff;
  -webkit-filter: unset;
          filter: unset;
  height: auto;
  padding: 97px 0;
}

.imageJS_section .no_border {
  width: 673px;
  margin: 0 auto;
  display: block;
  float: none;
}

@media only screen and (max-width: 767px) {
  .imageJS_section .no_border {
    width: 100%;
  }
}

.imageJS_section .no_border h1 {
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #26415e;
  text-align: left;
  display: block;
}

.imageJS_section .no_border p {
  margin-top: 10px;
  max-width: 100%;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: #141f3c;
}

.colored_bg {
  background-color: #f3f3f3;
}

.our_process_big_thumb {
  padding: 93px 0 144px;
}

.our_process_big_thumb .no_border {
  width: 930px;
  margin: 0 auto;
  display: block;
  float: none;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .our_process_big_thumb .no_border {
    width: 100%;
  }
}

.our_process_big_thumb .no_border h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.19em;
  color: #26415e;
}

.our_process_big_thumb .no_border p {
  margin-top: 20px;
  max-width: 100%;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 1.69em;
  color: #141f3c;
}

.list_process {
  margin-top: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0;
}

.list_process li {
  width: auto;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.list_process li:after {
  content: "";
  background: url(../images/arrow-proces.png) no-repeat;
  display: block;
  background-size: contain;
  width: 56px;
  height: 116px;
  position: absolute;
}

.list_process li:nth-child(1):after {
  right: -133px;
  top: 0px;
}

.list_process li:nth-child(2):after {
  right: -113px;
  top: -36px;
}

.list_process li:nth-child(3):after {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  left: -113px;
  top: 36px;
}

.list_process .item_thumb {
  margin-left: 0;
}

.list_process .item_content h3 {
  font-size: 23px;
  font-weight: bold;
  line-height: 1.79em;
  text-align: left;
  color: #141f3c;
}

.list_process .item_content h3:before {
  content: "";
  width: 33px;
  height: 33px;
  float: left;
  margin-right: 5px;
  background: url(../images/icon-logo.svg) no-repeat;
  background-size: cover;
  margin-top: -12px;
}

.list_process .item_content h2 {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.69em;
  text-align: left;
  color: #141f3c;
  margin-left: 38px;
  text-transform: none;
}

.list_process .list_services__item {
  margin-top: 60px;
}

.ceo_wrapper {
  padding: 135px 0 114px;
}

.ceo_holder {
  width: 850px;
  margin: 0 auto;
  display: block;
}

.ceo_thumb {
  width: 130px;
  height: 130px;
  float: left;
  margin-right: 40px;
}

.ceo_thumb img {
  font-family: 'object-fit:cover;';
  border-radius: 100%;
  border: 1px solid transparent;
}

.ceo_content {
  float: left;
  width: -webkit-calc( 100% - 170px);
  width: calc( 100% - 170px);
  color: #0C0C0C;
}

.ceo_content p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 28px;
  line-height: 1.10em;
  margin-top: 36px;
  font-style: italic;
}

.ceo_content p:first-of-type {
  margin-top: 0;
}

.ceo_content h4 {
  margin-top: 50px;
  font-size: 12px;
  font-weight: 300;
  float: left;
  color: #000;
  line-height: 1.2em;
}

.ceo_content h4 b {
  font-weight: bold;
}

.ceo_content img {
  float: left;
}

.overlay_special {
  height: 100vh;
}

.overlay_special h1 {
  margin-top: 0;
}

.overlay_special .home_banner_thumb {
  height: 100%;
}

.about_overlay_special .home_banner_thumb img {
  opacity: .8;
}

.about_overlay_special .overlay {
  background-image: unset;
}

.base_special {
  top: -60px;
  height: 60px;
}

.base_special .triangle {
  background: -webkit-gradient(linear, right top, left bottom, color-stop(50%, rgba(26, 50, 68, 0)), color-stop(53%, #fff));
  background: -webkit-linear-gradient(right top, rgba(26, 50, 68, 0) 50%, #fff 53%);
  background: linear-gradient(to left bottom, rgba(26, 50, 68, 0) 50%, #fff 53%);
}

.base_special .triangle2 {
  background: -webkit-gradient(linear, left top, right bottom, color-stop(50%, rgba(26, 50, 68, 0)), color-stop(53%, #fff));
  background: -webkit-linear-gradient(left top, rgba(26, 50, 68, 0) 50%, #fff 53%);
  background: linear-gradient(to right bottom, rgba(26, 50, 68, 0) 50%, #fff 53%);
}

.gecko .base_special .triangle,
.no-object-fit .base_special .triangle {
  background: -webkit-gradient(linear, right top, left bottom, color-stop(50%, rgba(26, 50, 68, 0)), color-stop(50%, #fff));
  background: -webkit-linear-gradient(right top, rgba(26, 50, 68, 0) 50%, #fff 50%);
  background: linear-gradient(to left bottom, rgba(26, 50, 68, 0) 50%, #fff 50%);
}

.gecko .base_special .triangle2,
.no-object-fit .base_special .triangle2 {
  background: -webkit-gradient(linear, left top, right bottom, color-stop(50%, rgba(26, 50, 68, 0)), color-stop(50%, #fff));
  background: -webkit-linear-gradient(left top, rgba(26, 50, 68, 0) 50%, #fff 50%);
  background: linear-gradient(to right bottom, rgba(26, 50, 68, 0) 50%, #fff 50%);
}

.home_bottom_banner_section {
  height: 550px;
}

.home_bottom_banner_section .home_banner_thumb {
  height: 100%;
}

.home_bottom_banner_section .home_banner_thumb img {
  font-family: 'object-fit:cover;';
}

.home_bottom_banner_content {
  margin: 52px auto;
  width: 798px;
  padding: 45px 76px;
  height: 445px;
  background-image: -webkit-linear-gradient(31deg, #43B9EA 0%, #47B9DD 2%, #4BBAC4 16%, #4DBAAF 36%, #4DBA9F 51%, #4DBB94 67%, #4DBC8D 83%, #4DBC8B 100%);
  background-image: linear-gradient(59deg, #43B9EA 0%, #47B9DD 2%, #4BBAC4 16%, #4DBAAF 36%, #4DBA9F 51%, #4DBB94 67%, #4DBC8D 83%, #4DBC8B 100%);
  position: absolute;
  z-index: 3;
  left: 50%;
  -webkit-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
  text-align: left;
  color: #fff;
}

.home_bottom_banner_content h1 {
  font-size: 36px;
  font-weight: 200;
  line-height: 1.4em;
}

.home_bottom_banner_content h1 b {
  font-weight: bold;
}

.home_bottom_banner_content h1:after {
  content: "";
  display: block;
  width: 62px;
  height: 3px;
  background-color: #fff;
  margin-top: 30px;
}

.home_bottom_banner_content p {
  margin-top: 29px;
  font-size: 14px;
  line-height: 1.72em;
}

.about_partners_section {
  height: 610px;
  padding: 46px  0 55px 0;
  max-width: 815px;
  margin: 0 auto;
}

.about_partners_section .black_text h1 {
  color: #000;
  line-height: 1.1em;
  width: 815px;
  display: block;
  padding: 0 100px;
  margin: 0 auto;
  font-weight: bold;
}

.about_partners_section .black_text h1:after {
  display: table;
  margin: 0 auto;
  margin-top: 30px;
}

.about_partners_section .black_text p {
  color: #000;
  font-weight: 600;
  max-width: 100%;
}

.about_partners_section a {
  font-size: 14px;
  font-weight: 500;
  color: #1391f6;
  letter-spacing: 0;
  position: relative;
  text-decoration: none;
  margin-top: 37px;
  display: inline-block;
}

.about_partners_section a:before {
  position: absolute;
  left: 0;
  bottom: -1px;
  float: left;
  content: " ";
  width: 0;
  height: 1px;
  background-color: #1391f6;
  -webkit-transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.about_partners_section a:after {
  content: "";
  color: #1391f6;
  background: url(../images/arrow_right_blue.svg) no-repeat;
  float: right;
  background-size: 8px;
  margin-top: 1px;
  width: 12px;
  height: 16px;
  margin-left: 12px;
}

.about_partners_section a:hover:before {
  width: -webkit-calc(100% - 23px);
  width: calc(100% - 23px);
}

.about_partners_section .partners_icon {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#21CE95), color-stop(98%, #10C5CD));
  background-image: -webkit-linear-gradient(top, #21CE95 0%, #10C5CD 98%);
  background-image: linear-gradient(-180deg, #21CE95 0%, #10C5CD 98%);
  width: 145px;
  height: 145px;
  margin: 0 auto;
  border-radius: 100%;
  margin-bottom: 45px;
  text-align: center;
}

.about_partners_section .partners_icon img {
  margin-top: 58px;
}

.footer_base_special {
  height: 15px;
  top: 0px;
  z-index: 2;
}

.footer_base_special .triangle {
  background: -webkit-gradient(linear, right bottom, left top, color-stop(50%, transparent), color-stop(52%, #fff));
  background: -webkit-linear-gradient(right bottom, transparent 50%, #fff 52%);
  background: linear-gradient(to left top, transparent 50%, #fff 52%);
}

.footer_base_special .triangle2 {
  background: -webkit-gradient(linear, left bottom, right top, color-stop(50%, transparent), color-stop(52%, #fff));
  background: -webkit-linear-gradient(left bottom, transparent 50%, #fff 52%);
  background: linear-gradient(to right top, transparent 50%, #fff 52%);
}

.gecko .footer_base_special .triangle,
.no-object-fit .footer_base_special .triangle {
  background: -webkit-gradient(linear, right bottom, left top, color-stop(50%, transparent), color-stop(50%, #fff));
  background: -webkit-linear-gradient(right bottom, transparent 50%, #fff 50%);
  background: linear-gradient(to left top, transparent 50%, #fff 50%);
}

.gecko .footer_base_special .triangle2,
.no-object-fit .footer_base_special .triangle2 {
  background: -webkit-gradient(linear, left bottom, right top, color-stop(50%, transparent), color-stop(50%, #fff));
  background: -webkit-linear-gradient(left bottom, transparent 50%, #fff 50%);
  background: linear-gradient(to right top, transparent 50%, #fff 50%);
}

.footer_base_special2 {
  height: 15px;
  top: -15px;
}

.footer_base_special2 .triangle {
  background: -webkit-gradient(linear, right bottom, left top, color-stop(50%, transparent), color-stop(52%, #48b9ae));
  background: -webkit-linear-gradient(right bottom, transparent 50%, #48b9ae 52%);
  background: linear-gradient(to left top, transparent 50%, #48b9ae 52%);
}

.footer_base_special2 .triangle2 {
  background: -webkit-gradient(linear, left bottom, right top, color-stop(50%, transparent), color-stop(52%, #49bb91));
  background: -webkit-linear-gradient(left bottom, transparent 50%, #49bb91 52%);
  background: linear-gradient(to right top, transparent 50%, #49bb91 52%);
}

.gecko .footer_base_special2 .triangle,
.no-object-fit .footer_base_special2 .triangle {
  background: -webkit-gradient(linear, right bottom, left top, color-stop(50%, rgba(26, 50, 68, 0.96)), color-stop(50%, #48b9ae));
  background: -webkit-linear-gradient(right bottom, rgba(26, 50, 68, 0.96) 50%, #48b9ae 50%);
  background: linear-gradient(to left top, rgba(26, 50, 68, 0.96) 50%, #48b9ae 50%);
}

.gecko .footer_base_special2 .triangle2,
.no-object-fit .footer_base_special2 .triangle2 {
  background: -webkit-gradient(linear, left bottom, right top, color-stop(50%, rgba(26, 50, 68, 0.96)), color-stop(50%, #49bb91));
  background: -webkit-linear-gradient(left bottom, rgba(26, 50, 68, 0.96) 50%, #49bb91 50%);
  background: linear-gradient(to right top, rgba(26, 50, 68, 0.96) 50%, #49bb91 50%);
}

.left_contact {
  float: left;
  width: 40%;
  padding-left: 45px;
  margin-top: 60px;
}

.left_contact .section_title {
  text-align: left;
}

.left_contact h1 {
  line-height: 1.2em;
  display: block;
}

.left_contact ul li {
  font-size: 14px;
  color: #FFFFFF;
  line-height: 1.25em;
  margin-top: 30px;
  position: relative;
  padding-left: 30px;
}

.left_contact ul li:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  display: block;
  left: 0;
  top: 5px;
  background: #fff;
}

.right_contact {
  float: right;
  width: 640px;
  height: 475px;
  border: 2px solid rgba(255, 255, 255, 0.17);
  padding: 52px 59px;
  position: relative;
  color: #FFF;
}

.right_contact .row {
  margin-left: -25px;
  margin-right: -25px;
}

.right_contact .has_gutter [class*=column-] {
  padding: 0 25px;
}

.right_contact label {
  width: 100%;
  float: left;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.right_contact textarea,
.right_contact input {
  background-color: transparent;
  border: 0;
  outline: 0;
  width: 100%;
  max-width: 100%;
  padding: 0;
  height: 28px;
  float: left;
  margin-bottom: 40px;
  border-bottom: 1px solid #fff;
  padding-bottom: 8px;
  font-weight: 200;
  font-size: 16px;
  color: #EFEFEF;
  letter-spacing: 0.07px;
}

.right_contact textarea::-webkit-input-placeholder,
.right_contact input::-webkit-input-placeholder {
  font-weight: 200;
  font-size: 16px;
  color: #EFEFEF;
  letter-spacing: 0.07px;
}

.right_contact textarea:-moz-placeholder,
.right_contact input:-moz-placeholder {
  font-weight: 200;
  font-size: 16px;
  color: #EFEFEF;
  letter-spacing: 0.07px;
}

.right_contact textarea::-moz-placeholder,
.right_contact input::-moz-placeholder {
  font-weight: 200;
  font-size: 16px;
  color: #EFEFEF;
  letter-spacing: 0.07px;
}

.right_contact textarea:-ms-input-placeholder,
.right_contact input:-ms-input-placeholder {
  font-weight: 200;
  font-size: 16px;
  color: #EFEFEF;
  letter-spacing: 0.07px;
}

.right_contact textarea {
  margin-bottom: 0;
  max-height: 80px;
}

.right_contact textarea::-webkit-scrollbar {
  display: none;
}

.right_contact input[type=submit] {
  position: absolute;
  bottom: 52px;
  left: 59px;
  width: 276px;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  height: 61px;
  -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
}

.right_contact input[type=submit]:focus, .right_contact input[type=submit]:hover {
  background-color: #fff;
  color: #49bb8d;
}

.contact_section {
  height: 760px;
  padding: 140px 0 78px;
}

.fixOverflow {
  overflow: hidden;
  max-width: 100%;
}

.showmain {
  -webkit-animation: fadeInDown 1.5s;
}

.title_max_width {
  max-width: 813px;
  margin: 0 auto;
  float: none;
}

/*===========================================
=            Case Studies Single            =
===========================================*/
.is-fixed {
  position: fixed;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
}

.paged_after {
  position: relative;
  top: 100vh;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
}

.footer_has_space {
  top: 100vh;
  background-color: #fff;
}

.full_height_wrapper {
  height: 100vh;
  position: relative;
  background-size: 100%;
  background-size: cover;
  background-position: center;
}

.white_bg {
  background-color: #fff;
}

.banner_content {
  text-align: left;
}

.banner_content h1 {
  font-weight: 800;
  font-size: 48px;
  color: #FFFFFF;
  letter-spacing: -3px;
  width: 965px;
  line-height: 1.32em;
}

.banner_content h1:after {
  margin-top: 32px;
  position: absolute;
  left: -112px;
  top: 0;
  width: 80px;
}

.banner_content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.58em;
}

.darken_color {
  color: #161616;
}

.has_left_space {
  padding-left: 70px;
  padding-right: 30px;
}

.has_left_space h1 {
  font-weight: 800;
}

/*=====  End of Case Studies Single  ======*/
.lighter_title h1 {
  font-size: 36px;
  font-weight: 300;
}

.lighter_title a {
  text-decoration: none;
  border-bottom: 2px solid #26415e;
}

.lighter_title p {
  font-size: 20px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .lighter_title p {
    font-size: 18px;
  }
}

/*=============================
=            Contact            =
==============================*/
.contact_wrapper.is--active {
  bottom: 0;
}

.contact_wrapper {
  width: 100%;
  bottom: -100vh;
  position: fixed;
  height: 100vh;
  padding-top: 75px;
  overflow-y: scroll;
  -webkit-transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  background: url(../images/map-bg.png) no-repeat bottom right #fff !important;
}

.contact_wrapper::-webkit-scrollbar {
  display: none;
}

.contact_wrapper .close_btn {
  width: 67px;
  height: 67px;
  position: absolute;
  top: 42px;
  right: 52px;
  background-color: #F2F2F2;
  border-radius: 100%;
  cursor: pointer;
}

.contact_wrapper .close_btn span {
  width: 37px;
  height: 2px;
  background-color: #404040;
  display: block;
  position: absolute;
  left: 45%;
  top: 50%;
}

.contact_wrapper .close_btn span:nth-child(1) {
  -webkit-transform: rotate(-45deg) translateX(-10px) translateY(-10px);
      -ms-transform: rotate(-45deg) translateX(-10px) translateY(-10px);
          transform: rotate(-45deg) translateX(-10px) translateY(-10px);
}

.contact_wrapper .close_btn span:nth-child(2) {
  -webkit-transform: rotate(45deg) translateX(-10px) translateY(10px);
      -ms-transform: rotate(45deg) translateX(-10px) translateY(10px);
          transform: rotate(45deg) translateX(-10px) translateY(10px);
}

.contact_title {
  padding-bottom: 35px;
  border-bottom: 1px solid #DFDFDF;
}

.contact_title h4 {
  font-weight: 300;
  font-size: 24px;
  color: #26415e;
  letter-spacing: 0;
  line-height: 1.29em;
}

.contact_title h2 {
  font-size: 48px;
  color: #26415e;
  letter-spacing: 0;
  line-height: 1em;
  margin-top: 10px;
}

.contact_form {
  width: -webkit-calc(100% - 3px);
  width: calc(100% - 3px);
  float: left;
}

.contact_form textarea,
.contact_form select,
.contact_form input {
  width: 100%;
  height: 50px;
  padding: 15px 13px;
  float: left;
  outline: 0;
  margin-top: 20px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 3px;
  font-size: 18px;
  font-family: 'OpenSans', sans-serif;
  color: #8E8E8E;
  letter-spacing: 0;
}

.contact_form textarea::-webkit-input-placeholder,
.contact_form select::-webkit-input-placeholder,
.contact_form input::-webkit-input-placeholder {
  font-size: 18px;
  font-family: 'OpenSans', sans-serif;
  color: #8E8E8E;
  letter-spacing: 0;
}

.contact_form textarea:-moz-placeholder,
.contact_form select:-moz-placeholder,
.contact_form input:-moz-placeholder {
  font-size: 18px;
  font-family: 'OpenSans', sans-serif;
  color: #8E8E8E;
  letter-spacing: 0;
}

.contact_form textarea::-moz-placeholder,
.contact_form select::-moz-placeholder,
.contact_form input::-moz-placeholder {
  font-size: 18px;
  font-family: 'OpenSans', sans-serif;
  color: #8E8E8E;
  letter-spacing: 0;
}

.contact_form textarea:-ms-input-placeholder,
.contact_form select:-ms-input-placeholder,
.contact_form input:-ms-input-placeholder {
  font-size: 18px;
  font-family: 'OpenSans', sans-serif;
  color: #8E8E8E;
  letter-spacing: 0;
}

.contact_form select {
  background: url(../images/bottom-arrow-select.png) no-repeat 96% #fff;
  /*for firefox*/
  -moz-appearance: none;
  /*for chrome*/
  -webkit-appearance: none;
}

.contact_form select::-ms-expand {
  display: none;
}

.contact_form input[type="submit"] {
  float: right;
  width: 150px;
  border-radius: 3px;
  background: url(../images/right-long-arrow.svg) no-repeat #0098ff 102px center;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 14px;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  margin-right: 15px;
}

.contact_form input[type="submit"]:hover {
  background-position-x: 92%;
}

.contact_form textarea {
  resize: none;
  height: 160px;
  overflow-x: hidden;
}

.contact_small_title {
  font-weight: bold;
  font-size: 12px;
  margin-top: 65px;
  margin-bottom: 10px;
  color: #26415e;
  letter-spacing: 0;
}

.contact_info {
  margin-left: 123px;
}

.contact_info h3 {
  font-size: 18px;
  color: #26415e;
  letter-spacing: 0;
  line-height: 1.25em;
  font-family: 'OpenSans', sans-serif;
  position: relative;
}

.contact_info h3:before {
  content: "";
  position: absolute;
  left: -30px;
  top: 2px;
  width: 15px;
  height: 20px;
  background: url(../images/location.svg) no-repeat;
}

.contact_info ul {
  margin-top: 18px;
}

.contact_info li {
  font-weight: bold;
  font-size: 14px;
  color: #0098ff;
  letter-spacing: 0;
  line-height: 25px;
  font-family: 'OpenSans', sans-serif;
  padding-left: 40px;
  position: relative;
}

.contact_info li:before {
  content: "";
  width: 30px;
  height: 1px;
  position: absolute;
  left: 0;
  top: 13px;
  background-color: #0098ff;
}

.contact_copyright {
  position: static;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 40px;
}

.contact_copyright p {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  color: #919191;
  letter-spacing: 0;
}

/*====  End of Contact  ====*/
/*=============================
=         Case Studies        =
==============================*/
.case_studies_info {
  margin-top: 59px;
  margin-bottom: 40px;
}

.case_studies_info_thumb {
  text-align: center;
}

.case_studies_article {
  height: 600px;
  position: relative;
  margin-top: 2px;
  overflow: hidden;
}

.case_studies_article:hover .cs_article_thumb {
  background-color: transparent;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

.case_studies_article:hover .cs_article_thumb:after {
  opacity: 0;
}

.case_studies_article .cs_article_thumb {
  background-size: cover;
  height: 100%;
  -webkit-transition: all 6s ease-out;
  transition: all 6s ease-out;
  background-position: center;
  position: relative;
}

.case_studies_article .cs_article_thumb:after {
  content: "";
  background-color: rgba(20, 31, 60, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transition: all 6s ease-out;
  transition: all 6s ease-out;
}

.case_studies_article_holder {
  position: absolute;
  width: 100%;
  float: left;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.case_studies_article_content {
  max-width: 1000px;
}

.case_studies_article_content h4 {
  text-transform: none;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25em;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .case_studies_article_content h4 {
    margin-left: 0;
  }
}

.case_studies_article_content h1 {
  font-size: 36px;
  color: #FFFFFF;
  letter-spacing: 0;
  margin-top: 12px;
  line-height: 1.33em;
  font-weight: 700;
}

.case_studies_article_content a {
  margin-top: 45px;
  width: 280px;
  float: left;
  padding: 20px 18px;
  border: 1px solid #fff;
  border-radius: 3px;
  text-decoration: none;
  background-image: url(../images/right-long-arrow.svg);
  background-size: 33px 13px;
  background-repeat: no-repeat;
  background-position: 93%;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.case_studies_article_content a:hover {
  background-position: 96%;
  background-color: #0098ff;
  border-color: #0098ff;
}

.case_studies_article_content a span {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}

.case-studies-banner .page_title p {
  margin-top: 15px;
}

.case_studies_partners_section .black_text h1 {
  font-size: 30px;
  line-height: 1.34em;
}

.case_studies_partners_section .black_text p {
  font-weight: 500;
  margin-top: 30px;
}

.case_studies_partners_section .partners_icon {
  margin-bottom: 30px;
}

/*====  End of Case Studies  ====*/
.mt80 {
  margin-top: 80px;
}

.mt71 {
  margin-top: 71px;
}

.case-studies-footer {
  margin-top: 2px;
}

.gfield {
  padding: 0 15px;
  float: left;
  width: 50%;
}

#field_1_1 input,
#field_1_1 select {
  margin-top: 0;
}

#field_1_10,
#field_1_11,
#field_1_1 {
  width: 100%;
}

.gfield_label {
  display: none;
}

.color_black h1 {
  color: #000;
}

.color_black p {
  color: #767676;
}

.gfield_description.validation_message {
  float: right;
  color: red;
  margin-top: 5px;
}

.validation_error {
  padding: 0 15px;
  color: red;
  margin-bottom: 10px;
}

.gform_confirmation_wrapper {
  padding: 0 15px;
  color: #3fb29a;
}

#gform_ajax_spinner_1 {
  float: left;
  margin-left: 15px;
  margin-top: 20px;
}

.loadmore span {
  text-align: center;
  background: none !important;
}

.insight_cat {
  padding-top: 60px;
  margin-top: 0;
  padding-bottom: 80px;
  background: #efefef;
}

.insight_cat .insight_box {
  margin-top: 32px;
}

.blog_slider_wrapper {
  position: relative;
}

.blog_slider_wrapper {
  position: relative;
  height: 638px;
}

.blog_slider_wrapper .overlay {
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.blog_slider_wrapper .our_clients_holder {
  z-index: 4;
}

.blog_slider_wrapper .our_clients_holder h1 {
  font-family: Montserrat,sans-serif;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  font-size: 36px;
  color: #fff;
  line-height: 1.22em;
  max-width: 910px;
  margin: 0 auto;
}

.blog_slider_wrapper .our_clients_holder a {
  width: 140px;
  height: 44px;
  display: inline-block;
  margin-top: 46px;
  border: 2px solid #fff;
  padding: 13px 11px  0 15px;
  text-decoration: none;
  color: #fff;
  border-radius: 3px;
  font-weight: 500;
  -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
}

.blog_slider_wrapper .our_clients_holder a:hover {
  padding-right: 7px;
}

.blog_slider_wrapper .our_clients_holder a span {
  width: 100%;
  float: left;
  font-size: 14px;
  background: url(../images/right-long-arrow.svg) no-repeat right center;
  text-align: left;
}

.blog_slider_wrapper .home_banner_thumb {
  position: relative;
  z-index: 1;
  height: 100%;
}

.blog_slider_wrapper .home_banner_thumb img {
  font-family: 'object-fit:cover;';
}

.blog_slider_wrapper .slides li {
  height: 638px;
  position: relative;
}

.blog_slider_wrapper .flex-direction-nav {
  display: none;
}

.blog_slider_wrapper .flex-control-paging li {
  margin-right: 13px;
}

.blog_slider_wrapper .flex-control-paging li:last-child {
  margin-right: 0;
}

.blog_slider_wrapper .flex-control-paging li a {
  width: 9px;
  height: 9px;
  background-color: #fff;
}

.blog_slider_wrapper .flex-control-paging li a.flex-active {
  background-color: #3FB29A;
}

.blog_slider_wrapper .flex-control-nav {
  bottom: 41px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  width: auto;
}

.single_banner .overlay {
  background: rgba(0, 0, 0, 0.4);
}

.single_banner .our_clients_holder {
  margin-top: -20px;
}

.single_banner .our_clients_holder span {
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 1.2em;
  margin-bottom: 10px;
  display: block;
}

.single_banner .insight_box__author {
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 150px;
  bottom: 74px;
  text-align: center;
  z-index: 4;
}

.single_banner .insight_box__author__thumb {
  float: none;
  margin: 0 auto;
  display: block;
}

.single_banner .insight_box__author__thumb img {
  border-radius: 100%;
  font-family: 'object-fit:cover;';
}

.single_banner .insight_box__author__content {
  width: 150px;
  margin-top: 10px;
}

.single_banner .insight_box__author__content span,
.single_banner .insight_box__author__content h4 {
  color: #fff;
  font-weight: 400;
}

.insight_box__author__thumb img {
  border-radius: 100%;
  font-family: 'object-fit:cover;';
}

.article_wrapper {
  padding: 80px 0 40px 0;
  font-size: 18px;
  font-weight: 300;
  color: #1A3347;
  letter-spacing: 0;
  line-height: 1.7em;
  font-family: Montserrat,sans-serif;
}

.article_wrapper p {
  margin-bottom: 40px;
  letter-spacing: -0.2px;
}

.article_wrapper blockquote {
  margin: 20px 0 20px;
}

.article_wrapper h1,
.article_wrapper h2,
.article_wrapper h3,
.article_wrapper h4,
.article_wrapper h5,
.article_wrapper h6 {
  font-weight: bold;
  margin-top: 40px;
}

.article_wrapper figcaption {
  font-size: 14px;
  font-weight: 400;
  color: #1A3347;
  line-height: 1.32em;
  margin-bottom: 40px;
}

.article_wrapper ul {
  list-style: none;
  margin-top: 20px;
}

.article_wrapper ul li {
  font-weight: 500;
  font-size: 14px;
  color: #1A3347;
  line-height: 1.32em;
  margin-top: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.article_wrapper ul li:before {
  content: " - ";
  padding-right: 10px;
}

.article_wrapper blockquote {
  position: relative;
  display: inline-block;
}

.article_wrapper blockquote:after {
  content: "";
  width: 2px;
  height: 100%;
  background-color: #3FB29A;
  position: absolute;
  left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.article_wrapper blockquote p {
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  margin: 0;
}

.article_wrapper blockquote span {
  float: left;
  width: 100%;
  clear: both;
  margin-top: 5px;
}

.article_wrapper pre {
  margin: 0;
  padding: 35px;
}

.article_wrapper code {
  background: #eee;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
  width: 100%;
  float: left;
}

.article_wrapper h1 {
  font-size: 32px;
}

.article_wrapper h2 {
  font-size: 28px;
}

.article_wrapper h3 {
  font-size: 22px;
}

.article_wrapper h4 {
  font-size: 18px;
  margin-bottom: 6px;
}

.article_wrapper h5 {
  font-size: 16px;
}

.article_wrapper h6 {
  font-size: 14px;
}

.article_wrapper ol {
  list-style: decimal;
  padding-left: 20px;
  margin-top: 40px;
}

.article_wrapper ol li {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #1A3347;
}

.article_wrapper img {
  width: auto;
  text-align: center;
  height: auto;
}

.article_wrapper iframe {
  width: 100%;
  text-align: center;
  margin-bottom: 40px !important;
}

.article_wrapper .fb_iframe_widget {
  float: left;
  width: 100%;
}

.article_wrapper a {
  font-weight: 500;
  color: #3CB2B8;
}

.article_container {
  margin: 0 auto;
  display: block;
  max-width: 700px;
  width: 100%;
  font-size: 18px;
}

.single_go_btn {
  bottom: 34px;
  width: 16px;
  height: 16px;
  background-size: cover;
}

.single_go_btn:hover {
  bottom: 30px;
}

.footer_base_special_colored {
  height: 15px;
  top: 0px;
}

.footer_base_special_colored .triangle {
  background: -webkit-gradient(linear, right bottom, left top, color-stop(50%, rgba(26, 50, 68, 0.96)), color-stop(52%, #fbfbfb));
  background: -webkit-linear-gradient(right bottom, rgba(26, 50, 68, 0.96) 50%, #fbfbfb 52%);
  background: linear-gradient(to left top, rgba(26, 50, 68, 0.96) 50%, #fbfbfb 52%);
}

.footer_base_special_colored .triangle2 {
  background: -webkit-gradient(linear, left bottom, right top, color-stop(50%, rgba(26, 50, 68, 0.96)), color-stop(52%, #fbfbfb));
  background: -webkit-linear-gradient(left bottom, rgba(26, 50, 68, 0.96) 50%, #fbfbfb 52%);
  background: linear-gradient(to right top, rgba(26, 50, 68, 0.96) 50%, #fbfbfb 52%);
}

.gecko .footer_base_special_colored .triangle,
.no-object-fit .footer_base_special_colored .triangle {
  background: -webkit-gradient(linear, right bottom, left top, color-stop(50%, rgba(26, 50, 68, 0.96)), color-stop(50%, #fbfbfb));
  background: -webkit-linear-gradient(right bottom, rgba(26, 50, 68, 0.96) 50%, #fbfbfb 50%);
  background: linear-gradient(to left top, rgba(26, 50, 68, 0.96) 50%, #fbfbfb 50%);
}

.gecko .footer_base_special_colored .triangle2,
.no-object-fit .footer_base_special_colored .triangle2 {
  background: -webkit-gradient(linear, left bottom, right top, color-stop(50%, rgba(26, 50, 68, 0.96)), color-stop(50%, #fbfbfb));
  background: -webkit-linear-gradient(left bottom, rgba(26, 50, 68, 0.96) 50%, #fbfbfb 50%);
  background: linear-gradient(to right top, rgba(26, 50, 68, 0.96) 50%, #fbfbfb 50%);
}

.footer_base_special_colored2 {
  height: 15px;
  top: 0px;
}

.footer_base_special_colored2 .triangle {
  background: -webkit-gradient(linear, right bottom, left top, color-stop(50%, rgba(26, 50, 68, 0.96)), color-stop(52%, #efefef));
  background: -webkit-linear-gradient(right bottom, rgba(26, 50, 68, 0.96) 50%, #efefef 52%);
  background: linear-gradient(to left top, rgba(26, 50, 68, 0.96) 50%, #efefef 52%);
}

.footer_base_special_colored2 .triangle2 {
  background: -webkit-gradient(linear, left bottom, right top, color-stop(50%, rgba(26, 50, 68, 0.96)), color-stop(52%, #efefef));
  background: -webkit-linear-gradient(left bottom, rgba(26, 50, 68, 0.96) 50%, #efefef 52%);
  background: linear-gradient(to right top, rgba(26, 50, 68, 0.96) 50%, #efefef 52%);
}

.gecko .footer_base_special_colored2 .triangle,
.no-object-fit .footer_base_special_colored2 .triangle {
  background: -webkit-gradient(linear, right bottom, left top, color-stop(50%, rgba(26, 50, 68, 0.96)), color-stop(50%, #efefef));
  background: -webkit-linear-gradient(right bottom, rgba(26, 50, 68, 0.96) 50%, #efefef 50%);
  background: linear-gradient(to left top, rgba(26, 50, 68, 0.96) 50%, #efefef 50%);
}

.gecko .footer_base_special_colored2 .triangle2,
.no-object-fit .footer_base_special_colored2 .triangle2 {
  background: -webkit-gradient(linear, left bottom, right top, color-stop(50%, rgba(26, 50, 68, 0.96)), color-stop(50%, #efefef));
  background: -webkit-linear-gradient(left bottom, rgba(26, 50, 68, 0.96) 50%, #efefef 50%);
  background: linear-gradient(to right top, rgba(26, 50, 68, 0.96) 50%, #efefef 50%);
}

#infscr-loading {
  display: none !important;
}

.box_hovered .insight_box a {
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.box_hovered .insight_box a:hover {
  border-color: transparent;
  background: #fff;
  -webkit-box-shadow: 0 17px 34px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 17px 34px 0 rgba(0, 0, 0, 0.1);
}

.clearleft {
  clear: left;
}

.in_container {
  float: none;
  margin: 0 auto;
}

.home_projects_item {
  padding: 34px 50px 28px;
}

.home_projects_item .insight_box__content {
  padding: 0;
}

.home_projects_item .insight_box__thumb {
  height: auto;
}

.home_projects_item .insight_box__thumb img {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.home_projects_item h3 {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5em;
  letter-spacing: normal;
  text-align: center;
  color: #26415e;
  margin-top: 26px;
}

.author_in_post {
  position: static;
  margin-top: 26px;
}

.author_in_post .insight_box__author__thumb {
  float: none;
  display: block;
  margin: 0 auto;
  width: 61px;
  height: 61px;
}

.author_in_post .insight_box__author__content {
  width: 100%;
}

.author_in_post .insight_box__author__content p {
  font-size: 14px;
  line-height: 1.07em;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25em;
  letter-spacing: normal;
  text-align: center;
  color: #141f3c;
  margin-top: 8px;
}

.author_in_post .insight_box__author__content span {
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25em;
  letter-spacing: normal;
  text-align: center;
  color: #141f3c;
  text-transform: none;
  margin-bottom: 0;
}

.new_banner {
  height: 520px;
}

.new_banner .overlay {
  background-color: rgba(20, 31, 60, 0.6);
}

@media only screen and (min-width: 1200px) {
  .flex-right .abous_us_item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .flex-right .abous_us_item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

.core_values {
  padding: 78px 0 83px;
}

.abous_us_item {
  margin-top: 64px;
}

.abous_us_item .item_thumb {
  width: 43px;
  margin-right: 22px;
  float: left;
}

.abous_us_item .item_content {
  width: -webkit-calc(100% - 144px);
  width: calc(100% - 144px);
  float: left;
}

.abous_us_item .item_content h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.32em;
  text-align: left;
  color: #2b2b2b;
}

.abous_us_item .item_content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.79em;
  text-align: left;
  color: #2b2b2b;
}

.mb20 {
  margin-bottom: 20px !important;
}

.middle_column {
  margin-top: -20px;
}

@media only screen and (max-width: 767px) {
  .middle_column {
    display: none;
  }
}

.case_studies_wrapper_new {
  padding: 70px 0 30px 0;
  background-color: #f3f3f3;
}

.case_studies_wrapper_new .case_studies_boxes {
  margin-top: 0px;
}

.services_list ul {
  margin-top: 48px;
  margin-bottom: 53px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .services_list ul {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .services_list ul {
    padding-left: 0;
    margin-top: 20px;
  }
}

.services_list li {
  padding: 38px 0;
  border-bottom: 1px solid  #D8D8D8;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .services_list li {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-align: left;
    -webkit-align-items: left;
        -ms-flex-align: left;
            align-items: left;
  }
}

.services_list li:last-child {
  border-bottom: 0;
}

.services_list .service_content {
  max-width: 641px;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .services_list .service_content {
    max-width: 100%;
  }
}

.service_content {
  width: 100%;
  float: left;
}

@media only screen and (max-width: 767px) {
  .service_content {
    margin-top: 15px;
  }
}

.service_content h1 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.32em;
  text-align: left;
  color: #26415e;
}

.service_content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.79em;
  text-align: left;
  color: #2b2b2b;
  margin-top: 13px;
}

@media only screen and (max-width: 767px) {
  .service_content p {
    font-size: 18px;
    padding-right: 15px;
  }
}

.service_content a {
  width: 105px;
  font-size: 14px;
  font-weight: 500;
  color: #1391f6;
  letter-spacing: 0;
  position: relative;
  text-decoration: none;
  display: inline-block;
  margin-top: 18px;
}

@media only screen and (max-width: 767px) {
  .service_content a {
    margin-top: 15px;
  }
}

.service_content a:before {
  position: absolute;
  left: 0;
  bottom: -1px;
  float: left;
  content: " ";
  width: 0;
  height: 1px;
  background-color: #1391f6;
  -webkit-transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.service_content a:after {
  content: "";
  color: #1391f6;
  background: url(../images/blue_right_icon.svg) no-repeat;
  float: right;
  background-size: 8px;
  margin-top: 1px;
  width: 12px;
  height: 16px;
  margin-left: 12px;
}

.service_content a:hover:before {
  width: -webkit-calc(100% - 23px);
  width: calc(100% - 23px);
}

.page-template-page-services .service_thumb {
  float: right;
}

.page-template-page-services .service_thumb img {
  max-width: 80%;
}

@media only screen and (max-width: 767px) {
  .page-template-page-services .service_thumb img {
    max-width: 50%;
  }
}

.medium_banner {
  height: 720px;
}

@media only screen and (max-width: 767px) {
  .medium_banner {
    height: auto;
  }
}

.medium_banner .new_home_banner_thumb {
  position: absolute;
}

.medium_banner .new_home_banner_thumb:after {
  content: "";
  background-color: #fff;
  height: 44px;
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .medium_banner .new_home_banner_thumb:after {
    display: none;
  }
}

.medium_banner .new_home_banner_thumb .overlay {
  width: 50%;
  background: rgba(20, 31, 60, 0.9);
}

@media only screen and (max-width: 767px) {
  .medium_banner .new_home_banner_thumb .overlay {
    width: 100%;
  }
}

.half_banner {
  width: 100%;
  text-align: left;
  top: 200px;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media only screen and (max-width: 767px) {
  .half_banner {
    top: 0;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    position: relative;
    left: 0;
    padding: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .half_banner {
    left: 15px;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    width: 90%;
    top: 150px;
  }
}

.half_banner .new_banner_content {
  width: 500px;
  float: left;
}

@media only screen and (max-width: 767px) {
  .half_banner .new_banner_content {
    width: 100%;
    margin-top: 140px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .half_banner .new_banner_content {
    width: 100%;
  }
}

.new_banner_content h4 {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25em;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .new_banner_content h4 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .new_banner_content h4 {
    margin-left: 0;
  }
}

.new_banner_content h2 {
  font-size: 48px;
  font-weight: 300;
  line-height: 1em;
  color: #ffffff;
  margin-top: 10px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .new_banner_content h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .new_banner_content h2 {
    font-size: 30px;
  }
}

.new_banner_content h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2em;
  color: #ffffff;
  margin-top: 10px;
  display: block;
}

.new_banner_content h1:after {
  width: 20%;
  height: 4px;
  background-color: #00a0d2;
  margin-top: 17px;
}

@media only screen and (max-width: 767px) {
  .new_banner_content h1:after {
    margin-top: 17px !important;
  }
}

@media only screen and (max-width: 767px) {
  .new_banner_content h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .new_banner_content h1 {
    font-size: 30px;
  }
}

.new_banner_content h3 {
  font-size: 32px;
  font-weight: 300;
  line-height: 1.34em;
  text-align: left;
  color: #ffffff;
}

.new_banner_content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.56em;
  color: #ffffff;
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .new_banner_content p {
    font-size: 16px;
  }
}

.new_banner_content p img {
  margin-right: 14px;
  margin-top: 18px;
}

.new_banner_content p img:last-child {
  margin-right: 0;
}

.banner_big_paragraph {
  width: 951px;
}

.banner_big_paragraph p {
  font-size: 46px;
}

@media only screen and (max-width: 767px) {
  .banner_big_paragraph p {
    font-size: 28px;
  }
}

.has_top_space {
  margin-top: 74px;
}

.services_list_items {
  padding-bottom: 114px;
}

.services_border {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 10px;
}

.services_item {
  float: right;
  margin-top: 89px;
  margin-bottom: 22px;
}

@media only screen and (max-width: 767px) {
  .services_item {
    width: 100%;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .services_item {
    margin-top: 40px;
  }
}

.services_item .service_thumb {
  width: 50px;
  float: left;
  margin-right: 24px;
}

.services_item .service_content {
  width: 464px;
  float: left;
}

@media only screen and (max-width: 767px) {
  .services_item .service_content {
    margin-top: 10px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .services_item .service_content {
    margin-top: 10px;
    width: 100%;
  }
}

.has_border_box {
  padding-bottom: 80px;
}

.has_border_box .insight_box {
  border-radius: 3px;
  border: 1px solid #d8d8d8;
}

.blue_section {
  height: 600px;
  background: #141F3C;
}

@media only screen and (max-width: 767px) {
  .blue_section {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .blue_section .half_banner .new_banner_content {
    margin-top: 50px;
  }
}

.blue_section .half_banner {
  top: 145px !important;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .blue_section .half_banner {
    top: 65px !important;
  }
}

@media only screen and (max-width: 767px) {
  .blue_section .half_banner {
    top: 0 !important;
  }
}

.right_half {
  width: 50% !important;
  position: absolute !important;
  right: 0;
}

@media only screen and (max-width: 767px) {
  .right_half {
    width: 100% !important;
    position: relative !important;
  }
}

.right_half img {
  object-position: bottom;
}

.has_pdf a {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.79;
  text-align: left;
  color: #2b2b2b;
  width: auto;
  text-decoration: underline;
}

.has_pdf a:after {
  float: left;
  margin-left: 0;
  margin-right: 10px;
  width: 24px;
  height: 24px;
  background: url(../images/ic_file_download_black_24px.svg) no-repeat center;
}

.has_pdf a:before {
  display: none;
}

.projects_list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.projects_list li {
  float: left;
}

@media only screen and (max-width: 767px) {
  .projects_list li {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .projects_list {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .projects_list li {
    margin-right: 10px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .projects_list {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.gecko .projects_list li,
.no-object-fit .projects_list li {
  width: auto;
  max-width: 20%;
}

.case_studies_sub_wrapper {
  position: relative;
}

.left_bar {
  float: left;
  width: 157px;
}

@media only screen and (max-width: 767px) {
  .left_bar {
    padding-left: 32px;
    width: auto;
  }
}

.bar_title {
  padding-top: 36px;
}

@media only screen and (max-width: 767px) {
  .bar_title {
    padding-top: 0;
    margin-bottom: -70px;
  }
}

.bar_title h3 {
  font-size: 18px;
  font-weight: bold;
  line-height: 2.39em;
  color: #26415e;
  margin-top: -10px;
}

.right_side {
  float: right;
  width: -webkit-calc(100% - 157px);
  width: calc(100% - 157px);
  padding-right: 102px;
  position: relative;
  padding-left: 32px;
  padding-top: 36px;
}

@media only screen and (max-width: 767px) {
  .right_side {
    width: 100%;
    padding-right: 0;
  }
}

.right_side:before {
  content: "";
  height: 100%;
  width: 2px;
  position: absolute;
  left: 0;
  top: 0;
  float: left;
  background: rgba(147, 147, 147, 0.2);
}

.right_content {
  margin-top: -5px;
}

.right_content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.69em;
  text-align: left;
  color: #141f3c;
  margin-bottom: 30px;
  max-width: 822px;
}

.right_content img {
  margin-bottom: 30px;
}

.sub_page_section {
  padding-top: 105px;
  padding-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .sub_page_section {
    padding-top: 65px;
    padding-bottom: 70px;
  }
}

.iphones_holder .iphone_holder {
  width: 232px;
  height: 420px;
  float: left;
  margin-right: 20px;
  position: relative;
  background: url(../images/iphone.png) no-repeat;
}

.iphones_holder .iphone_holder img {
  margin-bottom: 0;
  position: absolute;
  left: 19px;
  top: 57px;
  width: 176px;
  height: 309px;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.pt0 {
  padding-top: 0;
}

@media only screen and (max-width: 767px) {
  .right_side.pt0 {
    padding-top: 36px;
  }
}

.left_landing {
  float: left;
  width: 47.5%;
  min-height: 100px;
  background-color: #f7f7f7;
}

@media only screen and (max-width: 767px) {
  .left_landing {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .left_landing {
    width: 100%;
  }
}

.left_landing_container {
  width: 574px;
  float: right;
  padding-top: 53px;
  padding-bottom: 63px;
}

@media only screen and (max-width: 767px) {
  .left_landing_container {
    width: 100%;
    padding: 33px 15px 43px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .left_landing_container {
    width: 100%;
    padding: 33px 15px 43px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .left_landing_container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.left_landing_container p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.79em;
  color: #2b2b2b;
  margin-bottom: 36px;
  max-width: 496px;
}

.left_landing_container h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.19em;
  color: #26415e;
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .left_landing_container h1 {
    font-size: 24px;
  }
}

.left_landing_container h4 {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.19em;
  color: #26415e;
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .left_landing_container h4 {
    font-size: 20px;
  }
}

.left_landing img {
  margin-left: -60px;
  max-width: -webkit-calc(100% + 120px);
  max-width: calc(100% + 120px);
}

@media only screen and (max-width: 767px) {
  .left_landing img {
    margin-left: 0;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .left_landing img {
    margin-left: 0;
    max-width: 100%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .left_landing img {
    margin-left: 0;
    max-width: 100%;
  }
}

.right_landing {
  width: 52.5%;
  float: right;
}

@media only screen and (max-width: 767px) {
  .right_landing {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .right_landing {
    width: 100%;
  }
}

.right_landing_container {
  width: 670px;
  padding-left: 63px;
  float: left;
  padding-top: 53px;
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .right_landing_container {
    width: 100%;
    padding: 33px 15px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .right_landing_container {
    width: 100%;
    padding: 33px 15px 40px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .right_landing_container {
    width: 100%;
    padding: 33px 15px 40px;
  }
}

.right_landing .gfield {
  padding: 0;
  width: 100%;
}

.right_landing textarea,
.right_landing select,
.right_landing input {
  width: 100%;
  height: 50px;
  padding: 13px 15px;
  float: left;
  outline: 0;
  margin-top: 36px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 3px;
  font-size: 18px;
  line-height: normal;
  color: #8e8e8e;
}

@media only screen and (max-width: 767px) {
  .right_landing textarea,
  .right_landing select,
  .right_landing input {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .right_landing textarea,
  .right_landing select,
  .right_landing input {
    margin-top: 20px;
  }
}

.right_landing textarea::-webkit-input-placeholder,
.right_landing select::-webkit-input-placeholder,
.right_landing input::-webkit-input-placeholder {
  font-size: 18px;
  line-height: normal;
  color: #8e8e8e;
}

.right_landing textarea:-moz-placeholder,
.right_landing select:-moz-placeholder,
.right_landing input:-moz-placeholder {
  font-size: 18px;
  line-height: normal;
  color: #8e8e8e;
}

.right_landing textarea::-moz-placeholder,
.right_landing select::-moz-placeholder,
.right_landing input::-moz-placeholder {
  font-size: 18px;
  line-height: normal;
  color: #8e8e8e;
}

.right_landing textarea:-ms-input-placeholder,
.right_landing select:-ms-input-placeholder,
.right_landing input:-ms-input-placeholder {
  font-size: 18px;
  line-height: normal;
  color: #8e8e8e;
}

.right_landing select {
  background: url(../images/bottom-arrow-select.png) no-repeat 96% #fff;
  /*for firefox*/
  -moz-appearance: none;
  /*for chrome*/
  -webkit-appearance: none;
}

.right_landing select::-ms-expand {
  display: none;
}

.right_landing input[type="submit"] {
  float: right;
  width: 150px;
  border-radius: 3px;
  background: url(../images/right-long-arrow.svg) no-repeat #0098ff 102px center;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 0;
  margin-top: 77px;
  line-height: 14px;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

@media only screen and (max-width: 767px) {
  .right_landing input[type="submit"] {
    margin-top: 35px;
  }
}

.right_landing input[type="submit"]:hover {
  background-position-x: 92%;
}

.right_landing textarea {
  resize: none;
  height: 160px;
  overflow-x: hidden;
}

.right_landing .gform_title {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.69em;
  color: #141f3c;
  margin-bottom: 24px;
}

.right_landing .gform_description {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.79em;
  color: #2b2b2b;
  margin-bottom: 9px;
}

.costum_article_form .gfield {
  padding: 0;
  width: 100%;
}

.costum_article_form textarea,
.costum_article_form select,
.costum_article_form input {
  width: 100%;
  height: 50px;
  padding: 13px 15px;
  float: left;
  outline: 0;
  margin-top: 36px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 3px;
  font-size: 18px;
  line-height: normal;
  color: #8e8e8e;
}

@media only screen and (max-width: 767px) {
  .costum_article_form textarea,
  .costum_article_form select,
  .costum_article_form input {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .costum_article_form textarea,
  .costum_article_form select,
  .costum_article_form input {
    margin-top: 20px;
  }
}

.costum_article_form textarea::-webkit-input-placeholder,
.costum_article_form select::-webkit-input-placeholder,
.costum_article_form input::-webkit-input-placeholder {
  font-size: 18px;
  line-height: normal;
  color: #8e8e8e;
}

.costum_article_form textarea:-moz-placeholder,
.costum_article_form select:-moz-placeholder,
.costum_article_form input:-moz-placeholder {
  font-size: 18px;
  line-height: normal;
  color: #8e8e8e;
}

.costum_article_form textarea::-moz-placeholder,
.costum_article_form select::-moz-placeholder,
.costum_article_form input::-moz-placeholder {
  font-size: 18px;
  line-height: normal;
  color: #8e8e8e;
}

.costum_article_form textarea:-ms-input-placeholder,
.costum_article_form select:-ms-input-placeholder,
.costum_article_form input:-ms-input-placeholder {
  font-size: 18px;
  line-height: normal;
  color: #8e8e8e;
}

.costum_article_form select {
  background: url(../images/bottom-arrow-select.png) no-repeat 96% #fff;
  /*for firefox*/
  -moz-appearance: none;
  /*for chrome*/
  -webkit-appearance: none;
}

.costum_article_form select::-ms-expand {
  display: none;
}

.costum_article_form input[type="submit"] {
  float: right;
  width: 150px;
  border-radius: 3px;
  background: url(../images/right-long-arrow.svg) no-repeat #0098ff 102px center;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 0;
  margin-top: 77px;
  line-height: 14px;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

@media only screen and (max-width: 767px) {
  .costum_article_form input[type="submit"] {
    margin-top: 35px;
  }
}

.costum_article_form input[type="submit"]:hover {
  background-position-x: 92%;
}

.costum_article_form textarea {
  resize: none;
  height: 160px;
  overflow-x: hidden;
}

.costum_article_form .gform_title {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.69em;
  color: #141f3c;
  margin-bottom: 24px;
}

.costum_article_form .gform_description {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.79em;
  color: #2b2b2b;
  margin-bottom: 9px;
}

.gfield_error input {
  border: 1px solid red;
}

.gfield_description.validation_message {
  display: none;
}

.validation_error {
  display: none;
}

.no_margin p {
  margin-top: 0;
}

.full-image {
  position: absolute;
  height: 100%;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 767px) {
  .full-image {
    position: relative;
    height: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .full-image {
    position: relative;
    height: 450px;
  }
}

.case_studies_new_left {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .case_studies_new_left {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .case_studies_new_left {
    width: 100%;
  }
}

.case_studies_new_left .left_landing_container {
  float: left;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
}

.case_studies_new_left .left_landing_container p {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .case_studies_new_left .left_landing_container {
    padding: 33px 15px 43px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .case_studies_new_left .left_landing_container {
    padding: 33px 15px 43px;
  }
}

.case_studies_new_left .btn_out {
  margin-top: 0px;
  text-align: left;
}

.relativ_wrapper {
  position: relative;
}

.in-line-case-studies {
  padding-top: 0px;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .in-line-case-studies {
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .in-line-case-studies {
    padding-top: 0 !important;
  }
}

.list_of_case_studies_post {
  width: 100%;
  cursor: ew-resize;
  cursor: -webkit-grab;
}

.list_of_case_studies_post li {
  width: 100%;
  float: left;
  padding: 0 10px;
}

.list_of_case_studies_post li .box {
  height: 280px;
}

.list_of_case_studies_post li .box:hover .box__content a {
  margin-bottom: 0;
}

.list_of_case_studies_post li .box__thumb img {
  height: 100%;
}

.list_of_case_studies_post li .box__content {
  padding-left: 30px;
  padding-right: 30px;
  bottom: -74px;
}

@media only screen and (max-width: 767px) {
  .list_of_case_studies_post li .box__content {
    bottom: -94px;
  }
}

.list_of_case_studies_post li .box__content a {
  margin-bottom: -30px;
}

.flex_show_in_line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}

@media only screen and (min-width: 1200px) {
  .desktop_t120 {
    top: 120px;
  }
}

.bold_title h1 {
  font-weight: 700;
}

.main_footer {
  position: relative;
  height: 500px;
  background-color: #fff;
}

.main_footer .home_banner_thumb {
  width: 100%;
  height: 500px;
  float: left;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.main_footer .home_banner_thumb img {
  font-family: 'object-fit:cover;';
  opacity: 0.25;
}

.no-objectfit .main_footer .home_banner_thumb img {
  opacity: 0.45;
}

.main_footer .home_banner_thumb .overlay {
  background-size: 100%;
  background-color: #101a33;
  mix-blend-mode: multiply;
  -webkit-mix-blend-mode: multiply;
  -moz-mix-blend-mode: multiply;
  -ms-mix-blend-mode: multiply;
  -o-mix-blend-mode: multiply;
}

.no-objectfit .main_footer .home_banner_thumb .overlay {
  opacity: .92;
}

.footerWrap {
  padding: 136px 97px 38px;
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
}

@media only screen and (min-width: 1200px) {
  .footerWrap .container {
    height: 100%;
  }
  .footerWrap .bottom_footer {
    position: absolute;
    bottom: 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .footerWrap .container {
    height: 100%;
  }
  .footerWrap .bottom_footer {
    position: absolute;
    bottom: 0;
  }
}

.left_footer {
  width: 225px;
  float: left;
}

.left_footer img {
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit:cover;';
}

.right_footer {
  width: auto;
  float: right;
}

@media only screen and (min-width: 1200px) {
  .right_footer {
    margin-right: 85px;
  }
}

.right_footer .menu-item-has-children {
  width: auto;
  float: left;
  margin-left: 70px;
}

.right_footer .menu-item-has-children a {
  font-family: 'Rubik', sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}

.right_footer .menu-item-has-children ul {
  margin-top: 15px;
}

.right_footer .menu-item-has-children li {
  margin-top: 10px;
}

.right_footer .menu-item-has-children li a {
  font-family: 'Rubik', sans-serif;
  text-decoration: none;
  color: #929AA6;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.right_footer .menu-item-has-children li a:hover {
  color: #fff;
}

.right_footer .dropdown a {
  cursor: pointer;
}

.right_footer .dropdown a:after {
  content: "";
  border: solid #fff;
  cursor: pointer;
  margin-left: 16px;
  border-width: 0 1px 1px 0;
  display: inline-block;
  float: right;
  margin-top: 4px;
  padding: 3px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.right_footer .dropdown ul {
  display: none;
}

.right_footer .dropdown ul a:after {
  display: none;
}

.bottom_footer {
  margin-top: 120px;
}

.bottom_footer p {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
  float: left;
}

.bottom_footer .line {
  width: -webkit-calc(100% - 84px);
  width: calc(100% - 84px);
  margin-right: 24px;
  height: 2px;
  opacity: 0.2;
  background-color: #FFFFFF;
}

.bottom_footer .go_top {
  width: 60px;
  float: right;
  height: 60px;
  border-radius: 100%;
  background-image: url(../images/arrow-up.svg);
  background-repeat: no-repeat;
  margin-top: -30px;
  z-index: 2;
  position: relative;
  background-position: center;
}

.bottom_footer .go_top:after {
  content: "";
  width: 60px;
  height: 60px;
  float: left;
  z-index: 1;
  position: relative;
  border-radius: 100%;
  border: 1px solid transparent;
  background-image: -webkit-linear-gradient(202deg, #00e3c8, #5f5aff);
  background-image: linear-gradient(248deg, #00e3c8, #5f5aff);
  opacity: 0;
  -webkit-transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.bottom_footer .go_top:before {
  content: "";
  width: 15px;
  height: 15px;
  background: url(../images/down-arrow-white.svg) no-repeat;
  -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
  background-size: 15px;
  float: right;
  position: absolute;
  right: 22px;
  bottom: 23px;
  z-index: 3;
  -webkit-transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  opacity: 0;
}

.bottom_footer .go_top:hover:before, .bottom_footer .go_top:hover:after {
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .services_list_items {
    padding-bottom: 44px;
  }
  .right_header .hamburger {
    margin-top: 11px;
  }
  .home_banner_section .overlay {
    background-size: cover;
  }
  .is--active {
    background: rgba(26, 50, 68, 0.96);
  }
  .is--active .nav_wrapper {
    top: 67px;
  }
  .is--active .is--sticky .nav_wrapper {
    top: 67px;
  }
  .is--active .hamburger {
    margin-top: 6px;
  }
  .is--active .hamburger span:before {
    float: left;
  }
  .is--active .hamburger span:nth-child(1) {
    -webkit-transform: rotate(-45deg) translateX(-10px) translateY(10px);
        -ms-transform: rotate(-45deg) translateX(-10px) translateY(10px);
            transform: rotate(-45deg) translateX(-10px) translateY(10px);
  }
  .is--active .hamburger span:nth-child(2) {
    opacity: 0;
  }
  .is--active .hamburger span:nth-child(3) {
    -webkit-transform: rotate(45deg) translateX(0px) translateY(0px);
        -ms-transform: rotate(45deg) translateX(0px) translateY(0px);
            transform: rotate(45deg) translateX(0px) translateY(0px);
  }
  body.menu--active {
    position: fixed;
  }
  .nav_wrapper {
    clear: both;
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    top: -100%;
    left: 0;
    z-index: 99;
    -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
    transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
    background-color: rgba(20, 31, 60, 0.8);
    margin-top: 0;
  }
  .nav_wrapper ul {
    text-align: center;
    width: 100%;
    float: left;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .nav_wrapper li {
    display: block;
    clear: both;
    float: none;
    margin-right: 0;
  }
  .nav_wrapper li a {
    position: relative;
    font-size: 36px;
    font-weight: 300;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 2.23em;
    -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
    transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
    float: none;
  }
  .nav_wrapper li a:after {
    position: absolute;
    bottom: -3px;
    left: 0;
    float: left;
    z-index: -1;
    content: " ";
    width: 0;
    height: 3px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 101%;
    background-image: -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(#1FC887), to(#00B7F3)), -webkit-gradient(linear, left top, right top, from(#1FC887), to(#00B7F3));
    background-image: linear-gradient(to right, #1FC887 0%, #00B7F3 100%), linear-gradient(to right, #1FC887 0%, #00B7F3 100%);
    -webkit-transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .nav_wrapper li a:hover:after {
    width: 100%;
  }
  .home_banner_wrapper {
    padding: 0 15px;
    width: 100%;
  }
  .home_banner_wrapper img {
    width: 180px;
  }
  .home_banner_wrapper h1 {
    font-size: 25px;
    line-height: 1.35em;
  }
  .home_banner_wrapper h1 b {
    font-size: 25px;
    line-height: 1.35em;
  }
  .home_banner_wrapper h1:after {
    margin-top: 37px;
  }
  .list_links_header {
    display: none;
  }
  .nav_wrapper ul {
    margin-top: -82px;
  }
  .nav_wrapper li a {
    font-size: 28px;
  }
  .base {
    height: 32px;
  }
  .base_special {
    top: -32px;
  }
  .overlay_special {
    height: 100vh;
  }
  .overlay_special .go_bottom2 {
    bottom: 28px;
  }
  .buttons_holder a {
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
  }
  .buttons_holder a:first-child {
    margin-top: 0;
  }
  .analytics-platform {
    height: 110px;
    margin-top: -20px;
    margin-bottom: 0 !important;
  }
  .analytics-platform .visual {
    left: unset;
    width: 100%;
    margin: 0;
  }
  .home_services_section {
    height: auto;
    padding: 40px 0;
  }
  .projects_item {
    margin-top: 40px;
    padding-left: 0;
  }
  .projects_item__thumb {
    max-width: 30px;
    width: 30px;
  }
  .projects_item__content {
    width: -webkit-calc(100% - 75px);
    width: calc(100% - 75px);
  }
  .projects_item__content p {
    padding-right: 0px;
  }
  .btn_out {
    margin-top: 45px;
  }
  .box {
    margin-top: 15px;
    height: 250px;
  }
  .box .box__content {
    bottom: 0;
  }
  .box .box__content h3 {
    margin-bottom: 0;
  }
  .box .box__content a {
    margin-top: 20px;
  }
  .our_clients_slider {
    margin-bottom: 0;
  }
  .our_clients_slider .flex-control-nav {
    margin: 0 auto;
    position: static;
    -webkit-transform: unset;
        -ms-transform: unset;
            transform: unset;
    margin-top: -24px;
    padding: 0 15px;
  }
  .our_clients_slider .flex-control-nav li {
    margin-right: 15px;
    float: none;
  }
  .our_projects {
    margin-bottom: 40px;
  }
  .our_projects ul {
    margin-top: 10px;
  }
  .our_projects li {
    width: 50%;
    padding: 0 15px;
  }
  .list_services {
    padding: 0 20px 0 60px;
  }
  .list_services li {
    width: 100%;
  }
  .list_services .item_content h4:after {
    display: block !important;
  }
  .section_title h1 {
    font-size: 28px;
    line-height: 1.35em;
  }
  .home_projects_section {
    padding-top: 50px;
  }
  .projects_big_thumb img {
    margin-bottom: -10px;
  }
  .case_studies_wrapper {
    padding: 45px 0 35px;
  }
  .case_studies_wrapper .base {
    height: 8px;
  }
  .case_studies_boxes {
    padding: 0;
  }
  .box__content {
    padding: 20px 20px;
    padding-right: 60px;
  }
  .box__content a {
    bottom: 30px;
  }
  .list_of_case_studies_post li .box__content {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    bottom: 0;
  }
  .list_of_case_studies_post li .box__content a {
    margin-bottom: 0;
  }
  .our_clients_section {
    height: auto;
  }
  .our_clients_section .home_banner_thumb {
    position: absolute;
  }
  .our_clients_section .our_clients_wrapper {
    position: static;
  }
  .our_clients_section .base {
    height: 15px;
  }
  .our_clients_section .home_banner_thumb {
    height: -webkit-calc(100% + 20px);
    height: calc(100% + 20px);
    top: -20px;
  }
  .our_clients_holder {
    margin-top: 0;
  }
  .our_clients_holder h1 {
    max-width: 100%;
    font-size: 32px;
  }
  .our_clients_holder img {
    max-width: 250px;
  }
  .our_clients_slider .slides li {
    height: 600px;
  }
  .client_box__content {
    width: -webkit-calc(100% - 0px);
    width: calc(100% - 0px);
  }
  .our_clients_slider .flex-direction-nav {
    margin-top: 30px;
  }
  .home_insight_section {
    padding-top: 35px;
    padding-bottom: 45px;
  }
  .insight_box {
    margin-top: 15px;
  }
  .home_insight_wrapper .btn_out {
    margin-top: 30px;
  }
  .insight_box__content p {
    padding-right: 40px;
  }
  .list_boxes_out li {
    width: 100%;
  }
  .main_footer {
    height: auto;
  }
  .main_footer .home_banner_thumb {
    position: absolute;
    height: 100%;
  }
  .footerWrap {
    padding: 48px 0px;
    position: static;
  }
  .right_footer {
    margin-left: 10px;
    width: 100%;
  }
  .right_footer .menu-item-has-children {
    width: 50%;
    margin-top: 20px;
    margin-left: 0;
  }
  .right_footer li:nth-child(2n+1) {
    clear: left;
  }
  .bottom_footer {
    margin-top: 50px;
  }
  .ceo_wrapper {
    width: 100%;
    height: auto;
    padding: 62px 15px 62px 15px;
  }
  .ceo_holder {
    width: 100%;
  }
  .ceo_content {
    width: 100%;
    clear: both;
    margin-top: 20px;
  }
  .ceo_content p {
    margin-top: 20px;
    font-size: 24px;
  }
  .home_bottom_banner_section {
    width: 100%;
    height: auto;
    position: relative;
  }
  .home_bottom_banner_section .home_banner_thumb {
    position: absolute;
  }
  .home_bottom_banner_content {
    margin: 55px auto;
    width: 90%;
    padding: 20px 25px;
    height: auto;
    position: static;
    -webkit-transform: unset !important;
        -ms-transform: unset !important;
            transform: unset !important;
  }
  .home_bottom_banner_content h1 {
    font-size: 26px;
  }
  .home_bottom_banner_content h1:after {
    margin-top: 15px;
  }
  .home_bottom_banner_content p {
    margin-top: 15px;
  }
  .about_partners_section {
    height: auto;
    float: left;
    width: 100%;
  }
  .about_partners_section .black_text h1 {
    width: 100%;
    display: block;
    padding: 0;
  }
  .blue_wrapper {
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .list_services {
    padding: 0;
    display: block;
  }
  .list_services li {
    width: 100%;
    margin-top: 30px !important;
  }
  .list_services li:after {
    display: none;
  }
  .list_services .item_thumb {
    margin-left: 0;
  }
  .analytics-platform {
    zoom: unset !important;
    margin-bottom: 50px;
    float: left;
  }
  html body main .analytics-platform .visual {
    top: -30px !important;
  }
  .contact_banner {
    height: 500px;
  }
  .left_contact {
    width: 100%;
    padding-left: 25px;
    margin-top: 30px;
  }
  .right_contact {
    width: 100%;
    padding: 22px 29px;
    margin-top: 50px;
    height: 565px;
  }
  .right_contact input[type=submit] {
    width: -webkit-calc(100% - 59px);
    width: calc(100% - 59px);
    position: absolute;
    bottom: 22px;
    left: 29px;
  }
  .footer_base_special,
  .footer_base_special2 {
    height: 15px;
  }
  .list_services2__item {
    float: none;
    margin: 0 auto !important;
    margin-top: 30px !important;
  }
  .our_projects li:nth-child(2n+1) {
    clear: left;
  }
  .projects_big_thumb {
    padding: 0;
    padding-left: 10px;
    padding-bottom: 38px;
  }
  .white_header .right_header .hamburger span:after,
  .white_header .right_header .hamburger span:before {
    background-color: #1a3346;
  }
  .white_header .nav_wrapper li a {
    position: relative;
    z-index: 6;
    color: #fff;
  }
  .white_header .nav_wrapper li a:after {
    display: none !important;
  }
  .banner_content h1 {
    width: 100%;
    font-size: 32px;
    letter-spacing: -1px;
  }
  .banner_content h1:after {
    display: none;
  }
  .bear_profile {
    background-size: cover;
    background: #16216a;
  }
  .left_profile {
    position: relative;
    width: 100%;
    padding: 0 15px;
    background: transparent;
  }
  .right_profile {
    width: 100%;
    margin-top: 30px;
  }
  .profil_title h1 {
    font-size: 32px;
  }
  .profil_title p {
    font-size: 15px;
    line-height: 1.463em;
    margin-top: 15px;
  }
  .challenge_section {
    padding: 38px 0;
  }
  .challenge_section .left_challenge {
    width: 100%;
    margin-left: 0;
  }
  .challenge_section .right_challenge {
    width: 90%;
    margin-top: 20px;
  }
  .mac-holder {
    width: 347px;
    height: 200px;
    background-size: cover;
    margin-bottom: 65px;
  }
  .ba-slider {
    width: 261px;
    height: 145px !important;
    -webkit-transform: translateX(-50.2%);
        -ms-transform: translateX(-50.2%);
            transform: translateX(-50.2%);
    top: 30px;
  }
  .ba-control {
    top: -webkit-calc(100% + 40px);
    top: calc(100% + 40px);
    height: 50px !important;
  }
  .ba-control-bar {
    margin-top: 25px;
  }
  .ba-control-bar:before {
    width: -webkit-calc(100% + 120px);
    width: calc(100% + 120px);
    margin-left: -60px;
  }
  .ba-control-handle {
    width: 50px;
    height: 50px;
    margin-left: -25px;
  }
  .projects_slider_section .profil_title {
    padding-left: 0;
    margin-top: 10px;
  }
  .has_left_space {
    padding-left: 10px;
    padding-right: 30px;
  }
  .center_img {
    margin-top: 40px;
  }
  .deploy_section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .full_height_wrapper {
    background-size: cover;
  }
  .one-raiser-challenge {
    padding-top: 0;
  }
  .one-raiser-challenge .left_challenge {
    width: 100% !important;
  }
  .one-raiser-challenge .right_challenge {
    width: 100% !important;
    padding-left: 0;
  }
  .one-raiser-projects .one-raiser-projects-holder {
    margin-left: 0;
  }
  .vva-projects {
    padding-top: 20px;
  }
  .bioe-profile .right_profile img {
    margin-right: 0;
  }
  .bioe-projects-thumb {
    margin-right: 0;
    margin-top: 30px;
  }
  .bioe-projects {
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .bioe-profile .left_profile .left_profile_innter {
    margin-top: 70px;
  }
  .contact_wrapper {
    overflow-y: scroll;
    background-size: 100% !important;
  }
  .contact_wrapper::-webkit-scrollbar {
    display: none;
  }
  .contact_wrapper .close_btn {
    top: 20px;
    right: 20px;
    width: 47px;
    height: 47px;
  }
  .contact_wrapper .close_btn span {
    width: 25px;
    left: 55%;
  }
  .contact_small_title {
    margin-top: 30px;
  }
  .contact_info {
    margin-left: 32px;
  }
  .contact_copyright {
    position: static;
    margin-top: 40px;
  }
  .contact_title h2 {
    font-size: 36px;
  }
  .home_banner_wrapper img {
    width: auto;
    max-width: 100%;
  }
  .clc-banner p {
    font-size: 14px;
    margin-top: 15px;
  }
  .clc-banner h1 {
    font-size: 28px;
    line-height: 1.15em;
  }
  .clc-banner img {
    margin-top: 30px;
    margin-bottom: -50px;
  }
  .clc-profile {
    height: auto;
    background: url(../images/clc-profile-bg.png) no-repeat;
    background-position: bottom;
    background-size: 100%;
  }
  .clc-profile .right_profile {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 20px;
  }
  .clc-profile .right_profile img {
    margin-right: 0;
  }
  #first-info {
    right: 200px;
    top: 5px;
  }
  #second-info {
    right: 118px;
    top: 43px;
  }
  #third-info {
    right: 98px;
    top: 143px;
  }
  .clc-projects-holder .profil_title {
    padding: 0 15px;
  }
  .clc-projects-holder:after {
    margin-top: 20px;
  }
  .clc-pc-holder {
    height: 280px !important;
    margin-bottom: -50px;
    float: none;
  }
  .clc-pc-holder .ba-slider {
    width: 239px;
    height: 150px !important;
    -webkit-transform: translateX(-50.6%);
        -ms-transform: translateX(-50.6%);
            transform: translateX(-50.6%);
    top: 12px;
  }
  .clc-projects {
    padding-top: 50px;
  }
  .clc-projects .middle_banner {
    margin-top: 20px;
  }
  .left_profile .left_profile_innter {
    margin-top: 60px;
  }
  .clc-challenge .left_challenge {
    margin-right: 0;
    padding-right: 0 !important;
    margin-top: 30px;
  }
  .one-raiser-profile .left_profile .left_profile_innter {
    margin-top: 66px;
  }
  .one-raiser-projects {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .vva-profile {
    height: auto;
  }
  .vva-profile .left_profile .left_profile_innter {
    margin-top: 60px;
  }
  .vva-profile .right_profile {
    margin-top: 60px;
  }
  .vva-profile .slider_nav_info p {
    margin-left: 0;
    margin-bottom: 40px;
  }
  .vva-mac-holder {
    margin-left: -40px;
    margin-bottom: 35px;
  }
  .vva-mac-holder .ba-slider {
    width: 248px !important;
    height: 140px !important;
    -webkit-transform: translateX(-48.5%);
        -ms-transform: translateX(-48.5%);
            transform: translateX(-48.5%);
    top: 10px;
    left: 49%;
  }
  .vva-mac-holder .ba-control {
    top: -22px;
    left: -webkit-calc(100% + 46px);
    left: calc(100% + 46px);
  }
  .vva-mac-holder .ba-control-bar:before {
    height: -webkit-calc(100% + 120px);
    height: calc(100% + 120px);
    margin-top: -10px;
    margin-left: 2px;
  }
  .vva-challenge .right_challenge {
    width: 100%;
  }
  .case_studies_article {
    height: 450px;
  }
  .case_studies_article_content h1 {
    font-size: 28px;
  }
  .article_container {
    max-width: 100%;
    padding: 0 15px;
  }
  .article_wrapper {
    padding: 30px 0;
  }
  .blog_slider_wrapper {
    height: 100vh;
  }
  .blog_slider_wrapper .our_clients_holder h1 {
    font-size: 28px;
  }
  .article_wrapper blockquote {
    margin-left: 20px;
  }
  .article_wrapper pre {
    padding: 20px;
    max-width: 100%;
  }
  .article_wrapper p {
    margin-bottom: 20px;
  }
  .projects_big_thumb {
    padding-bottom: 30px;
  }
  .projects_big_thumb .projects_item__content {
    width: 100%;
  }
  .projects_big_thumb .clearleft .projects_item__content {
    padding-right: 0;
  }
  .projects_big_thumb .pull-right .projects_item__content {
    padding-left: 0;
  }
  .home_projects_item {
    padding: 34px 30px 28px;
  }
  .author_in_post .insight_box__author__content p {
    padding-right: 0;
  }
  .no_border {
    width: 100% !important;
  }
  .no_border h1 {
    font-size: 28px !important;
  }
  .list_process .item_content h3 {
    font-size: 20px;
  }
  .our_process_big_thumb,
  .core_values {
    padding: 38px 0 43px;
  }
  .abous_us_item .item_content {
    width: -webkit-calc(100% - 65px);
    width: calc(100% - 65px);
  }
  .new_banner {
    height: 300px;
  }
  .contact_title h4:before {
    margin-left: 5px;
  }
  .middle_column {
    position: static;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    text-align: center;
    margin-top: 40px;
  }
  .mob-mt67 {
    margin-top: 67px;
  }
  .gfield {
    width: 100%;
  }
  .headerWrap .menu-item-has-children ul {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    left: 0;
    top: 0;
    margin-top: 0;
    position: static;
  }
  .headerWrap .menu-item-has-children li a {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .home_banner_section .overlay {
    background-size: cover;
  }
  .nav_wrapper {
    margin-top: 0;
    background-color: rgba(20, 31, 60, 0.8);
  }
  .right_header .hamburger {
    margin-top: 15px;
  }
  .is--active {
    background: rgba(26, 50, 68, 0.8);
  }
  .is--active .nav_wrapper {
    top: 76px;
  }
  .is--active .is--sticky .nav_wrapper {
    top: 72px;
    height: 100vh;
  }
  .is--active .hamburger {
    margin-top: 9px;
  }
  .is--active .hamburger span:before {
    float: left;
  }
  .is--active .hamburger span:nth-child(1) {
    -webkit-transform: rotate(-45deg) translateX(-10px) translateY(10px);
        -ms-transform: rotate(-45deg) translateX(-10px) translateY(10px);
            transform: rotate(-45deg) translateX(-10px) translateY(10px);
  }
  .is--active .hamburger span:nth-child(2) {
    opacity: 0;
  }
  .is--active .hamburger span:nth-child(3) {
    -webkit-transform: rotate(45deg) translateX(0px) translateY(0px);
        -ms-transform: rotate(45deg) translateX(0px) translateY(0px);
            transform: rotate(45deg) translateX(0px) translateY(0px);
  }
  .menu--active {
    position: fixed;
  }
  .white_header .right_header .hamburger span:after,
  .white_header .right_header .hamburger span:before {
    background-color: #1a3346;
  }
  .white_header .nav_wrapper li a {
    color: #fff;
  }
  .home_banner_wrapper {
    width: 100%;
  }
  .banner_content h1 {
    width: 100%;
  }
  .banner_content h1:after {
    display: none;
  }
  .nav_wrapper {
    clear: both;
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    top: -100%;
    left: 0;
    z-index: 99;
    -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
    transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
    background-color: rgba(26, 50, 68, 0.96);
  }
  .nav_wrapper ul {
    text-align: center;
    width: 100%;
    float: left;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .nav_wrapper li {
    display: block;
    clear: both;
    float: none;
    margin-right: 0;
  }
  .nav_wrapper li a {
    position: relative;
    font-size: 36px;
    font-weight: 300;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 2.23em;
    -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
    transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
    float: none;
  }
  .nav_wrapper li a:after {
    position: absolute;
    bottom: -3px;
    left: 0;
    float: left;
    z-index: -1;
    content: " ";
    width: 0;
    height: 3px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 101%;
    background-image: -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(#1FC887), to(#00B7F3)), -webkit-gradient(linear, left top, right top, from(#1FC887), to(#00B7F3));
    background-image: linear-gradient(to right, #1FC887 0%, #00B7F3 100%), linear-gradient(to right, #1FC887 0%, #00B7F3 100%);
    -webkit-transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .nav_wrapper li a:hover:after {
    width: 100%;
  }
  .list_links_header {
    margin-right: 30px;
    margin-top: 5px;
  }
  .list_links_header li:first-child:before {
    display: none;
  }
  .list_services2 {
    max-width: 558px !important;
  }
  .list_services2 li:nth-child(2n) {
    margin-right: 0;
  }
  .title_max_width {
    max-width: 640px;
  }
  .projects_item__thumb {
    width: 30px;
  }
  .our_clients_holder img {
    max-width: 400px;
  }
  .our_projects li {
    width: 25%;
  }
  .our_projects li:nth-child(4n+1) {
    clear: left;
  }
  .projects_item__content {
    width: -webkit-calc(100% - 70px);
    width: calc(100% - 70px);
  }
  .projects_item__content p {
    padding-right: 0;
  }
  .home_banner_wrapper {
    width: 620px;
  }
  .case_studies_boxes {
    padding: 0;
  }
  .home_services_section {
    height: auto;
  }
  .list_services li {
    width: 50%;
  }
  .list_services li:nth-child(3) {
    clear: left;
  }
  .list_services .item_content ul {
    width: 100%;
  }
  .list_services .item_content ul li {
    width: 100%;
  }
  .list_services .item_content h4:after {
    display: block !important;
  }
  .base {
    height: 42px;
  }
  .projects_big_thumb img {
    margin-bottom: -15px;
  }
  .case_studies_wrapper .base {
    height: 13px;
  }
  .our_clients_holder {
    padding: 90px 80px;
  }
  .list_boxes_out li {
    width: 50%;
  }
  .main_footer {
    height: auto;
  }
  .main_footer .home_banner_thumb {
    position: absolute;
    height: 100%;
  }
  .footerWrap {
    padding: 58px 37px 40px;
    position: static;
  }
  .right_footer .menu-item-has-children {
    margin-left: 0;
    margin-right: 54px;
    margin-top: 30px;
  }
  .our_clients_section {
    height: auto;
  }
  .our_clients_section .home_banner_thumb {
    position: absolute;
  }
  .our_clients_wrapper {
    position: static;
  }
  .box__content {
    padding: 30px;
    padding-right: 60px;
  }
  .box__content a {
    bottom: 30px;
  }
  .base_special {
    top: -32px;
  }
  .overlay_special {
    height: 100vh;
  }
  .analytics-platform {
    margin-top: 0px;
    height: 325px;
  }
  html body main .analytics-platform .visual {
    top: -30px !important;
  }
  .list_process {
    padding: 1px;
  }
  .list_process li:after {
    display: none;
  }
  .ceo_holder {
    width: 690px;
  }
  .home_bottom_banner_content {
    margin: 52px auto;
    width: 698px;
    padding: 35px 36px;
  }
  .about_partners_section {
    max-width: 655px;
  }
  .about_partners_section .black_text h1 {
    width: 605px;
    padding: 0 20px;
  }
  .left_contact {
    width: 80%;
    margin-top: 0px;
  }
  .right_contact {
    margin-top: 50px;
    width: 100%;
  }
  .footer_base_special2 {
    height: 15px;
  }
  .base_special {
    height: 32px;
  }
  .our_clients_section .base,
  .footer_base_special {
    height: 15px;
  }
  .insight_box {
    min-height: 365px;
  }
  .insight_box__content {
    padding: 20px 18px;
  }
  .insight_box__content p {
    padding-right: 0;
  }
  .challenge_section .left_challenge {
    width: 50%;
    margin-top: 50px;
    margin-left: 0px;
  }
  .challenge_section .right_challenge {
    width: 50%;
    margin-top: 0;
  }
  .deploy_section {
    padding-top: 70px;
    padding-bottom: 80px;
  }
  .deploy_section .center_img {
    margin-top: 50px;
  }
  .bear_profile {
    background-size: cover;
  }
  .right_profile {
    margin-top: 200px;
  }
  .left_profile {
    position: relative;
    margin-bottom: 30px;
  }
  .left_profile .left_profile_innter {
    max-width: 360px;
    margin-top: 60px;
  }
  .mac-holder {
    width: 485px;
    height: 210px;
    background-size: cover;
    margin-bottom: 65px;
  }
  .mac-holder {
    width: 366px;
  }
  .bioe-profile .left_profile .left_profile_innter {
    margin-top: 67px;
  }
  .bioe-profile .right_profile img {
    margin-right: -30px;
  }
  .ba-slider {
    width: 273px;
    height: 154px !important;
    -webkit-transform: translateX(-50.4%);
        -ms-transform: translateX(-50.4%);
            transform: translateX(-50.4%);
    top: 30px;
  }
  .ba-control {
    top: -webkit-calc(100% + 40px);
    top: calc(100% + 40px);
    height: 50px !important;
  }
  .ba-control-bar {
    margin-top: 25px;
  }
  .ba-control-bar:before {
    width: -webkit-calc(100% + 120px);
    width: calc(100% + 120px);
    margin-left: -60px;
  }
  .ba-control-handle {
    width: 50px;
    height: 50px;
    margin-left: -25px;
  }
  .projects_slider_section .profil_title {
    margin-top: -30px;
  }
  .has_left_space {
    padding-left: 40px;
    padding-right: 30px;
  }
  .vva-challenge .right_challenge {
    width: 45%;
  }
  .clc-challenge .left_challenge {
    margin-right: -35px;
    margin-left: 0;
    padding-right: 15px !important;
  }
  .clc-challenge .profil_title {
    padding-right: 10px;
  }
  .clc-pc-holder {
    width: 445px;
    height: 350px;
  }
  .clc-pc-holder .ba-slider {
    width: 306px;
    height: 192px !important;
    -webkit-transform: translateX(-50.5%);
        -ms-transform: translateX(-50.5%);
            transform: translateX(-50.5%);
    top: 15px;
  }
  .clc-profile {
    background-size: auto;
  }
  #third-info {
    right: 56px;
    top: 193px;
  }
  #second-info {
    right: 68px;
    top: 63px;
  }
  #first-info {
    right: 200px;
    top: 25px;
  }
  .clc-banner img {
    max-width: 80%;
  }
  .vva-mac-holder {
    margin-left: -38px;
  }
  .vva-mac-holder .ba-slider {
    width: 259.5px !important;
    height: 148px !important;
    top: 10px;
  }
  .vva-profile {
    height: auto;
  }
  .vva-profile .left_profile .left_profile_innter {
    margin-top: 58px;
  }
  .clc-profile .right_profile {
    width: 50%;
  }
  .clc-profile .right_profile img {
    margin-right: -25px;
  }
  .case_studies_wrapper {
    padding: 55px 0 70px;
  }
  .article_wrapper {
    padding: 40px 0 40px;
  }
  .article_wrapper blockquote {
    margin-left: 20px;
  }
  .projects_item__content {
    width: 100%;
  }
  .home_projects_item {
    padding: 34px 20px 28px;
  }
  .contact_title h4:before {
    margin-left: 5px;
  }
  .headerWrap .menu-item-has-children ul {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    left: 0;
    top: 0;
    margin-top: 0;
    position: static;
  }
  .headerWrap .menu-item-has-children li a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .home_banner_section .overlay {
    background-size: cover;
  }
  .hamburger {
    display: none;
  }
  .home_banner_wrapper {
    min-width: 620px;
  }
  .list_services {
    padding: 0 0px 0 110px;
  }
  .case_studies_boxes {
    padding: 0 15px;
  }
  .box__content {
    padding: 30px;
    padding-right: 155px;
  }
  .our_clients_holder {
    padding: 140px 150px;
  }
  .footerWrap {
    padding: 98px 30px 48px;
  }
  .right_footer .menu-item-has-children:first-child {
    margin-left: 0;
  }
  .analytics-platform .visual {
    top: -30px;
  }
  .right_contact {
    width: -webkit-calc(100% - 45%);
    width: calc(100% - 45%);
  }
  .list_services2__item {
    width: 207px;
  }
  .list_services2 .item_thumb {
    margin-top: 15px;
  }
  .insight_box__content p {
    padding-right: 20px;
  }
  .left_profile .left_profile_innter {
    max-width: 490px;
    margin-top: 76px;
  }
  .challenge_section .left_challenge {
    width: 50%;
    margin-left: 0;
  }
  .challenge_section .right_challenge {
    margin-top: 0px;
    float: right;
  }
  .deploy_section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .mac-holder {
    width: 485px;
    height: 280px;
    background-size: cover;
    margin-bottom: 65px;
  }
  .bioe-projects-thumb {
    margin-right: -20px;
  }
  .bioe-profile .left_profile .left_profile_innter {
    margin-top: 77px;
  }
  .ba-slider {
    width: 365px;
    height: 204px !important;
    -webkit-transform: translateX(-50.1%);
        -ms-transform: translateX(-50.1%);
            transform: translateX(-50.1%);
    top: 42px;
  }
  .ba-control {
    top: -webkit-calc(100% + 40px);
    top: calc(100% + 40px);
    height: 50px !important;
  }
  .ba-control-bar {
    margin-top: 25px;
  }
  .ba-control-bar:before {
    width: -webkit-calc(100% + 120px);
    width: calc(100% + 120px);
    margin-left: -60px;
  }
  .projects_slider_section .profil_title {
    margin-top: 35px;
  }
  .vva-mac-holder .ba-slider {
    width: 347.5px !important;
    height: 196px !important;
    top: 14px;
  }
  .clc-pc-holder .ba-slider {
    width: 335px;
    height: 210px !important;
    top: 16px;
  }
  .clc-projects-holder:after {
    margin-bottom: 20px;
  }
  .clc-challenge .left_challenge {
    margin-right: -5px;
    padding-right: 5px !important;
  }
  .clc-profile .right_profile {
    width: 50%;
  }
  .clc-profile .right_profile img {
    margin-right: -20px;
  }
  .case_studies_wrapper {
    padding: 55px 0 70px;
  }
  .list_services.list_process {
    padding-left: 0 !important;
  }
  .projects_big_thumb .clearleft .projects_item__content {
    width: 100%;
  }
  .contact_title h4:before {
    margin-left: 5px;
  }
}

@media only screen and (min-width: 1200px) {
  .challenge_section .left_challenge {
    padding-right: 37px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .analytics-platform .visual {
    bottom: unset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .analytics-platform .visual {
    height: 340px;
    bottom: unset;
  }
}

@media only screen and (max-width: 767px) {
  .analytics-platform {
    zoom: 50%;
  }
  .analytics-platform .visual {
    background-image: none !important;
    display: block !important;
  }
  .analytics-platform .visual img {
    display: block !important;
  }
  .clc-challenge {
    padding-top: 0;
  }
  .info_box span {
    -webkit-tap-highlight-color: transparent;
  }
  .info_box_holder {
    display: block;
    left: -20px !important;
    top: -105px !important;
  }
  #second-info .info_box_holder {
    display: block;
    left: -100px !important;
    top: -105px !important;
  }
  #third-info .info_box_holder {
    display: block;
    left: -110px !important;
    top: -105px !important;
  }
  .vva-projects-thumb {
    margin-top: 30px;
  }
}

@media (max-width: 320px) {
  .home_banner_wrapper h1 {
    font-size: 20px;
  }
  .home_banner_wrapper p {
    font-size: 13px;
  }
  .mac-holder {
    width: 297px;
    height: 170px;
  }
  .ba-slider {
    width: 222px;
    height: 138px !important;
    -webkit-transform: translateX(-50.7%);
        -ms-transform: translateX(-50.7%);
            transform: translateX(-50.7%);
    top: 11px;
  }
  .clc-pc-holder {
    height: 240px !important;
  }
  .clc-pc-holder .ba-slider {
    width: 206px;
    height: 128px !important;
    -webkit-transform: translateX(-50.1%);
        -ms-transform: translateX(-50.1%);
            transform: translateX(-50.1%);
    top: 10px;
  }
  .vva-mac-holder .ba-slider {
    width: 214px !important;
    height: 120px !important;
    top: 9px;
  }
  .loader_img {
    width: 280px;
  }
}

@media (min-width: 1440px) and (max-width: 1536px) {
  .clc-banner img {
    width: 60%;
  }
  .left_landing_container {
    width: 588px;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .clc-banner img {
    margin-top: 40px;
    max-width: 60%;
  }
  .contact_wrapper {
    background: url(../images/map-bg.png) no-repeat 100% 50% #fff !important;
  }
  .safari .banner_content h1 {
    margin-left: -20px;
  }
  .left_landing_container {
    width: 587px;
  }
  .right_landing_container {
    width: 660px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .list_services {
    padding: 0 0 0 60px;
  }
  .list_process li:after {
    background-size: 145px;
    width: 145px;
  }
  .list_process li:first-child:after {
    right: -webkit-calc(-100% + 110px);
    right: calc(-100% + 110px);
  }
  .list_process li:nth-child(2):after {
    right: -webkit-calc(-100% + 30px);
    right: calc(-100% + 30px);
  }
  .list_process li:nth-child(3):after {
    left: -webkit-calc(-100% + 200px);
    left: calc(-100% + 200px);
  }
  .clc-banner img {
    max-width: 70%;
  }
  .costum-query {
    width: 100%;
    position: relative;
    height: 500px;
  }
  .case_studies_new_left {
    width: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1280px) {
  .container {
    padding: 0 15px;
  }
}
