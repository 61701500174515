@include mobile {
	.services_list_items {
	    padding-bottom: 44px;
	}
	.right_header .hamburger {
		margin-top: 11px;
	}
	.home_banner_section{
		.overlay{
			background-size: cover;
		}
	}
	.is--active {
		background: rgba(26,50,68,.96); 
		.nav_wrapper{
			top: 67px;
		}
		.is--sticky {
			.nav_wrapper{
				top: 67px;
			}
		}
		.hamburger{
		    margin-top: 6px;
			span{
				&:before{
					float: left;
				}
				&:nth-child(1){
			    	transform: rotate(-45deg) translateX(-10px) translateY(10px);
			    }
			    &:nth-child(2){
			      opacity: 0;
			    }
			    &:nth-child(3){
		    	    transform: rotate(45deg) translateX(0px) translateY(0px);
			    }
			}
		}
	}
	body.menu--active{
		position: fixed;
	}
	.nav_wrapper{
		clear: both;
		float: left;
		width: 100%;
		height: 100%;
		position: fixed;
		top: -100%;
		left: 0;
	    z-index: 99;
		transition:$transition2;
	    background-color: rgba(20,31,60,.8);
		margin-top: 0;
		ul{
			text-align: center;
			width: 100%;
			float: left;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);		
		}
		li{
			display: block;
			clear: both;
			float: none;
			margin-right: 0;
			a{
				position: relative;
				font-size: 36px;
				font-weight: 300;
				color: #fff;
				text-decoration: none;
				text-transform: uppercase;
				line-height: 2.23em;
				transition:$transition2;
				float: none;
				&:after{
					position: absolute;
					bottom: -3px;
					left: 0;
					float: left;
					z-index: -1;
					content: " ";
					width: 0;
					height: 3px;
					background-position: 0 0, 0 100% ;
					background-repeat: no-repeat;
					-webkit-background-size: 101% ;
					-moz-background-size: 101% ;
					background-size: 101% ;
					background-image: -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
					background-image: -moz-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -moz-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
					background-image: -o-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -o-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
					background-image: linear-gradient(to right, #1FC887 0%, #00B7F3 100%), linear-gradient(to right, #1FC887 0%, #00B7F3 100%);
					transition: all .35s cubic-bezier(0.860, 0.000, 0.070, 1.000);
				}
				&:hover{
					&:after{
						width: 100%;
					}
				}
			}
		}	
	}
	.home_banner_wrapper{
		padding: 0 15px;
		width: 100%;
		img{
			width: 180px;
		}
		h1 {
			font-size: 25px;
			line-height: 1.35em;
			b{
				font-size: 25px;
				line-height: 1.35em;
			}
			&:after{
				margin-top: 37px;
			}
		}
	}
	.list_links_header{
		display: none;
	}
	.nav_wrapper ul{
		margin-top: -82px;
	}
	.nav_wrapper li a{
		font-size: 28px;
	}
	.base{
		height: 32px;
	}
	.base_special{
	    top: -32px;
	}
	.overlay_special {
	    height: 100vh;
	    .go_bottom2 {
		    bottom: 28px;
		}
	}
	.buttons_holder {
		a{
			width: 100%;
			margin-right: 0;
			margin-top: 10px;
			&:first-child{
				margin-top: 0;
			}
		}
	}
	.analytics-platform{
        height: 110px;
	    margin-top: -20px;
	    margin-bottom: 0!important;
	    .visual{
	    	left: unset;
	    	width: 100%;
	    	margin: 0;
	    }
	}
	.home_services_section{
		height: auto;
		padding: 40px 0; 
	}
	.projects_item {
	    margin-top: 40px;
	    padding-left: 0;
	    &__thumb{
    	    max-width: 30px;
    	    width: 30px;
	    }
	}
	.projects_item__content{
		width: calc(100% - 75px);
		p{
		    padding-right: 0px;
		}
	}
	.btn_out{
		margin-top: 45px;
	}
	.box {
		margin-top: 15px;
	    height: 250px;
	    .box__content{
	    	bottom: 0;
	    	h3{
	    		margin-bottom: 0;
	    	}
	    	a{
	    		margin-top: 20px;
	    	}
	    }
	}
	.our_clients_slider {
		margin-bottom: 0;
		.flex-control-nav{
			margin: 0 auto;
			position: static;
			transform: unset;
			margin-top: -24px;
			padding: 0 15px;
			li{
				margin-right: 15px;
				float: none;
			}
		}
	}
	.our_projects {
		margin-bottom: 40px;
		ul{
			margin-top: 10px;
		}
		li{
			width: 50%;
			padding: 0 15px;
		}
	}
	.list_services{
		padding: 0 20px 0 60px;
		li{
			width: 100%;
		}
		.item_content {
			h4:after{
				display: block!important;
			}
		}
	}
	.section_title {
		h1{
			font-size: 28px;
			line-height: 1.35em;
		}
	}
	.home_projects_section{
		padding-top: 50px;
	}
	.projects_big_thumb img{
		margin-bottom:-10px; 
	}
	.case_studies_wrapper {
		padding: 45px 0 35px;
		.base{
			height: 8px;
		}
	}
	.case_studies_boxes{
		padding: 0
	}
	.box__content{
	    padding: 20px 20px;
	    padding-right: 60px;
	    a{
	    	bottom: 30px;
	    }
	}
	.list_of_case_studies_post {
		li {
			.box__content {
				height: auto;
				padding-left: 20px;
				padding-right: 20px;
				bottom: 0;
				a{
					margin-bottom: 0;
				}
			}
		}
	}
	.our_clients_section {
		.home_banner_thumb{
			position: absolute;
		}
		.our_clients_wrapper{
			position: static;
		}
		height: auto;
		.base{
			height: 15px;
		}
	}
	.our_clients_section .home_banner_thumb{
		height: calc(100% + 20px);
		top: -20px;
	}
	.our_clients_holder{
		margin-top: 0;
		h1{
			max-width: 100%;
			font-size: 32px;
		}
		img{
			max-width: 250px;
		}
	}
	.our_clients_slider .slides li{
		height: 600px;
	}
	.client_box__content{
		width: calc(100% - 0px);
	}
	.our_clients_slider {
		.flex-direction-nav{
			margin-top: 30px;		
		}
	}
	.home_insight_section{
		padding-top: 35px;
    	padding-bottom: 45px;
	}
	.insight_box{
		margin-top: 15px;
	}
	.home_insight_wrapper .btn_out{
		margin-top: 30px;
	}
	.insight_box__content p{
		padding-right: 40px
	}
	.list_boxes_out{
		li{
			width: 100%;
		}
	}
	.main_footer{
		height: auto;
		.home_banner_thumb{
			position: absolute;
			height: 100%;
		}
	}
	.footerWrap{
		padding: 48px 0px;
		position: static;
	}
	.right_footer{
		margin-left: 10px;
		width: 100%;
		.menu-item-has-children{
		    width: 50%;
			margin-top: 20px;
			margin-left: 0;
		}
		li{
			&:nth-child(2n+1){
				clear: left;
			}
		}
	}
	.bottom_footer {
	    margin-top: 50px;
	}
	.ceo_wrapper{
		width: 100%;
		height: auto;
	    padding: 62px 15px 62px 15px;
	}
	.ceo_holder{
		width: 100%;
	}
	.ceo_content {
		width: 100%;
		clear: both;
		margin-top: 20px;
		p{
			margin-top: 20px;
			font-size: 24px;
		}
	}
	.home_bottom_banner_section{
		width: 100%;
		height: auto;
		position: relative;
		.home_banner_thumb{
			position: absolute;
		}

	}
	.home_bottom_banner_content {
	    margin: 55px auto;
	    width: 90%;
	    padding: 20px 25px;
	    height: auto;
	    position: static;
	    transform:  unset!important;
	    h1{
	    	font-size: 26px;
	    	&:after{
				margin-top: 15px;
	    	}
	    }
	    p{
	    	margin-top: 15px;	
	    }
	}
	.about_partners_section {
		height: auto;
		float: left;
		width: 100%;
		.black_text h1{
			width: 100%;
			display: block;
			padding: 0;
		}
	}
	.blue_wrapper {
	    height: auto;
	    padding-top: 70px;
	    padding-bottom: 70px;
	}
	.list_services{
		padding: 0;
		display: block;
		li{
			width: 100%;
			margin-top: 30px!important;
			&:after{
				display: none;
			}
		}
		.item_thumb{
			margin-left: 0;
		}
	}
	.analytics-platform {
	    zoom: unset!important;
	    margin-bottom: 50px;
	    float: left;
	}
	html body main .analytics-platform .visual{
		top: -30px!important;
	}
	.contact_banner{
		height: 500px;
	}
	.left_contact {
	    width: 100%;
	    padding-left: 25px;
	    margin-top: 30px;
	}
	.right_contact{
	    width: 100%;
		padding: 22px 29px;
		margin-top: 50px;
		height: 565px;
		input[type=submit] {
		    width:calc(100% - 59px);
		    position: absolute;
		    bottom: 22px;
		    left: 29px;
		}
	}
	.footer_base_special,
	.footer_base_special2{
	    height: 15px;
	}
	.list_services2__item{
		float: none;
		margin: 0 auto!important;
		margin-top: 30px!important;
	}
	.our_projects li{
		&:nth-child(2n+1){
			clear: left;
		}
	}
	.projects_big_thumb{
		padding: 0;
		padding-left: 10px;
	    padding-bottom: 38px;
	}
	.white_header{
		.right_header .hamburger span:after, 
		.right_header .hamburger span:before{
		    background-color: #1a3346;
		}
		.nav_wrapper li a{
			position: relative;
			z-index: 6;
			color: #fff;
			&:after{
				display: none!important;
			}
		}
	}
	.banner_content{
		h1{
			width: 100%;
			font-size: 32px;
			letter-spacing: -1px;
			&:after{
				display: none;
			}
		}
	}
	.bear_profile{
	    background-size: cover;
	    background: #16216a
	}
	.left_profile{
		position: relative;
		width: 100%;
	    padding: 0 15px;
	    background: transparent;
	}
	.right_profile{
		width: 100%;
		margin-top: 30px;
	}
	.profil_title {
		h1{
			font-size: 32px;
		}
		p{
			font-size: 15px;
			line-height: 1.463em;
			margin-top: 15px;
		}
	}
	.challenge_section {
	    padding: 38px 0;
		.left_challenge{
			width: 100%;
			margin-left: 0;
		}
		.right_challenge{
		    width: 90%;
			margin-top: 20px;
		}
	}
	.mac-holder{
		width: 347px;
		height: 200px;
	    background-size: cover;
        margin-bottom: 65px;
	}
	.ba-slider{
		width: 261px;
		height: 145px!important;
		transform: translateX(-50.2%);
	    top: 30px;
	}
	.ba-control{
	    top: calc(100% + 40px);
        height: 50px!important;
	}
	.ba-control-bar{
		margin-top: 25px;
		&:before{
		    width: calc(100% + 120px);
	        margin-left: -60px;
		}
	}
	.ba-control-handle{
		width: 50px;
		height: 50px;
	    margin-left: -25px;
	}
	.projects_slider_section {
		.profil_title{
			padding-left: 0;
			margin-top: 10px;
		}
	}
	.has_left_space {
	    padding-left: 10px;
	    padding-right: 30px;
	}
	.center_img{
		margin-top: 40px;
	}
	.deploy_section {
	    padding-top: 40px;
	    padding-bottom: 40px;
	}
	.full_height_wrapper{
		background-size: cover;
	}
	.one-raiser-challenge {
		padding-top: 0;
		.left_challenge{
			width: 100%!important;
		}
		.right_challenge{
			width: 100%!important;
			padding-left: 0;
		}
	}
	.one-raiser-projects .one-raiser-projects-holder{
		margin-left: 0;
	}
	.vva-projects{
		padding-top: 20px;
	}
	.bioe-profile .right_profile img{
		margin-right: 0;
	}
	.bioe-projects-thumb{
		margin-right: 0;
		margin-top: 30px;
	}
	.bioe-projects{
		padding-top: 10px;
		padding-bottom: 30px;
	}
	.bioe-profile .left_profile .left_profile_innter {
	    margin-top: 70px;
	}
	.contact_wrapper {
		overflow-y: scroll;
	    background-size: 100%!important;
		&::-webkit-scrollbar { 
		    display: none; 
		}
		.close_btn{
			top: 20px;
			right: 20px;
			width: 47px;
			height: 47px;
			span{
				width: 25px;
				left: 55%
			}
		}
	}
	.contact_small_title{
		margin-top: 30px;
	}
	.contact_info {
	    margin-left: 32px;
	}
	.contact_copyright{
		position: static;
		margin-top: 40px;
	}
	.contact_title {
		h2{
			font-size: 36px;
		}
	}
	.home_banner_wrapper img{
		width: auto;
		max-width: 100%;
	}
	.clc-banner {
		p{
			font-size: 14px;
			margin-top: 15px;
		}
		h1{
			font-size: 28px;
			line-height: 1.15em;
		}
		img{
			margin-top: 30px;
			margin-bottom: -50px;
		}
	}
	.clc-profile{
		height: auto;
		background: url(../images/clc-profile-bg.png) no-repeat;
	    background-position: bottom;
	    background-size: 100%;
	    .right_profile{
	    	width: 100%;
			margin-top: 35px;
			margin-bottom: 20px;
	    	img{
	    		margin-right: 0;
	    	}
	    }
	}
	#first-info {
	    right: 200px;
	    top: 5px;
	}
	#second-info {
	    right: 118px;
	    top: 43px;
	}
	#third-info {
	    right: 98px;
	    top: 143px;
	}
	.clc-projects-holder{
		.profil_title{
		    padding: 0 15px;
		}
		&:after{
			margin-top: 20px;
		}
	}
	.clc-pc-holder {
		height: 280px!important;
		margin-bottom: -50px;
		float: none;
		.ba-slider{
			width: 239px;
		    height: 150px!important;
		    transform: translateX(-50.6%);
		    top: 12px;
		}
	}
	.clc-projects{
		padding-top: 50px;
		.middle_banner{
			margin-top: 20px;
		}
	}
	.left_profile .left_profile_innter{
		margin-top: 60px;
	}
	.clc-challenge{
		.left_challenge{
			margin-right: 0;
			padding-right: 0!important;
			margin-top: 30px;
		}
	}
	.one-raiser-profile .left_profile .left_profile_innter {
	    margin-top: 66px;
	}
	.one-raiser-projects{
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.vva-profile{
		height: auto;
		.left_profile .left_profile_innter{
			margin-top: 60px;
		}
		.right_profile {
		    margin-top: 60px;
		}
		.slider_nav_info p{
			margin-left: 0;
			margin-bottom: 40px;
		}
	}
	.vva-mac-holder {
		margin-left: -40px;
		margin-bottom: 35px;
		.ba-slider {
		    width: 248px!important;
		    height: 140px!important;
		    transform: translateX(-48.5%);
		    top: 10px;
		    left: 49%;
		}
		.ba-control{
			top: -22px;
			left: calc(100% + 46px);
		}
		.ba-control-bar:before{
		    height: calc(100% + 120px);
	        margin-top: -10px;
	        margin-left: 2px;
		}
	}
	.vva-challenge .right_challenge{
		width: 100%;
	}
	.case_studies_article{
		height: 450px;
		&_content {
			h1 {
				font-size: 28px;
			}
		}
	}

	.article_container{
		max-width: 100%;
		padding: 0 15px;
	}
	.article_wrapper{
		padding: 30px 0; 
	}
	.blog_slider_wrapper {
		height: 100vh;
		.our_clients_holder h1{
			font-size: 28px;
		}
	}
	.article_wrapper blockquote{
	    margin-left: 20px;
	}
	.article_wrapper pre{
		padding: 20px;
		max-width: 100%;
	}
	.article_wrapper p{
		margin-bottom: 20px;
	}
	.projects_big_thumb{
		padding-bottom: 30px;
		.projects_item__content {
			width: 100%;
		}
		.clearleft{
			.projects_item__content{
				padding-right: 0;
			}
		}
		.pull-right{
			.projects_item__content{
				padding-left: 0;
			}
		}
	}
	.home_projects_item {
	    padding: 34px 30px 28px;
	}
	.author_in_post .insight_box__author__content p{
		padding-right: 0;
	}
	.no_border {
		width: 100%!important; 
		h1{
			font-size: 28px!important;
		}
	}
	.list_process .item_content h3{
		font-size: 20px;
	}
	.our_process_big_thumb,
	.core_values{
		padding: 38px 0 43px;
	}
	.abous_us_item .item_content{
		width: calc(100% - 65px);
	}
	.new_banner{
		height: 300px;
	}
	.contact_title h4:before{
		margin-left: 5px;
	}
	.middle_column {
	    position: static;
	    transform: none;
	    text-align: center;
	    margin-top: 40px;
	}
	.mob-mt67{
		margin-top: 67px
	}
	.gfield{
		width: 100%;
	}
	.headerWrap{
		.menu-item-has-children{
			ul{
				transform: none;
			    left: 0;
			    top: 0;
			    margin-top: 0;
			    position: static;
			}
		    li{
		    	a{
		    		font-size: 18px;
		    	}
		    }
		}
	}
}
@include tablet{
	.home_banner_section{
		.overlay{
			background-size: cover;
		}
	}
	.nav_wrapper{
		margin-top: 0;
		background-color: rgba(20,31,60,.8);
	}
	.right_header .hamburger{
		margin-top: 15px;
	}
	.is--active {
		background: rgba(26,50,68,.8); 
		.nav_wrapper{
			top: 76px;
		}
		.is--sticky {
			.nav_wrapper{
				top: 72px;
				height: 100vh;
			}
		}
		.hamburger{
		    margin-top: 9px;
			span{
				&:before{
					float: left;
				}
				&:nth-child(1){
			    	transform: rotate(-45deg) translateX(-10px) translateY(10px);
			    }
			    &:nth-child(2){
			      opacity: 0;
			    }
			    &:nth-child(3){
		    	    transform: rotate(45deg) translateX(0px) translateY(0px);
			    }
			}
		}
	}
	.menu--active{
		position: fixed;
	}
	.white_header{
		.right_header .hamburger span:after, 
		.right_header .hamburger span:before{
		    background-color: #1a3346;
		}
		.nav_wrapper li a{
			color: #fff;
		}
	}
	.home_banner_wrapper{
		width: 100%;
	}
	.banner_content {
		h1{
			width: 100%;
			&:after{
				display: none;
			}
		}
	}
	.nav_wrapper{
		clear: both;
		float: left;
		width: 100%;
		height: 100%;
		position: fixed;
		top: -100%;
		left: 0;
	    z-index: 99;
		transition:$transition2;
		background-color: rgba(26,50,68,.96); 
		ul{
			text-align: center;
			width: 100%;
			float: left;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);		
		}
		li{
			display: block;
			clear: both;
			float: none;
			margin-right: 0;
			a{
				position: relative;
				font-size: 36px;
				font-weight: 300;
				color: #fff;
				text-decoration: none;
				text-transform: uppercase;
				line-height: 2.23em;
				transition:$transition2;
				float: none;
				&:after{
					position: absolute;
					bottom: -3px;
					left: 0;
					float: left;
					z-index: -1;
					content: " ";
					width: 0;
					height: 3px;
					background-position: 0 0, 0 100% ;
					background-repeat: no-repeat;
					-webkit-background-size: 101% ;
					-moz-background-size: 101% ;
					background-size: 101% ;
					background-image: -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
					background-image: -moz-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -moz-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
					background-image: -o-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -o-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
					background-image: linear-gradient(to right, #1FC887 0%, #00B7F3 100%), linear-gradient(to right, #1FC887 0%, #00B7F3 100%);
					transition: all .35s cubic-bezier(0.860, 0.000, 0.070, 1.000);
				}
				&:hover{
					&:after{
						width: 100%;
					}
				}
			}
		}	
	}
	.list_links_header{
	    margin-right: 30px;
	    margin-top: 5px;
	    li{
	    	&:first-child{
	    		&:before{
	    			display: none;
	    		}
	    		
	    	}
	    }
	}
	.list_services2{
	    max-width: 558px!important;
	    li{
	    	&:nth-child(2n){
	    		margin-right: 0
	    	}
	    }
	}
	.title_max_width {
	    max-width: 640px;
	}
	.projects_item__thumb{
		width: 30px;
	}
	.our_clients_holder img{
	    max-width: 400px;
	}
	.our_projects li{
		width: 25%;
		&:nth-child(4n+1){
			clear: left;
		}
	}

	.projects_item__content {
	    width: calc(100% - 70px);
		p{
			padding-right: 0
		}
	}
	.home_banner_wrapper{
		width: 620px;
	}
	.case_studies_boxes{
		padding: 0
	}
	.home_services_section{
		height: auto;
	}
	.list_services{
		li{
			width: 50%;
			&:nth-child(3){
				clear: left;
			}
		}
		.item_content ul{
			width: 100%;
			li{
			    width: 100%;
			}
		}
		.item_content {
			h4:after{
				display: block!important;
			}
		}
	}
	.base{
		height: 42px;
	}
	.projects_big_thumb img{
		margin-bottom: -15px;
	}
	.case_studies_wrapper .base{
		height: 13px;
	}
	.our_clients_holder {
	    padding: 90px 80px;
	}
	.list_boxes_out{
		li{
			width: 50%;
		}
	}
	.main_footer {
		height: auto;
		.home_banner_thumb{
			position: absolute;
			height: 100%;
		}
	}
	.footerWrap {
	    padding: 58px 37px 40px;
	    position: static;
	}
	.right_footer .menu-item-has-children{
		margin-left: 0;
		margin-right: 54px;
		margin-top: 30px;
	}
	.our_clients_section{
		height: auto;
		.home_banner_thumb{
			position: absolute;
		}
	}
	.our_clients_wrapper{
		position: static;
	}
	.box__content{
		padding: 30px;
		padding-right: 60px;
		a{
			bottom: 30px;
		}
	}
	.base_special{
	    top: -32px;
	}
	.overlay_special {
	    height: 100vh;
	}
	.analytics-platform{
		margin-top: 0px;
		height: 325px;
	}
	html body main .analytics-platform .visual{
		top: -30px!important;
	}
	.list_process{
		padding: 1px;
		li{
			&:after{
				display: none;
			}
		}
	}
	.ceo_holder {
	    width: 690px;
	}
	.home_bottom_banner_content {
	    margin: 52px auto;
	    width: 698px;
	    padding: 35px 36px;
	}
	.about_partners_section{
	    max-width: 655px;
	    .black_text h1{
	    	width: 605px;
    	    padding: 0 20px;
	    }
	}
	.left_contact {
	    width: 80%;
	    margin-top: 0px;
	}
	.right_contact {
	    margin-top: 50px;
	    width: 100%;
	}
	.footer_base_special2 {
	    height: 15px;
	}
	.base_special{
		height: 32px;
	}
	.our_clients_section .base,
	.footer_base_special{
	    height: 15px;
	}
	.insight_box{
		min-height: 365px;
	}
	.insight_box__content{
		padding: 20px 18px;
		p{
			padding-right: 0		
		}
	}
	.challenge_section {
		.left_challenge{
			width: 50%;
			margin-top: 50px;
			margin-left: 0px;
		}
		.right_challenge{
			width: 50%;
			margin-top: 0;
		}
	}
	.deploy_section {
	    padding-top: 70px;
	    padding-bottom: 80px;
	    .center_img{
	    	margin-top: 50px;
	    }
	}
	.bear_profile{
	    background-size: cover;
	}
	.right_profile{
		margin-top: 200px;
	}
	.left_profile{
		position: relative;
		margin-bottom: 30px;
		.left_profile_innter{
			max-width: 360px;
			margin-top: 60px;
		}
	}
	.mac-holder{
		width: 485px;
		height: 210px;
	    background-size: cover;
        margin-bottom: 65px;
	}
	.mac-holder {
	    width: 366px;
	}
	.bioe-profile .left_profile .left_profile_innter {
	    margin-top: 67px;
	}
	.bioe-profile .right_profile img {
	    margin-right: -30px;
	}
	.ba-slider{
		width: 273px;
		height: 154px!important;
		transform: translateX(-50.4%);
	    top: 30px;
	}
	.ba-control{
	    top: calc(100% + 40px);
        height: 50px!important;
	}
	.ba-control-bar{
		margin-top: 25px;
		&:before{
		    width: calc(100% + 120px);
	        margin-left: -60px;
		}
	}
	.ba-control-handle{
		width: 50px;
		height: 50px;
	    margin-left: -25px;
	}
	.projects_slider_section .profil_title{
	    margin-top: -30px;
	}
	.has_left_space {
	    padding-left: 40px;
	    padding-right: 30px;
	}
	.vva-challenge{
		.right_challenge {
			width: 45%;
		}
	}
	.clc-challenge {
		.left_challenge {
		    margin-right: -35px;
		    margin-left: 0;
		    padding-right: 15px!important;
		}
		.profil_title{
			padding-right: 10px;
		}
	}
	.clc-pc-holder{
		width: 445px;
		height: 350px;
		.ba-slider{
			width: 306px;
		    height: 192px!important;
			transform: translateX(-50.5%);
			top: 15px;
		}
	}
	.clc-profile{
		background-size: auto;
	}
	#third-info {
	    right: 56px;
	    top: 193px;
	}
	#second-info {
	    right: 68px;
	    top: 63px;
	}
	#first-info {
	    right: 200px;
	    top: 25px;
	}
	.clc-banner img{
		max-width: 80%;
	}
	.vva-mac-holder {
	    margin-left: -38px;
		.ba-slider{
			width: 259.5px!important;
	    	height: 148px!important;
	    	top: 10px;
		}
	}
	.vva-profile{
		height: auto;
		.left_profile {
			.left_profile_innter{
			    margin-top: 58px;
			}
		}
	}
	.clc-profile {
    	.right_profile{
	    	width: 50%;
	    	img {
			    margin-right: -25px;
			}
		}
    }
    .case_studies_wrapper{
    	padding: 55px 0 70px;
    }
    
    .article_wrapper {
    	padding: 40px 0 40px;
	    blockquote{
		    margin-left: 20px;
		}
    }
    .projects_item__content{
    	width: 100%;
    }
    .home_projects_item {
	    padding: 34px 20px 28px;
	}
	.contact_title h4:before{
		margin-left: 5px;
	}
	.headerWrap{
		.menu-item-has-children{
			ul{
				transform: none;
			    left: 0;
			    top: 0;
			    margin-top: 0;
			    position: static;
			}
		    li{
		    	a{
		    		font-size: 20px;
		    	}
		    }
		}
	}
}
@include tablet-big{
	.home_banner_section{
		.overlay{
			background-size: cover;
		}
	}
	.hamburger{
		display: none;
	} 
	.home_banner_wrapper{
		min-width: 620px;
	}
	.list_services {
	    padding: 0 0px 0 110px;
	}
	.case_studies_boxes {
	    padding: 0 15px;
	}
	.box__content{
		padding: 30px;
		padding-right: 155px;
	}
	.our_clients_holder {
	    padding: 140px 150px;
	}
	.footerWrap {
	    padding: 98px 30px 48px;
	}
	.right_footer{
		.menu-item-has-children{
			&:first-child{
				margin-left: 0
			}
		}
	}
	.analytics-platform .visual{
	    top: -30px;
	}
	.right_contact {
	    width: calc(100% - 45%);
	}
	.list_services2__item{
		width: 207px;
	}
	.list_services2 .item_thumb{
	    margin-top: 15px;
	}
	.insight_box__content p{
	    padding-right: 20px;
	}
	.left_profile .left_profile_innter{
		max-width: 490px;
    	margin-top: 76px;
	}
	.challenge_section {
		.left_challenge{
			width: 50%;
			margin-left: 0;
		}
		.right_challenge{
			margin-top: 0px;
			float: right;
		}
	} 
	.deploy_section {
	    padding-top: 70px;
	    padding-bottom: 70px;
	}
	.mac-holder{
		width: 485px;
		height: 280px;
	    background-size: cover;
        margin-bottom: 65px;
	}
	.bioe-projects-thumb{
	    margin-right: -20px;
	}
	.bioe-profile .left_profile .left_profile_innter {
	    margin-top: 77px;
	}
	.ba-slider{
		width: 365px;
		height: 204px!important;
		transform: translateX(-50.1%);
		top: 42px;
	}
	.ba-control{
	    top: calc(100% + 40px);
        height: 50px!important;
	}
	.ba-control-bar{
		margin-top: 25px;
		&:before{
		    width: calc(100% + 120px);
	        margin-left: -60px;
		}
	}
	.projects_slider_section .profil_title{
	    margin-top: 35px;
	}
	.vva-mac-holder .ba-slider{
		width: 347.5px!important;
		height: 196px!important;
		top: 14px;
	}
	.clc-pc-holder .ba-slider{
		width: 335px;
		height: 210px!important;
	    top: 16px;
	}
    .clc-projects-holder:after{
    	margin-bottom: 20px;
    }
    .clc-challenge .left_challenge{
    	margin-right: -5px;
	    padding-right: 5px!important;
    }
    .clc-profile {
    	.right_profile{
	    	width: 50%;
	    	img {
			    margin-right: -20px;
			}
		}
    }
    .case_studies_wrapper{
    	padding: 55px 0 70px;
    }
    .list_services.list_process{
    	padding-left: 0!important;
    }
    .projects_big_thumb .clearleft .projects_item__content{
    	width: 100%;
    }
    .contact_title h4:before{
		margin-left: 5px;
	}
}

@include desktop {
	.challenge_section {
		.left_challenge {
			padding-right: 37px;
		}
	}
}

@include tablet-big {
	.analytics-platform .visual{
		bottom: unset;
	}
}
@include tablet {
	.analytics-platform .visual{
		height: 340px;
		bottom: unset
	}
}
@include mobile {
	.analytics-platform {
	    zoom: 50%;
		.visual{
			background-image: none!important;
			display: block!important;
			img{
				display: block!important;
			}
		}
	}
	.clc-challenge{
		padding-top: 0
	}
	.info_box {
		span{
			-webkit-tap-highlight-color: transparent;
		}
	}
	.info_box_holder{
		display: block;
		left: -20px!important;
	    top: -105px!important;
	}
	#second-info{
		.info_box_holder {
		    display: block;
		    left: -100px!important;
		    top: -105px!important;
		}
	}
	#third-info{
		.info_box_holder {
		    display: block;
		    left: -110px!important;
		    top: -105px!important;
		}
	}
	.vva-projects-thumb{
		margin-top: 30px;
	}
}
@media (max-width: 320px) {
	.home_banner_wrapper  h1{
		font-size: 20px;
	}
	.home_banner_wrapper  p{
		font-size: 13px;
	}
	.mac-holder {
	    width: 297px;
	    height: 170px;
	}
	.ba-slider {
	    width: 222px;
	    height: 138px!important;
	    transform: translateX(-50.7%);
	    top: 11px;
	}
	.clc-pc-holder {
    	height: 240px!important;
		.ba-slider{
			width: 206px;
			height: 128px!important;
			transform: translateX(-50.1%);
			top: 10px;
		}
    }
    .vva-mac-holder .ba-slider{
		width: 214px!important;
		height: 120px!important;
		top: 9px;
    }
    .loader_img{
		width: 280px;
    }
}
@media (min-width: 1440px) and (max-width: 1536px) {
	// .analytics-platform {
	//     margin-bottom: -262px!important;
	// }
	.clc-banner img {
    	width: 60%;
    }
	.left_landing_container {
	    width: 588px;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
	.clc-banner img{
	    margin-top: 40px;
		max-width: 60%
	}
	.contact_wrapper{
	    background: url(../images/map-bg.png) no-repeat 100% 50% #fff!important;
	}
	.safari{
		.banner_content h1{
			margin-left: -20px;
		}
	}
	.left_landing_container {
		width: 587px;
	}
	.right_landing_container{
		width: 660px;
	}

}
@media (min-width: 991px) and (max-width: 1200px) {
	.list_services {
	    padding: 0 0 0 60px;
	}
	.list_process li{
		&:after{
			background-size: 145px;
			width: 145px;
		}
		&:first-child:after {
		    right: calc(-100% + 110px);
		}
		&:nth-child(2):after {
		    right: calc(-100% + 30px);
		}
		&:nth-child(3):after {
		    left: calc(-100% + 200px);
		}
	}
	.clc-banner img{
		max-width: 70%
	}
	.costum-query{
		width: 100%;
		position: relative;
		height: 500px;
	}
	.case_studies_new_left{
		width: 100%;
	}
}
@media (min-width: 1200px) and (max-width: 1280px) {
	.container {
		padding: 0 15px;
	}
}