body {
	@include flexCenter(true,false);
}
.block_section{
	@extend %fullBlock;
}
.home_banner_section{
	@extend %fullBlock;
	height: 100%;
	position: relative;
	.home_banner_thumb{
		width: 100%;
		height: 100%;
		position: relative;
		img{
		 	@extend %imgCropped;
		}
	}
	.overlay{
		background-color: rgba(20, 31, 60, 0.7); 
	}
}
.home_banner_wrapper{
	float: left;
	@extend %centered;
	text-align: center;
	h1{
		margin-top: 40px;
		color: #fff;
		line-height: 1.54em;
		font-size: 26px;
		font-weight: 200;
		display: flex;
		flex-direction: column;
		align-items: center;
		b{
			font-size: 36px;
			font-weight: bold;
			display: block;
		}
		&:after{
			content: "";
			display: block;
			width: 85px;
			height: 2px;
			background-color: #fff;
			margin-top: 47px;
		}
	}
	p{
		font-size: 24px;
		font-weight: 400;
		line-height: 1.25em;
		margin-top: 32px;
		color: #fff;
	}
}
.buttons_holder{
	display: block;
	margin-top: 50px;
	text-align: center;
	a{
		width: 276px;
		padding: 21px 0;
		display: inline-block;
		margin-right: 57px;
		font-size: 18px;
		color: #fff;
		font-weight: 400;
		text-decoration: none;
		border-radius: 3px;
		border-left: 2px solid #1FC887;
		border-right: 2px solid #00B7F3;
		position: relative;
		background-position: 0 0, 0 100% ;
		background-repeat: no-repeat;
		-webkit-background-size: 101% 2px;
		-moz-background-size: 101% 2px;
		background-size: 101% 2px;
		background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=),url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
		background-image: -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
		background-image: -moz-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -moz-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
		background-image: -o-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -o-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
		background-image: linear-gradient(to right, #1FC887 0%, #00B7F3 100%), linear-gradient(to right, #1FC887 0%, #00B7F3 100%);
		z-index: 2;

		&:after{
			@extend %centered;
			float: left;
			z-index: -1;
			content: " ";
			width: 0;
			height: 100%;
			background-position: 0 0, 0 100% ;
			background-repeat: no-repeat;
			-webkit-background-size: 101% ;
			-moz-background-size: 101% ;
			background-size: 101% ;
			background-image: -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -webkit-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
			background-image: -moz-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -moz-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
			background-image: -o-linear-gradient(left, #1FC887 0%, #00B7F3 100%), -o-linear-gradient(left, #1FC887 0%, #00B7F3 100%);
			background-image: linear-gradient(to right, #1FC887 0%, #00B7F3 100%), linear-gradient(to right, #1FC887 0%, #00B7F3 100%);
			transition: all .35s cubic-bezier(0.860, 0.000, 0.070, 1.000);
		}
		&:hover{
			&:after{
				width: 100%;
			}
		}
		&:last-child{
			margin-right: 0;
		}
	}
}
.overlay{
	@extend %fullBlock;
	position: absolute;
	left:0;
	top:0;
	height: 100%;
}
.go_bottom{
	width: 24px;
	height: 24px;
	position: absolute;
	bottom: 28px;
    z-index: 13;
	left: 50%;
	transform:translateX(-50%);
	background-size:24px; 
	transition: $transition1;
	padding: 7px 8px;
	width: 28px;
	height: 42px;
	border-radius: 14px;
	border: solid 3px #ffffff;
	&:after{
		content: " ";
		width: 7px;
		height: 12px;
		bottom: 17px;
		border-radius: 4px;
		background-color: #ffffff;
		position: absolute;
		transition: $transition1;
	}
	&:hover{
		&:after{
			bottom: 7px;
		}
	}
}
.green_btn{
	background-image: url(../images/down-arrow-green.svg);
}
.home_services_section{
	@extend %fullBlock;
	position: relative;
	padding: 70px 0 65px;
}
.base {
    left: 0px;
    top: 0px;
    position: absolute;
    height: 62px;
    width: 100%;
}
#base3 {
    left: 0;
}

.triangle {
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to left bottom, rgba(26, 50, 68, 0.92) 50%,transparent 52%);
}
.triangle2 {
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(to right bottom, rgba(26, 50, 68, 0.92) 50%,transparent 52%);
}
.gecko,
.no-object-fit{
	.triangle{
	    background: linear-gradient(to left bottom, rgba(26, 50, 68, 0.92) 50%,transparent 50%);
	}
	.triangle2 {
				
	    background: linear-gradient(to right bottom, rgba(26, 50, 68, 0.92) 50%,transparent 50%);
	}
}
.gecko,
.no-object-fit{
	.our_clients_section {
		.triangle{
		    background: linear-gradient(to left bottom,#fff 50%,transparent 50%)
		}
		.triangle2 {
		    background: linear-gradient(to right bottom,#fff 50%,transparent 50%);
		}
	}	
}

.section_title{
	@extend %fullBlock;
	text-align: center;
	h1{
		color: #fff;
		line-height: 1.2em;
		display: flex;	
		flex-direction: column;
		align-items: center;
		font-size: 36px;
		font-weight: bold;
		&:after{
			content: "";
			display: block;
			width: 62px;
			height: 3px;
			background-color: #fff;
			margin-top: 20px;
		}
	}
	p{
		font-size: 14px;
		font-weight: 500;
		margin: 0 auto;
		margin-top: 22px;
		color: #fff;
		line-height: 1.72em;
		max-width: 813px;
	}
}
.page_title{
	h1{
		color: #fff;
		line-height: 1.54em;
		display: flex;	
		flex-direction: column;
		align-items: center;
		font-size: 36px;
		font-weight: 300;
		&:after{
			display: none;
		}
	}
	p{
		font-size: 18px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.69em;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
		max-width: 815px;
		margin-top: 16px;
	}
}

.list_services2{
	max-width: 1091px!important;
	display: block;
	margin: 0 auto;
	float: none;
	padding: 28px;
	text-align: center;
	&__item{
		float: left;
		width: 237px;
		margin-top: 32px;
		margin-right: 28px;
	    height: 250px;
		a{
			float: left;
			height: 100%;
			width: 100%;
			padding: 30px 19px;
			border: 1px solid #E8E8E8;
			border-radius: 3px;
			transition: $transition1;
			text-decoration: none;
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    flex-direction: column;
			&:hover{
				border-color: transparent;
				background: #FFFFFF;
				box-shadow: 0 17px 34px 0 rgba(0,0,0,0.08);
			}
		}
		&:last-child{
			margin-right: 0
		}
	}
	.item_thumb{
		max-width: 92px;
		max-height: 80px;
		margin: 0 auto;
	}
	.item_content{
		width: 100%;
		margin-top: 25px;
		height: 60px;
		display: flex;
	    justify-content: center;
	    align-items: center;
		h4{
			font-size: 18px;
			font-weight: 600;
			line-height: 1.6em;
			color: #2B2B2B;
			letter-spacing: 0;
		}
	}
}

.list_services {
  padding: 0 50px 0 150px;
  li:last-child h4:after {
    display: none;
  }
}

.list_services__item {
  float: left;
  width: 25%;
  margin-top: 40px;
}

.list_services {
  .item_thumb {
    width: 120px;
    height: 120px;
    border: 1px solid #fff;
    border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: -60px;
  }
  .item_content {
    width: 100%;
    margin-top: 20px;
    color: #fff;
    h4 {
      font-size: 14px;
      font-weight: bold;
      position: relative;
      &:after {
        content: "";
        width: -webkit-calc(100% - 27px);
        width: calc(100% - 27px);
        height: 1px;
        margin-right: 9px;
        background-color: #fff;
        float: right;
        margin-top: 15px;
      }
      &:before {
        content: "";
        float: left;
        width: 10px;
        margin-right: -10px;
        margin-top: 25px;
        height: 10px;
        border: 1px solid #fff;
        border-radius: 100%;
        background-color: #fff;
      }
    }
    ul {
      float: left;
      margin-top: 12px;
      clear: both;
      li {
        margin-top: 10px;
        font-size: 14px;
        color: #fff;
        text-decoration: none;
        font-weight: 200;
      }
    }
    p {
      float: left;
      clear: both;
      margin-top: 10px;
      font-size: 14px;
      color: #fff;
      text-decoration: none;
      font-weight: 200;
      &:first-of-type {
        margin-top: 22px;
      }
    }
  }
}

.black_title{
	h1{
		color: #26415e;
	}
	a{
		color: #26415e;
	}
	p{
		color: #26415e;
	}
}
.white_title{
	h1{
		color: #fff;
	}
	p{
		color: #fff;
	}
}
.no_border{
	h1{
		&:after{
			display: none;
		}
	}
}
.black_text{
	p,
	h1{
		color: #162A39;
	}
}
.colored_line_title{
	h1{
		&:after{
			background-image: linear-gradient(-59deg, #43B9EA 0%, #47B9DD 6%, #4BBAC4 21%, #4DBAAF 36%, #4DBA9F 51%, #4DBB94 67%, #4DBC8D 83%, #4DBC8B 100%);;
		}
	}
}
.home_projects_section{
	@extend %fullBlock;
	position: relative;
	padding-top: 95px;
	.base{
		height: 20px;
	}
	.triangle{
		background: linear-gradient(to left bottom, #141f3c 50%,transparent 52%);
	}
	.triangle2{
		background: linear-gradient(to right bottom, #141f3c 50%,transparent 52%) ;
	}
}
.gecko,
.no-object-fit{
	.home_projects_section{
		.base{
			.triangle{
			    background: linear-gradient(to left bottom, #141f3c 50%, transparent 50%);
			}
			.triangle2 {
						
			    background: linear-gradient(to right bottom, #141f3c 50%, transparent 50%);
			}
		}
	}
}
.projects_big_thumb{
	@extend %fullBlock;
	margin-top: 8px;
	padding-bottom: 104px;
	.clearleft{
		.projects_item__content{
			padding-right: 50px;
		}
	}
	.pull-right{
		.projects_item__content{
			padding-left: 50px;
		}
	}
}
.projects_item{
	@extend %fullBlock;
	margin-top: 84px;
	&__content{
		float: left;
		width: 100%;
		min-height: 100px;
		position: relative;
		h3{
			font-weight: 700;
			font-size: 20px;
			color: #1A3347;
			letter-spacing: 0;
			line-height: 1.14em;
		}
		p{
			font-size: 16px;
			font-weght: 300;
			color: #162A39;
			letter-spacing: 0;
			line-height: 1.70em;
			margin-top: 5px;
			//padding-right: 40px;
		}
	}
}
.case_studies_wrapper{
	@extend %fullBlock;
	position: relative;
	background-color: #141f3c;
	padding: 60px 0 55px 0;
}

.case_studies_boxes{
	@extend %fullBlock;
	margin-top: 30px;
	.row{
		margin-left: -17px;
		margin-right: -17px;
	}
	.column-6{
		padding: 0 17px;
	}
}
.box{
	@extend %fullBlock;
	height: 340px;
	margin-top: 30px;
	position: relative;
	overflow: hidden;
	border-radius: 4px;
	&:hover{
		.box__content{
			bottom: 0;
			h3{
				margin-bottom: 0;
			}
		}
	}
	&__thumb{
		@extend %fullBlock;
		height: 100%;
		img{
			@extend %imgCropped;
			font-family: 'object-fit:cover;';
		}
	}
	&__content{
		@extend %fullBlock;
		position: absolute;
		left: 0;
		transition:$transition2;
		bottom: -94px;
		padding: 48px 40px;
		padding-right: 165px;
		height: auto;
		color: #fff;
		font-weight: 700;
		p{
			text-transform: uppercase;
			font-size: 12px;
		}
		h3{
			font-size: 24px;
			font-weight: 400;
			margin-top: 12px;
			line-height: 1.14em;
		    margin-bottom: 30px;
			transition:$transition2;
		}
		a{
			margin-top: 30px;
			width: 209px;
			float: left;
			padding: 14px 13px;
			border: 2px solid #0098ff;
			border-radius: 3px;
			text-decoration: none;
			background-image: url(../images/right-long-arrow.svg);
			background-size: 33px 13px;
			background-repeat: no-repeat;
			background-position: 93%;
			transition:$transition1;
			&:hover{
				background-position: 96%;
				background-color: #0098ff;
			}
			span{
				font-weight: 600;
				color:#fff;
			}
		}
	}
	.hover_second{
		h3{
			font-size: 22px;
			font-weight: bold;
		}
		a{
			border-color: #3FB29A;
			&:hover{
				background-color: #3FB29A;
			}
		}
	}
}
.btn_out{
	position: relative;
	width: 100%;
	float: left;
	text-align: center;
	margin-top: 70px;
	a{
		display: inline-block;
		margin: 0 auto;
		width: 276px;
		padding: 21px 19px;
		border: 2px solid #0098ff;
		border-radius: 3px;
		text-decoration: none;
		text-align: left;
		transition:$transition1;
		
		position: relative;
		background-position: 0 0, 0 100% ;
		background-repeat: no-repeat;
		-webkit-background-size: 101% 2px;
		-moz-background-size: 101% 2px;
		background-size: 101% 2px;
		
		z-index: 2;

		&:after{
			@extend %centered;
			float: left;
			z-index: -1;
			content: " ";
			width: 0;
			height: 100%;
			background: #0098ff;
			transition: all .35s cubic-bezier(0.860, 0.000, 0.070, 1.000);
		}
		&:hover{
			&:after{
				width: 100%;
			}
		}
		// &:hover{
		// 	background-position: 96%;
		// 	background-color: #3FB29A;
		// }

		span{
			color:#fff;
			float: left;
			width: 100%;
			font-size: 18px;
			font-weight: 500;
			background-image: url(../images/right-long-arrow.svg);
			background-size: 33px 13px;
			background-repeat: no-repeat;
			background-position: 100%;
		}
	}

}
.btn_out.black_title{
	a{
		border-color: #26415e;
		&:hover{
			background-color: #26415e!important;
			&:after{
				display: none;
			}
			span{
				color: #fff;
				background-image: url(../images/right-long-arrow.svg);
			}
		}
		span{
			color: #26415e;
			background-image: url(../images/right-long-arrow-blue.svg);
		}
	}
}
/*----------  REMOVE STYLE  ----------*/

.our_clients_section{
	@extend %fullBlock;
	position: relative;
	.base {
		top: 0;
	    height: 30px;
	    z-index: 9;
	}
	.triangle{
	    background: linear-gradient(to left bottom, #fff 50%,transparent 52%);
	}
	.triangle2 {
	    background: linear-gradient(to right bottom, #fff 50%,transparent 52%);
	}
	.home_banner_thumb{
		width: 100%;
		height: 100%;
		position: relative;
		// opacity: 0.79;
		img{
			@extend %imgCropped;
			font-family: 'object-fit:cover;';
			// -webkit-filter: grayscale(100%);
	  //   	filter: grayscale(100%);
		}
	}
	.overlay{
		background: rgba(26, 50, 68, 0.96);
	}
}
.our_clients_wrapper{
	@extend %fullBlock;
}
.our_clients_holder{
	@extend %fullBlock;
	position: absolute;
	left: 50%;
	top: 50%;
	transform:translate(-50%, -50%);
	text-align: center;
    margin-top: 40px;
	h1{
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: 600;
		font-style: italic;
		font-size: 36px;
		color: #FFFFFF;
		line-height: 1.25em;
	    max-width: 970px;
	    margin: 0 auto;
	}
	p{
		margin-top: 20px;
		font-weight: 300;
		font-size: 12px;
		color: #fff;
		letter-spacing: 0;
		line-height: 1.67em;
	}
	img{
		max-width: 535px;
		margin: 0 auto;
		margin-top: 45px;
	}
}

.our_clients_slider{
	@extend %fullBlock;
	position: relative;
	margin-bottom: 100px;
	.overlay{
		background-color: #161A1D;
		opacity: .42;
	}
	.slides{
		li{
			height: 700px;
			position: relative;
		}
	}
	.flex-direction-nav{
		display: none;
	}
	.flex-control-nav{
		bottom: -100px;
	    margin-top: -24px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: auto;
		li{
			width: 80px;
			margin-right: 40px;
		    margin-top: 44px;
		    &:last-child{
		    	margin-right: 0
		    }
			img{
				width: 80px;
				height: 80px;
				object-fit:cover;
				font-family: "object-fit:cover;";
				border-radius: 100%;
				background: #D8D8D8;
				position: relative;
				z-index: 3;
			}
			&.flex-active {
				position: relative;
				&:before{
					content: "";
					transition: $transition2;
					width: 36px;
					height: 36px;
					background-color: #fff;
					display: block;
					position: absolute;
					top: -26px;
					left: 50%;
					z-index: 2;
					transform: translateX(-50%) rotate(45deg);
				}
			}
		}
	} 
}
.our_projects{
	@extend %fullBlock;
	margin-bottom: 60px;
	ul{
		@extend %fullBlock;
		&:first-child{
			margin-top: 38px;
		}
	}
	li{
		float: left;
		width: 25%;
		text-align: center;
		margin-top: 35px;
		min-height: 63px;
		display: flex;
		justify-content:center;
		align-items:center;
	    height: 88px;
		&:nth-child(4n+1){
			clear: left;
		}
	}
	.btn_out{
		margin-top: 50px;
	}
}

.client_box{
	@extend %fullBlock;
	&__thumb{
		width: 174px;
		height: 174px;
		float: left;
		margin-right: 60px;
		position: relative;
		span{
			display: block;
			background: url(../images/right-quote.svg) no-repeat;
			width: 80px;
			height: 65px;
			position: absolute;
			background-size: 80px 65px;
			top: 0;
			right: -26px;
		}
		img{
			border: 2px solid transparent;
			border-radius: 100%;
			@extend %imgCropped;
			font-family: 'object-fit:cover;'
		}
	}
	&__content{
		float: left;
		width: calc(100% - (174px + 60px));
		margin-top: 25px;
		h2{
			font-family: 'Source Sans Pro', sans-serif;
			font-size: 27px;
			line-height: 1.15em;
			font-weight: 600;
			font-style: italic;
		}
		p{
			font-size: 12px;
			margin-top: 12px;
			font-weight: 300;
			margin-left: 3px;
		}
	}
	a{
		text-decoration: none;
		color: #fff;
	}
}

/*----------  END REMOVE STYLE  ----------*/

.home_insight_section{
	@extend %fullBlock;
	background: #F3F3F3;
	padding-top: 50px;
	padding-bottom: 50px;
	.row.has_gutter{
		margin-right: -17.5px;
		margin-left: -17.5px;
	}
	.column-4{
		padding: 0 17.5px;
	}
}
.home_insight_wrapper{
	@extend %fullBlock;	
	.btn_out{
		margin-top: 60px;
	}
}
.insight_box{
	@extend %fullBlock;	
	background: #FFFFFF;
	// border: 1px solid #EBEBEB;
	border-radius: 6px;
	margin-top: 60px;
	min-height: 320px;
	position: relative;
	&__thumb{
		@extend %fullBlock;	
		height: 230px;
		img{
			@extend %imgCropped;
			border-top-right-radius: 6px;
			border-top-left-radius: 6px;
			font-family: "object-fit:cover;";
		}
	}
	&__content{
		@extend %fullBlock;	
		padding: 40px 38px;
		span{
			font-size: 12px;
			color: #1A3347;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 10px;
			float: left;
			width: 100%;
		}
		h3{
			font-weight: bold;
			font-size: 22px;
			color: #1A3347;
			letter-spacing: 0;
			line-height: 1.14em;
		}
		p{
			font-weight: 400;
			font-size: 18px;
			color: #838484;
			letter-spacing: 0;
			line-height: 1.72em;
			// padding-right: 80px;
			margin-top: 11px;
		}
	}
	&__author{
		@extend %fullBlock;
		position: absolute;
		bottom: 20px;
		&__thumb{
			width: 50px;
			height: 50px;
			float: left;
			margin-right: 11px;
		}
		&__content{
			float: left;
			width: calc(100% - 101px);
			h4{
				font-weight: 500;
				font-size: 14px;
				color: #1A3347;
				letter-spacing: 0;
				line-height: 1.72em;
			}
			span{
				font-weight: 500;
				font-size: 14px;
				color: #838484;
				letter-spacing: 0;
				line-height: 1.72em;
			}
		}
	}
}
.analytics-platform{
	height: 755px;
	width: 100%;
    float: left;
    .visual{
    	height: 100%;
    }
}
html body .analytics-platform .visual{
	top: -30px!important;
}
.analytics-platform {
    zoom: 63%;
	z-index: 9;
    margin-bottom: -20px;
}
html.no-object-fit{
	.wrapper{
		left: 50%;
		transform:translateX(-50%)
	}
	.animations-block {
		.static-line2{
			top: 210px;
		}
		.line-1{
			bottom: 240px;
		}
		.static-line3{
			top: 150px;
		}
	}
}
.imageJS_section{
	background: #fff;
	filter: unset;
	height: auto;
	padding: 97px 0;
	.no_border{
		width: 673px;
		margin: 0 auto;
		display: block;
		float: none;
		@include mobile {
			width: 100%;
		}
		h1{
			font-size: 36px;
			font-weight: 700;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.19;
			letter-spacing: normal;
			color: #26415e;
			text-align: left;
			display: block;
		}
		p{
			margin-top: 10px;
		    max-width: 100%;
		    font-size: 18px;
			font-weight: 400;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.69;
			letter-spacing: normal;
			text-align: left;
			color: #141f3c;
		}
	}
}
.colored_bg{
	background-color: #f3f3f3;
}
.our_process_big_thumb{
	@extend %fullBlock;
	padding: 93px 0 144px;
	.no_border{
		width: 930px;
		margin: 0 auto;
		display: block;
		float: none;
		@include tablet{
			width: 100%;
		}
		h1{
			font-size: 36px;
			font-weight: 700;
			line-height: 1.19em;
			color: #26415e;
		}
		p{
			margin-top: 20px; 
		    max-width: 100%;
		    font-size: 18px;
			font-weight: 400;
			text-align: center;
			line-height: 1.69em;
			color: #141f3c;
		}
	}
}
.list_process{
	margin-top: 50px;
	display: flex;
	justify-content:space-between;
	padding: 0;
	li{
		width: auto;
		text-align: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		&:after{
			content: "";
			background: url(../images/arrow-proces.png) no-repeat;
			display: block;
			background-size: contain;
			width: 56px;
			height: 116px;
			position: absolute;
		}
		&:nth-child(1){
			&:after{
				right: -133px;
				top: 0px;
			}
		}
		&:nth-child(2){
			&:after{
				right: -113px;
				top: -36px;
			}
		}
		&:nth-child(3){
			&:after{
				transform: rotate(180deg);
				left: -113px;
				top: 36px;
			}
		}
	}
	.item_thumb{
		margin-left: 0;
	}
	.item_content{
		h3{
			font-size: 23px;
			font-weight: bold;
			line-height: 1.79em;
			text-align: left;
			color: #141f3c;
			&:before{
				content: "";
				width: 33px;
				height: 33px;
				float: left;
				margin-right: 5px;
				background: url(../images/icon-logo.svg) no-repeat;
				background-size: cover;
			    margin-top: -12px;
			}
		}
		h2{
			font-size: 18px;
			font-weight: 300;
			line-height: 1.69em;
			text-align: left;
			color: #141f3c;
			margin-left: 38px;
			text-transform: none;
		}
	}
	.list_services__item{
		margin-top: 60px;
	}
}
.ceo_wrapper{
	@extend %fullBlock;
	padding:135px 0 114px;
}
.ceo_holder{
	width: 850px;
	margin: 0 auto;
	display: block;
}
.ceo_thumb{
	width: 130px;
	height: 130px;
	float: left;
	margin-right: 40px;
	img{
		@extend %imgCropped;
		font-family: 'object-fit:cover;';
		border-radius: 100%;
		border: 1px solid transparent;
	}
}
.ceo_content{
	float: left;
	width: calc( 100% - 170px);
	color: #0C0C0C;
	p{
		font-family: 'Source Sans Pro', sans-serif;
		font-size: 28px;
		line-height: 1.10em;
    	margin-top: 36px;
		font-style: italic;
		&:first-of-type{
			margin-top: 0;
		}
	}
	h4{
		margin-top: 50px;
		font-size: 12px;
		font-weight: 300;
		float: left;
		color: #000;
		line-height: 1.2em;
		b{
			font-weight: bold;
		}
	}
	img{
		float: left;
	}
}
.overlay_special{
    height: 100vh;
    h1{
    	margin-top: 0;
    }
	.home_banner_thumb{
		height: 100%;
	}
}
.about_overlay_special{
	.home_banner_thumb{
		img{
	    	opacity: .8;
		}
	}
	.overlay{
		background-image: unset;
	}
}
.base_special{
	top: -60px;
	height: 60px;
	.triangle{
	    background: linear-gradient(to left bottom,rgba(26,50,68,0) 50%, #fff 53%);
	}
	.triangle2{
	    background: linear-gradient(to right bottom,rgba(26,50,68,0) 50%, #fff 53%);

	}
}
.gecko,
.no-object-fit{
	.base_special{
		.triangle{
		    background: linear-gradient(to left bottom, rgba(26, 50, 68, 0) 50%, #fff 50%);
		}
		.triangle2 {
					
		    background: linear-gradient(to right bottom, rgba(26, 50, 68, 0) 50%, #fff 50%);
		}
	}
}
.home_bottom_banner_section{
	@extend %fullBlock;
	height: 550px;
	.home_banner_thumb{
		@extend %fullBlock;
		height: 100%;
		img{
			@extend %imgCropped;
			font-family: 'object-fit:cover;';
		}
	}
}
.home_bottom_banner_content{
	margin: 52px auto;
	width: 798px;
	padding: 45px 76px;
	height: 445px;
	background-image: linear-gradient(59deg, #43B9EA 0%, #47B9DD 2%, #4BBAC4 16%, #4DBAAF 36%, #4DBA9F 51%, #4DBB94 67%, #4DBC8D 83%, #4DBC8B 100%); 
    position: absolute;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%)!important;
    text-align: left;
	color: #fff;
    h1{
    	font-size: 36px;
    	font-weight: 200;
		line-height: 1.4em;
    	b{
    		font-weight: bold;	
    	}
    	&:after {
		    content: "";
		    display: block;
		    width: 62px;
		    height: 3px;
		    background-color: #fff;
		    margin-top: 30px;
		}
    }
    p{
    	margin-top: 29px;
    	font-size: 14px;
    	line-height: 1.72em;
    }
}
.about_partners_section{
	height: 610px;
	padding: 46px  0 55px 0;
	max-width: 815px; 
	margin: 0 auto;
	.black_text{
		h1{
			color: #000;
			line-height: 1.1em;
			width: 815px; 
			display: block;
		    padding: 0 100px;
			margin: 0 auto;
		    font-weight: bold;
		    &:after{
		    	display: table;
		    	margin: 0 auto;
		    	margin-top: 30px;
		    }
		}
		p{
			color: #000;
			font-weight: 600;
			max-width: 100%;
		}
	}
	a{
		font-size: 14px;
		font-weight: 500;
		color: #1391f6;
		letter-spacing: 0;
		position: relative;
		text-decoration: none;
		margin-top: 37px;
		display: inline-block;
		&:before{
			position: absolute;
			left: 0;
			bottom: -1px;
			float: left;
			content: " ";
			width: 0;
			height: 1px;
			background-color: #1391f6;
			transition: all .35s cubic-bezier(.86,0,.07,1);
		}
		&:after {
		    content: "";
		    color: #1391f6;
		    background: url(../images/arrow_right_blue.svg) no-repeat;
		    float: right;
		    background-size: 8px;
		    margin-top: 1px;
		    width: 12px;
		    height: 16px;
		    margin-left: 12px;
		}
		&:hover{
			&:before{
				width: calc(100% - 23px);
			}
		}
	}
	.partners_icon{
		background-image: linear-gradient(-180deg, #21CE95 0%, #10C5CD 98%);
		width: 145px;
		height: 145px;
		margin: 0 auto;
		border-radius: 100%;
		margin-bottom: 45px;
	    text-align: center;
	    img{
	    	margin-top: 58px;
	    }
	}
}
.footer_base_special{
	height: 15px;
	top: 0px;
	z-index: 2;
	.triangle{
	    background: linear-gradient(to left top, transparent 50%, #fff  52%);
	}
	.triangle2{
	    background: linear-gradient(to right top, transparent 50%,#fff 52%);
	}
}
.gecko,
.no-object-fit{
	.footer_base_special{
		.triangle{
		    background: linear-gradient(to left top, transparent 50%,#fff 50%);
		}
		.triangle2 {
		    background: linear-gradient(to right top, transparent 50%,#fff 50%);
		}
	}
}

.footer_base_special2{
	height: 15px;
	top: -15px;
	.triangle{
	    background: linear-gradient(to left top, transparent 50%,#48b9ae 52%);
	}
	.triangle2{
	    background: linear-gradient(to right top, transparent 50%,#49bb91  52%);
	}
}
.gecko,
.no-object-fit{
	.footer_base_special2{
		.triangle{
		    background: linear-gradient(to left top, rgba(26,50,68,.96) 50%,#48b9ae 50%);
		}
		.triangle2 {
		    background: linear-gradient(to right top, rgba(26,50,68,.96) 50%,#49bb91  50%);
		}
	}
}
.left_contact{
	float: left;
	width: 40%;
	padding-left: 45px;
	margin-top: 60px;
	.section_title{
		text-align: left;
	}
	h1{
		line-height: 1.2em;
		display: block;
	}
	ul{
		li{
			font-size: 14px;
			color: #FFFFFF;
			line-height: 1.25em;
			margin-top: 30px;
			position: relative;	
			padding-left:30px; 
			&:before{
				content: "";
				width: 10px;
				height: 10px;
				border-radius: 100%;
				position: absolute;	
				display: block;
				left: 0;
				top: 5px;
				background: #fff;
			}
		}
	}
}
.right_contact{
	float: right;
	width: 640px;
	height: 475px;
	border: 2px solid rgba(255,255,255,0.17);
	padding: 52px 59px;
	position: relative;
	color: #FFF;
	.row {
	    margin-left: -25px;
	    margin-right: -25px;
	}
	.has_gutter [class*=column-] {
	    padding: 0 25px;
	}
	label{
		width: 100%;
		float: left;
		font-weight: 900;
		font-size: 12px;
		letter-spacing: 0.1px;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
	textarea,
	input{
		background-color: transparent;
		border: 0;
		outline: 0;
		width: 100%;
		max-width: 100%;
		padding: 0;
		height: 28px;
		float: left;
		margin-bottom: 40px;
		border-bottom: 1px solid #fff; 
		padding-bottom: 8px;
		font-weight: 200;
		font-size: 16px;
		color: #EFEFEF;
		letter-spacing: 0.07px;
		@include placeholder {
			font-weight: 200;
			font-size: 16px;
			color: #EFEFEF;
			letter-spacing: 0.07px;
		}
	}
	textarea{
		margin-bottom: 0;
		max-height: 80px;
		&::-webkit-scrollbar { 
		    display: none; 
		}
	}
	input[type=submit]{
		position: absolute;
		bottom: 52px;
		left: 59px;
		width: 276px;
		cursor: pointer;
		border: 2px solid #fff;
		border-radius: 3px;
		margin-bottom: 0;
		padding: 0;
		font-weight: 500;
		font-size: 18px;
		height: 61px;
		transition: $transition2;
		&:focus,
		&:hover{
			background-color: #fff;
			color: #49bb8d;
		}
	}
}
.contact_section{
	height: 760px;
	padding: 140px 0 78px;
}
.fixOverflow{
	overflow: hidden;
	max-width: 100%;
}
.showmain {
    -webkit-animation: fadeInDown 1.5s;
}
.title_max_width{
    max-width: 813px;
    margin: 0 auto;
    float: none;
}
/*===========================================
=            Case Studies Single            =
===========================================*/

.is-fixed{
	position: fixed;
	max-width: 100%;
	overflow: hidden;
	width: 100%;
}
.paged_after{
	position: relative;
	top: 100vh;
	@extend %fullBlock;
	max-width: 100%;
	overflow: hidden;
	width: 100%;
}
.footer_has_space{
	top: 100vh;
	background-color: #fff;
}
.full_height_wrapper{
	@extend %fullBlock;
	height: 100vh ;
	position: relative;
	background-size: 100%;
	background-size: cover;
    background-position: center;
}
.white_bg{
	background-color: #fff;
}
.banner_content{
	text-align: left;
	h1 {
		font-weight: 800;	
		font-size: 48px;
		color: #FFFFFF;
		letter-spacing: -3px;
		width: 965px;
		line-height: 1.32em;
		&:after{
			margin-top: 32px;
			position: absolute;
			left: -112px;
			top: 0;
			width: 80px;
		}
	}
	p{
		font-size: 18px;
		font-weight: 300;
		line-height: 1.58em;
	}
}
.darken_color{
	color: #161616;
}

.has_left_space{
	padding-left: 70px;
	padding-right: 30px;
	h1{
		font-weight: 800;
	}
}
/*=====  End of Case Studies Single  ======*/

.lighter_title{
	h1{	
		font-size: 36px;
		font-weight: 300;
	}
	a{
		text-decoration: none;
		border-bottom: 2px solid #26415e
	}
	p{
		font-size: 20px;
		font-weight: 400;
		@include mobile{
			font-size: 18px;
		}
	}
}

/*=============================
=            Contact            =
==============================*/

.contact_wrapper.is--active{
	bottom: 0;
}
.contact_wrapper{
	width: 100%;
	bottom: -100vh;
	position: fixed;
	height: 100vh;
	padding-top: 75px; 
	overflow-y: scroll; 
	transition: all .35s cubic-bezier(0.860, 0.000, 0.070, 1.000);
	background: url(../images/map-bg.png) no-repeat bottom right #fff !important;
	&::-webkit-scrollbar { 
	    display: none; 
	}
	.close_btn{
		width: 67px;
		height: 67px;
		position: absolute;
		top: 42px;
		right: 52px;
		background-color: #F2F2F2;
		border-radius: 100%;
		cursor: pointer;
		span{
			width: 37px;
			height: 2px;
			background-color: #404040;
			display: block;
			position: absolute;
			left: 45%;
			top: 50%;
			&:nth-child(1){
				transform: rotate(-45deg) translateX(-10px) translateY(-10px);
			}
			&:nth-child(2){
				transform: rotate(45deg) translateX(-10px) translateY(10px);
			}
		}
	}
}
.contact_title{
	@extend %fullBlock;
	padding-bottom: 35px;
	border-bottom: 1px solid #DFDFDF;
	h4{
		font-weight: 300;
		font-size: 24px;
		color: #26415e;
		letter-spacing: 0;
		line-height: 1.29em;
	}
	h2{
		font-size: 48px;
		color: #26415e;
		letter-spacing: 0;
		line-height: 1em;
		margin-top: 10px;
	}
}
.contact_form{
	width: calc(100% - 3px);
	float: left;
	textarea,
	select,
	input{
		width: 100%;
		height: 50px;
		padding: 15px 13px; 
		float: left;
		outline: 0;
		margin-top: 20px; 
		background: #FFFFFF;
		border: 1px solid #EEEEEE;
		border-radius: 3px;
		font-size: 18px;
		font-family: 'OpenSans', sans-serif;
		color: #8E8E8E;
		letter-spacing: 0;
		@include placeholder {
			font-size: 18px;
			font-family: 'OpenSans', sans-serif;
			color: #8E8E8E;
			letter-spacing: 0;
		}
	}
	select{
		background: url(../images/bottom-arrow-select.png) no-repeat 96% #fff;
		/*for firefox*/
		-moz-appearance: none;
		/*for chrome*/
		-webkit-appearance:none;
		&::-ms-expand {
		    display: none;
		}
	}
	input[type="submit"]{
		float: right;
		width: 150px;
		border-radius: 3px;
		background: url(../images/right-long-arrow.svg) no-repeat #0098ff 102px center;
		font-family: 'Montserrat', sans-serif;
		cursor: pointer;
		text-align: left;
		font-size: 14px;
		font-weight: 500;
		color: #FFFFFF;
		letter-spacing: 0;
		line-height: 14px;
		transition: $transition1;
		margin-right: 15px;
		&:hover{
			background-position-x: 92%; 
		}
	}
	textarea{
		resize:none;
		height: 160px;
		overflow-x: hidden;
	}
}
.contact_small_title{
	font-weight: bold;
	font-size: 12px;
	margin-top: 65px;
	margin-bottom: 10px;
	color: #26415e;
	letter-spacing: 0;
}
.contact_info{
	margin-left: 123px;
	h3{
		font-size: 18px;
		color: #26415e;
		letter-spacing: 0;
		line-height: 1.25em;
		font-family: 'OpenSans', sans-serif;
		position: relative;
		&:before{
			content: "";
			position: absolute;
			left: -30px;
			top: 2px;
			width: 15px;
			height: 20px;
			background: url(../images/location.svg) no-repeat; 
		}
	}
	ul{
		margin-top: 18px;
	}
	li{
		font-weight: bold;
		font-size: 14px;
		color: #0098ff;
		letter-spacing: 0;
		line-height: 25px;
		font-family: 'OpenSans', sans-serif;
		padding-left: 40px;
		position: relative;
		&:before{
			content: "";
			width: 30px;
			height: 1px;
			position: absolute;
		    left: 0;
		    top: 13px;
			background-color: #0098ff;
		}
	}
}
.contact_copyright{
	position: static;
	width: 100%;
    margin-top: 60px;
	margin-bottom: 40px;
	p{
		font-family: 'Rubik', sans-serif;
		font-size: 14px;
		color: #919191;
		letter-spacing: 0;
	}
}

/*====  End of Contact  ====*/



/*=============================
=         Case Studies        =
==============================*/

.case_studies_info{
	@extend %fullBlock;
	margin-top: 59px;
	margin-bottom: 40px; 
	&_thumb{
		text-align: center;
	}
}
.case_studies_article{
	@extend %fullBlock;
	height: 600px;
	position: relative;
	margin-top: 2px;
	overflow: hidden;
	&:hover{
		.cs_article_thumb{
		    background-color: transparent;
		    transform: scale(1.1);
		    &:after{
		    	opacity: 0;
		    }
		}
	}
	.cs_article_thumb{
		background-size: cover;
		height: 100%;
		transition: all 6s ease-out;
	    background-position: center;
	    position: relative;
	    &:after{
	    	content: "";
		    background-color: rgba(20,31,60,.5);
		    width: 100%;
	    	height: 100%;
	    	position: absolute;
	    	transition: all 6s ease-out;
	    }
	}
	&_holder{
		position: absolute;
		width: 100%;
		float: left;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	&_content{
		max-width: 1000px;
		h4{
			// font-size: 12px;
			// color: #FFFFFF;
			// font-weight: bold;
			// letter-spacing: 0;
			// text-transform: uppercase;
			text-transform: none;
			font-size: 24px;
			font-weight: 300;
			line-height: 1.25em;
			color: #ffffff;
			@include mobile{
				margin-left: 0
			}
		}
		h1{
			font-size: 36px;
			color: #FFFFFF;
			letter-spacing: 0;
			margin-top: 12px;
			line-height: 1.33em;
			font-weight: 700;
		}
		a{
			margin-top: 45px;
			width: 280px;
			float: left;
			padding: 20px 18px;
			border: 1px solid #fff;
			border-radius: 3px;
			text-decoration: none;
			background-image: url(../images/right-long-arrow.svg);
			background-size: 33px 13px;
			background-repeat: no-repeat;
			background-position: 93%;
			transition:$transition1;
			&:hover{
				background-position: 96%;
				background-color: #0098ff;
				border-color: #0098ff;
			}
			span{
				font-weight: 600;
				font-size: 18px;
				color:#fff;
			}
		}
	}
}
.case-studies-banner{
	.page_title {
		p{
			margin-top: 15px;
		}
	}
}
.case_studies_partners_section {
	.black_text {
		h1{
			font-size: 30px;
			line-height: 1.34em;
		}
		p{
			font-weight: 500;
			margin-top: 30px;
		}
	}
	.partners_icon{
		margin-bottom: 30px;
	}
}
/*====  End of Case Studies  ====*/

.mt80{
	margin-top: 80px;
}
.mt71{
	margin-top: 71px;
}
.case-studies-footer {
    margin-top: 2px;
}
.gfield{
	padding: 0 15px;
	float: left;
	width: 50%;
}
#field_1_1{
	input,
	select{
		margin-top: 0;
	}
}
#field_1_10,
#field_1_11,
#field_1_1{
	width: 100%;
}
.gfield_label{
	display: none;
}
.color_black{
	h1{
		color: #000;
	}
	p{
		color: #767676;
	}
}

.gfield_description.validation_message{
	float: right;
    color: red;
    margin-top: 5px;
}
.validation_error{
	padding: 0 15px;
	color: red;
	margin-bottom: 10px;
}
.gform_confirmation_wrapper{
	padding: 0 15px;
    color: #3fb29a;
} 
#gform_ajax_spinner_1{
	float: left;
	margin-left: 15px;
	margin-top: 20px;
}
.loadmore{
	span{
		text-align: center;
		background: none!important;
	}
}


.insight_cat{
	padding-top: 60px;
	margin-top: 0;
	padding-bottom: 80px;
	background: #efefef;
	.insight_box{
		margin-top: 32px;
	}
}
.blog_slider_wrapper{
	@extend %fullBlock;
	position: relative;
}
.blog_slider_wrapper{
	@extend %fullBlock;
	position: relative;
	height: 638px;
	.overlay{
		background: rgba(0,0,0,0.80);
		z-index: 2;
	}
	.our_clients_holder{
		z-index: 4;
		h1{
			font-family: Montserrat,sans-serif;
			font-weight: bold;
			font-style: normal;
			text-transform: uppercase;
			font-size: 36px;
			color: #fff;
			line-height: 1.22em;
			max-width: 910px;
			margin: 0 auto;
		}
		a{
			width: 140px;
			height: 44px;
			display: inline-block;
			margin-top: 46px;
			border: 2px solid #fff;
			padding: 13px 11px  0 15px;
			text-decoration: none;
			color: #fff;
			border-radius: 3px;
			font-weight: 500;
			transition: $transition2;
			&:hover{
				padding-right: 7px;
			}
			span{
				width: 100%;
				float: left;
				font-size: 14px;
				background: url(../images/right-long-arrow.svg) no-repeat right center;
				text-align: left;
			}
		}
	}
	.home_banner_thumb{
		position: relative;
		z-index: 1;
		height: 100%;
		img{
			@extend %imgCropped;
			font-family: 'object-fit:cover;';
		}
	}
	.slides{
		li{
			height: 638px;
			position: relative;
		}
	}
	.flex-direction-nav{
		display: none;
	}
	.flex-control-paging {
		li{ 
			margin-right: 13px;
			&:last-child{
				margin-right: 0;
			}
			a{
				width: 9px;
				height: 9px;			
				background-color: #fff;	
			}
			a.flex-active{
				background-color: #3FB29A; 
			}
		}
	}
	.flex-control-nav{
		bottom: 41px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: auto;
	} 
}
.single_banner{
	.overlay{
		background: rgba(0,0,0,0.40);
	}
	.our_clients_holder{
		margin-top: -20px;
		span{
			font-size: 14px;
			color: #FFFFFF;
			letter-spacing: 0;
			line-height: 1.2em;
			margin-bottom: 10px;
			display: block;
		}
	}
	.insight_box__author{
		left: 50%;
		transform: translateX(-50%);
		width: 150px;
		bottom: 74px;
		text-align: center;
		z-index: 4;
	}
	.insight_box__author__thumb{
		float: none;
		margin: 0 auto;
		display: block;
		img{
			border-radius: 100%;
			@extend %imgCropped;
			font-family: 'object-fit:cover;';
		}
	}
	.insight_box__author__content{
		width: 150px;
		margin-top: 10px;
		span,
		h4{
			color: #fff;
			font-weight: 400;
		}
	}
}
.insight_box__author__thumb{
	img{
		border-radius: 100%;
		@extend %imgCropped;
		font-family: 'object-fit:cover;';
	}
}
.article_wrapper{
	@extend %fullBlock;
	padding: 80px 0 40px 0;
	font-size: 18px;
	font-weight: 300;
	color: #1A3347;
	letter-spacing: 0;
	line-height: 1.7em;
	font-family: Montserrat,sans-serif;
	p{
		margin-bottom: 40px;
	    letter-spacing: -0.2px;
	}
	blockquote{
		margin: 20px 0 20px;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6{
		font-weight: bold;
		margin-top: 40px;
	}

	figcaption{
		font-size: 14px;
		font-weight: 400;
		color: #1A3347;
		line-height: 1.32em;
		margin-bottom: 40px;
	}
	ul{
		list-style: none;
		margin-top: 20px;
		li{
			font-weight: 500;
    		font-size: 14px;
			color: #1A3347;
			line-height: 1.32em;
			margin-top: 10px;
			display: flex;
			&:before{
				content:" - ";
				padding-right: 10px;
			}
		}
	}
	blockquote {
		position: relative;
		display: inline-block;
		&:after{
			content: "";
			width: 2px;
			height: 100%;
			background-color: #3FB29A;
			position: absolute;
			left: -20px;
			top: 50%;
			transform: translateY(-50%);
		}
        p {
        	font-weight: 500;
            display: flex;
    		max-width: 80%;
    		margin: 0;
        }
        span{
			float: left;
			width: 100%;
			clear: both;
			margin-top: 5px;
        }
    }
    pre{
    	margin: 0;
    	padding: 35px;
    }
    code{
    	background: #eee;
	    line-height: 1.6;
	    margin-bottom: 1.6em;
	    max-width: 100%;
	    overflow: auto;
	    padding: 1.6em;
		width: 100%;
		float: left;
    }
    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 28px;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 18px;
		margin-bottom: 6px;
    }
    h5 {
        font-size: 16px;
    }
    h6 {
        font-size: 14px;
    }
    ol {
        list-style: decimal;
        padding-left: 20px;
		margin-top: 40px;
        li {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 500;
			color: #1A3347;
        }
    }
    img {
        width: auto;
        text-align: center;
        height: auto;
    }
    iframe {
        width: 100%;
        text-align: center;
        margin-bottom: 40px!important;
    }
    .fb_iframe_widget{
    	float: left;
    	width: 100%;
    }
    a{
    	font-weight: 500;
    	color: #3CB2B8; 
    }
}
.article_container{
	margin: 0 auto;
	display: block;
	max-width: 700px; 
	width: 100%;
	font-size: 18px;
}
.single_go_btn{
	bottom: 34px;
	width: 16px;
	height: 16px;
	background-size: cover;
	&:hover{
		bottom: 30px;
	}
}
.footer_base_special_colored{
	height: 15px;
	top: 0px;
	.triangle{
	    background: linear-gradient(to left top, rgba(26,50,68,.96) 50%,#fbfbfb 52%);
	}
	.triangle2{
	    background: linear-gradient(to right top, rgba(26,50,68,.96) 50%,#fbfbfb  52%);
	}
}
.gecko,
.no-object-fit{
	.footer_base_special_colored{
		.triangle{
		    background: linear-gradient(to left top, rgba(26,50,68,.96) 50%,#fbfbfb 50%);
		}
		.triangle2 {
		    background: linear-gradient(to right top, rgba(26,50,68,.96) 50%,#fbfbfb  50%);
		}
	}
}
.footer_base_special_colored2{
	height: 15px;
	top: 0px;
	.triangle{
	    background: linear-gradient(to left top, rgba(26,50,68,.96) 50%, #efefef  52%);
	}
	.triangle2{
	    background: linear-gradient(to right top, rgba(26,50,68,.96) 50%, #efefef   52%);
	}
}
.gecko,
.no-object-fit{
	.footer_base_special_colored2{
		.triangle{
		    background: linear-gradient(to left top, rgba(26,50,68,.96) 50%, #efefef  50%);
		}
		.triangle2 {
		    background: linear-gradient(to right top, rgba(26,50,68,.96) 50%, #efefef   50%);
		}
	}
}
#infscr-loading{
	display: none!important;
}
.box_hovered{
	.insight_box{
		a{
			float: left;
			width: 100%;
			height: 100%;
		    border-radius: 6px;
			transition: $transition1;
			&:hover{
				border-color: transparent;
			    background: #fff;
			    box-shadow: 0 17px 34px 0 rgba(0,0,0,.1);
			}
		}
	}
}
.clearleft{
	clear: left;
}
.in_container{
	float: none;
	margin: 0 auto;
}
.home_projects_item{
    padding: 34px 50px 28px;
	.insight_box__content{
		padding: 0
	}
    .insight_box__thumb{
    	height: auto;
    	img{
			border-top-right-radius: 0;
			border-top-left-radius: 0;
    	}
    }
    h3{
		font-size: 14px;
	    font-weight: 500;
	    font-style: normal;
	    font-stretch: normal;
	    line-height: 1.5em;
	    letter-spacing: normal;
	    text-align: center;
	    color: #26415e;
    	margin-top: 26px;
    }
}
.author_in_post{
	position: static;
	margin-top: 26px;
	.insight_box__author__thumb{
		float: none;
	    display: block;
	    margin: 0 auto;
	    width: 61px;
	    height: 61px;
	}
	.insight_box__author__content{
		width: 100%;
		p{
			font-size: 14px;
			line-height: 1.07em;
			font-weight: 300;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.25em;
			letter-spacing: normal;
			text-align: center;
			color: #141f3c;
			margin-top: 8px;
		}
		span{
			font-size: 12px;
			font-weight: 300;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.25em;
			letter-spacing: normal;
			text-align: center;
			color: #141f3c;
			text-transform: none;
			margin-bottom: 0;
		}
	}
}
.new_banner{
	height: 520px;
	.overlay{
		background-color: rgba(20,31,60,0.60)
	}
}
@include desktop {
	.flex-right{
		.abous_us_item{
			display: flex;
			justify-content: flex-end;
		}
	}
}
@include tablet {
	.flex-right{
		.abous_us_item{
			display: flex;
			justify-content: flex-end;
		}
	}
}
.core_values{
	padding: 78px 0 83px;
}
.abous_us_item{
	@extend %fullBlock;
	margin-top: 64px;
	.item_thumb{
		width: 43px;
		margin-right: 22px;
		float: left;
	}
	.item_content{
		width: calc(100% - 144px);
		float: left;
		h3{
			font-size: 22px;
			font-weight: 700;
			line-height: 1.32em;
			text-align: left;
			color: #2b2b2b;
		}
		p{
			font-size: 18px;
			font-weight: 400;
			line-height: 1.79em;
			text-align: left;
			color: #2b2b2b;
		}
	}
}
.mb20{
	margin-bottom: 20px!important;
}
.middle_column{
	@extend %centered;
	margin-top: -20px;
	@include mobile {
		display: none;
	}
}
.case_studies_wrapper_new{
	padding: 70px 0 30px 0;
	background-color: #f3f3f3;
	.case_studies_boxes{
		margin-top: 0px;
	}
}
.services_list{
	@extend %fullBlock;
	ul{
		@extend %fullBlock;
		margin-top: 48px;
		margin-bottom: 53px;
		@include tablet {
			padding-left: 0;
	    }
		@include mobile {
			padding-left: 0;
			margin-top: 20px;
		}
	}
	li{
		@extend %fullBlock;
	    padding: 38px 0;
		border-bottom: 1px solid  #D8D8D8;
		display: flex;
		align-items:center;
		flex-direction: row;
		justify-content: space-between;
		@include mobile {
			height: auto;
			flex-direction: column-reverse;
			align-items:left;
		}
		&:last-child{
			border-bottom: 0;
		}
	}
	.service_content{
		max-width: 641px;
		@include mobile {
			max-width: 100%;
		}
		height: 100%;	
		display: flex;
	    justify-content: space-between;
		flex-direction: column;
	}
}
.service_content{
	width: 100%;
	float: left;
	@include mobile {
		margin-top: 15px;
	}
	h1{
		font-size: 22px;
		font-weight: 700;
		line-height: 1.32em;
		text-align: left;
		color: #26415e;
	}
	p{
		font-size: 18px;
		font-weight: 300;
		line-height: 1.79em;
		text-align: left;
		color: #2b2b2b;
		margin-top: 13px;
		@include mobile {
			font-size: 18px;
			padding-right: 15px;
		}
	}
	a{
		width: 105px;
		font-size: 14px;
		font-weight: 500;
		color: #1391f6;
		letter-spacing: 0;
		position: relative;
		text-decoration: none;
		display: inline-block;
		margin-top: 18px;
		@include mobile {
			margin-top: 15px;
		}
		&:before{
			position: absolute;
			left: 0;
			bottom: -1px;
			float: left;
			content: " ";
			width: 0;
			height: 1px;
			background-color: #1391f6;
			transition: all .35s cubic-bezier(.86,0,.07,1);
		}
		&:after {
		    content: "";
		    color: #1391f6;
		    background: url(../images/blue_right_icon.svg) no-repeat;
		    float: right;
		    background-size: 8px;
		    margin-top: 1px;
		    width: 12px;
		    height: 16px;
		    margin-left: 12px;
		}
		&:hover{
			&:before{
				width: calc(100% - 23px);
			}
		}
	}
}
.page-template-page-services{
	.service_thumb{
		float: right;
		img{
			max-width: 80%;
			@include mobile{
				max-width: 50%;
			}
		}
	}
}
.medium_banner{
	height: 720px;
	@include mobile{
		height: auto;
	}
	.new_home_banner_thumb{
		position: absolute;
		&:after{
			content: "";
			background-color: #fff;
			height: 44px;
			width: 50%;
			position: absolute;
			right: 0;
			bottom: 0;
			@include mobile {
				display: none;
			}
		}
		.overlay{
			width: 50%;
			background: rgba(20,31,60,0.90);
			@include mobile {
				width: 100%;
			}
		}
	}
}
.half_banner{
	width: 100%;
	text-align: left;
	top: 200px;
    transform: translateX(-50%);
    @include mobile {
		top: 0;
    	transform: none;
    	position: relative;
    	left: 0;
    	padding: 0!important;
    }
    @include tablet {
		left: 15px;
		transform: none;
		width: 90%;
		top: 150px;
    }
	.new_banner_content{
		width: 500px;
		float: left;
    	@include mobile {
    		width: 100%;
		    margin-top: 140px;
		    margin-bottom: 40px;
    	}
    	@include tablet {
    		width: 100%;
    	}
	}
}
.new_banner_content{
	h4{
		font-size: 24px;
		font-weight: 300;
		line-height: 1.25em;
		color: #ffffff;
		@include mobile {
    		margin-left: 0;
    	}
    	@include tablet {
    		margin-left: 0;
    	}
	}
	h2{
		font-size: 48px;
		font-weight: 300;
		line-height: 1em;
		color: #ffffff;
		margin-top: 10px;
		display: block;
    	@include mobile {
    		font-size: 30px;
    	}
    	@include tablet {
    		font-size: 30px;
    	}
	}
	h1{
		font-size: 48px;
		font-weight: 700;
		line-height: 1.2em;
		color: #ffffff;
		margin-top: 10px;
		display: block;
		&:after{
			width: 20%;
		    height: 4px;
		    background-color: #00a0d2;
	    	margin-top: 17px;
	    	@include mobile {
		    	margin-top: 17px!important;
		    }
		}
    	@include mobile {
    		font-size: 30px;
    	}
    	@include tablet {
    		font-size: 30px;
    	}
	}
	h3{
		font-size: 32px;
		font-weight: 300;
		line-height: 1.34em;
		text-align: left;
		color: #ffffff;
	}
	p{
		font-size: 18px;
		font-weight: 400;
		line-height: 1.56em;
		color: #ffffff;
		margin-top: 20px;
    	@include mobile {
    		font-size: 16px;
    	}
		img{
			margin-right: 14px;
			margin-top: 18px;
			&:last-child{
				margin-right: 0
			}
		}
	}
}
.banner_big_paragraph{
	width: 951px;
	p{
		font-size: 46px;
		@include mobile {
		    font-size: 28px;
		}
	}
}
.has_top_space{
	margin-top: 74px;
}
.services_list_items{
	@extend %fullBlock;
    padding-bottom: 114px;

}
.services_border{
	border-bottom: 1px solid #d8d8d8;
    margin-bottom: 10px;
}
.services_item{
	float: right;
	margin-top: 89px;
	margin-bottom: 22px;
	@include mobile {
		width: 100%;
		margin-top: 40px;
	}
	@include tablet {
		margin-top: 40px;
	}
	.service_thumb{
		width: 50px;
		float: left;
		margin-right: 24px;
	}
	.service_content{
		width: 464px;
		float: left;
		@include mobile {
			margin-top: 10px;
			width: 100%;
		}
		@include tablet {
			margin-top: 10px;
			width: 100%;
		}
	}
}
.has_border_box{
	padding-bottom: 80px;
	.insight_box{
	    border-radius: 3px;
	    border: 1px solid #d8d8d8;
	}
}
.blue_section{
	height: 600px;
	background: #141F3C;
	@include mobile {
		height: auto;
	}
	.half_banner .new_banner_content{
		@include mobile {
			margin-top: 50px;
		}
	}
	.half_banner{
		top: 145px!important;
		@include tablet {
			top: 65px!important;
		}
		@include mobile {
			top: 0!important;
		}
	}
}
.right_half{
	width: 50%!important;
	position: absolute!important;
	right: 0;
	@include mobile {
		width: 100%!important;
		position: relative!important;	
	}
	img{
	    object-position: bottom;
	}
}
.has_pdf{
	a {
		font-size: 14px;
		font-weight: 300;
		line-height: 1.79;
		text-align: left;
		color: #2b2b2b;
		width: auto;
		text-decoration: underline;
		&:after{
			float: left;
			margin-left: 0;
			margin-right: 10px;
			width: 24px;
			height: 24px;
			background: url(../images/ic_file_download_black_24px.svg) no-repeat center;
		}
		&:before{
			display: none;
		}
	}
}
.projects_list{
	@extend %fullBlock;
	display: flex;
	justify-content: space-between;
    li{
    	float: left; 
		@include mobile{
			margin-top: 20px;
		}
    }
	@include tablet {
	    flex-wrap: wrap;
	    li{
	    	margin-right: 10px;
	    	margin-top: 15px;
	    }
	}
	@include mobile {
	    flex-wrap: wrap;
	}
}
.gecko,
.no-object-fit{
	.projects_list{
		li{
			width: auto;
			max-width: 20%;
		}
	}
}
.case_studies_sub_wrapper{
	@extend %fullBlock;
	position: relative;
}
.left_bar{
	float: left;
	width: 157px;
	@include mobile {
	    padding-left: 32px;
		width: auto
	}
}
.bar_title{
	@extend %fullBlock;
	padding-top: 36px;
	@include mobile {
		padding-top: 0;
		margin-bottom: -70px;
	}
	h3{
		font-size: 18px;
		font-weight: bold;
		line-height: 2.39em;
		color: #26415e;
		margin-top: -10px;
	}
}
.right_side{
	float: right;
	width: calc(100% - 157px);
	padding-right: 102px;
	position: relative;
	padding-left: 32px;
	padding-top: 36px;
	@include mobile {
		width: 100%;
		padding-right: 0;
	}
	&:before{
		content: "";
		height: 100%;
		width: 2px;
		position: absolute;
		left: 0;
		top: 0;
		float: left;
		background: rgba(147, 147, 147, 0.2); 
	}
}
.right_content{
	@extend %fullBlock;
	margin-top: -5px;
	p{
		font-size: 18px;
		font-weight: 300;
		line-height: 1.69em;
		text-align: left;
		color: #141f3c;
		margin-bottom: 30px;
		max-width: 822px;
	}
	img{
		margin-bottom: 30px;
	}
}
.sub_page_section{
	padding-top: 105px;
	padding-bottom: 80px;
	@include mobile {
		padding-top: 65px;
		padding-bottom: 70px;
	}
}
.iphones_holder{
	@extend %fullBlock;
	.iphone_holder{
		width: 232px;
		height: 420px;
		float: left;
		margin-right: 20px;
		position: relative;
		background: url(../images/iphone.png) no-repeat;
		img{
			margin-bottom: 0;
			position: absolute;
			left: 19px;
			top: 57px;
			width: 176px;
			height: 309px;
			object-fit: cover;
			font-family: "object-fit: cover;";
		}
	}
}
.pt0{
	padding-top: 0;
}
.right_side.pt0{
	@include mobile{
		padding-top: 36px;
	}
}

.left_landing{
	float: left;
	width: 47.5%;
	min-height: 100px;
	background-color: #f7f7f7;
	@include mobile {
		width: 100%;
	}
	@include tablet {
		width: 100%;
	}

	&_container{
	    width: 574px;
		float: right;
		padding-top: 53px;
		padding-bottom: 63px;
		@include mobile {
			width: 100%;
			padding: 33px 15px 43px;
		}
		@include tablet {
			width: 100%;
			padding: 33px 15px 43px;
		}
		@include tablet-big {
			width: 100%;
			padding-left: 15px; 
			padding-right: 15px; 
		}
		p{
			font-size: 18px;
			font-weight: 300;
			line-height: 1.79em;
			color: #2b2b2b;
			margin-bottom: 36px;
			max-width: 496px;
		}
		h1{
			font-size: 36px;
			font-weight: 300;
			line-height: 1.19em;
			color: #26415e;
			margin-bottom: 24px;
			@include mobile {
				font-size: 24px;
			}
		}
		h4{
			font-size: 22px;
			font-weight: 300;
			line-height: 1.19em;
			color: #26415e;
			margin-bottom: 24px;
			@include mobile {
				font-size: 20px;
			}
		}
	}
	img{
		margin-left: -60px;
		max-width: calc(100% + 120px);
		@include mobile {
			margin-left: 0;
			max-width: 100%;
		}
		@include tablet {
			margin-left: 0;
			max-width: 100%;
		}
		@include tablet-big {
			margin-left: 0;
			max-width: 100%;
		}
	}
}
.right_landing{
	width: 52.5%;
	float: right;
	@include mobile {
		width: 100%;
	}
	@include tablet {
		width: 100%;
	}
	&_container{
		width: 670px;
		padding-left: 63px;
		float: left;
		padding-top: 53px;
		padding-bottom: 70px;
		@include mobile {
			width: 100%;
			padding: 33px 15px 40px; 
		}
		@include tablet {
			width: 100%;
			padding: 33px 15px 40px; 
		}
		@include tablet-big {
			width: 100%;
			padding: 33px 15px 40px; 
		}
	}
	.gfield {
	    padding: 0;
	    width: 100%;
	}
	textarea,
	select,
	input{
		width: 100%;
		height: 50px;
		padding: 13px 15px; 
		float: left;
		outline: 0;
		margin-top: 36px; 
		background: #FFFFFF;
		border: 1px solid #EEEEEE;
		border-radius: 3px;
		font-size: 18px;
		line-height: normal;
		color: #8e8e8e;
		@include mobile {
			margin-top: 20px;
		}
		@include tablet-big {
			margin-top: 20px;
		}
		@include placeholder {
			font-size: 18px;
			line-height: normal;
			color: #8e8e8e;
		}
	}
	select{
		background: url(../images/bottom-arrow-select.png) no-repeat 96% #fff;
		/*for firefox*/
		-moz-appearance: none;
		/*for chrome*/
		-webkit-appearance:none;
		&::-ms-expand {
		    display: none;
		}
	}
	input[type="submit"]{
		float: right;
		width: 150px;
		border-radius: 3px;
		background: url(../images/right-long-arrow.svg) no-repeat #0098ff 102px center;
		font-family: 'Montserrat', sans-serif;
		cursor: pointer;
		text-align: left;
		font-size: 14px;
		font-weight: 500;
		color: #FFFFFF;
		letter-spacing: 0;
		margin-top: 77px;
		line-height: 14px;
		transition: $transition1;
		@include mobile {
			margin-top: 35px;
		}
		&:hover{
			background-position-x: 92%; 
		}
	}
	textarea{
		resize:none;
		height: 160px;
		overflow-x: hidden;
	}
	.gform_title{
		font-size: 16px;
		font-weight: 300;
		line-height: 1.69em;
		color: #141f3c;
		margin-bottom: 24px;
	}
	.gform_description{
		font-size: 14px;
		font-weight: 300;
		line-height: 1.79em;
		color: #2b2b2b;
		margin-bottom: 9px;
	}
}
.costum_article_form{
	.gfield {
	    padding: 0;
	    width: 100%;
	}
	textarea,
	select,
	input{
		width: 100%;
		height: 50px;
		padding: 13px 15px; 
		float: left;
		outline: 0;
		margin-top: 36px; 
		background: #FFFFFF;
		border: 1px solid #EEEEEE;
		border-radius: 3px;
		font-size: 18px;
		line-height: normal;
		color: #8e8e8e;
		@include mobile {
			margin-top: 20px;
		}
		@include tablet-big {
			margin-top: 20px;
		}
		@include placeholder {
			font-size: 18px;
			line-height: normal;
			color: #8e8e8e;
		}
	}
	select{
		background: url(../images/bottom-arrow-select.png) no-repeat 96% #fff;
		/*for firefox*/
		-moz-appearance: none;
		/*for chrome*/
		-webkit-appearance:none;
		&::-ms-expand {
		    display: none;
		}
	}
	input[type="submit"]{
		float: right;
		width: 150px;
		border-radius: 3px;
		background: url(../images/right-long-arrow.svg) no-repeat #0098ff 102px center;
		font-family: 'Montserrat', sans-serif;
		cursor: pointer;
		text-align: left;
		font-size: 14px;
		font-weight: 500;
		color: #FFFFFF;
		letter-spacing: 0;
		margin-top: 77px;
		line-height: 14px;
		transition: $transition1;
		@include mobile {
			margin-top: 35px;
		}
		&:hover{
			background-position-x: 92%; 
		}
	}
	textarea{
		resize:none;
		height: 160px;
		overflow-x: hidden;
	}
	.gform_title{
		font-size: 16px;
		font-weight: 300;
		line-height: 1.69em;
		color: #141f3c;
		margin-bottom: 24px;
	}
	.gform_description{
		font-size: 14px;
		font-weight: 300;
		line-height: 1.79em;
		color: #2b2b2b;
		margin-bottom: 9px;
	}
	
}
.gfield_error input {
    border: 1px solid red;
}
.gfield_description.validation_message{
	display: none;
}
.validation_error{
	display: none;
}
.no_margin{
	p{
		margin-top: 0;
	}
}
.full-image{
	position: absolute;
	height: 100%;
	left: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	@include mobile {
		position: relative;
		height: 350px;
	}
	@include tablet {
		position: relative;
		height: 450px;
	}
}
.case_studies_new_left{
	width: 50%;
	@include mobile {
		width: 100%;
	}
	@include tablet {
		width: 100%;
	}
	.left_landing_container{
		float: left;
		width: 100%;
		padding-left: 60px;
		padding-right: 60px;
		p{
			max-width: 100%;
		}
		@include mobile {
			padding: 33px 15px 43px;
		}
		@include tablet {
			padding: 33px 15px 43px;
		}
	}
	.btn_out{
		margin-top: 0px;
		text-align: left;
	}
}
.relativ_wrapper{
	position: relative;
}
.in-line-case-studies{
	padding-top: 0px;
	background-color: #fff;
	@include mobile {
		padding-top: 0;
	}
	@include tablet {
		padding-top: 0!important;
	}
}
.list_of_case_studies_post{
	width: 100%;
    cursor: ew-resize;
    cursor: -webkit-grab;
	li{
		width: 100%;
		float: left;
		padding: 0 10px;
		.box{
			height: 280px;
			&:hover{
				.box__content{
					a{
						margin-bottom: 0
					}
				}
			}
		}
		.box__thumb{
			img{
				height: 100%;
			}
		}
		.box__content{
			padding-left: 30px;
			padding-right: 30px;
			bottom: -74px;
			@include mobile {
				bottom:-94px;
			}
			a{
				margin-bottom: -30px;
			}
		}
	}
}
.flex_show_in_line{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.desktop_t120{
	@include desktop{
		top: 120px;
	}
}
.bold_title{
	h1{
		font-weight: 700;
	}
}