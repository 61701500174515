.headerWrap{
	padding: 14px 0;
	position: fixed;
	transition:$transition1;
	border-bottom: 1px solid transparent;
}
.main_header{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999999;
	@extend %fullBlock;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.headerWrap.is--sticky{
	padding: 14px 0;
	background: rgba(26, 50, 68, .96); 
	border-bottom-color:  rgba(26, 50, 68, .82);
}
.logo_wrapper{
    width: 165px;
    height: 34px;
	float: left;
    margin-top: 4px;
}
.right_header{
	float: right;
	.hamburger{
		float: right;
		cursor:pointer;
		&:hover{
			span{
				&:nth-child(2){
					&:after{
						width: 6px;
					}
					&:before{
						width: 25px;
					}	
				}
				&:after{
					width: 6px;
				}
				&:before{
					width: 25px;
				}
			}
		}
		span{
			float: left;
			clear: both;
			width: 35px;
			margin-bottom: 7px;
			transition:$transition1;
			&:last-child{
				margin-bottom: 0
			}
			&:nth-child(2){
				&:after{
					float: right;
				}
				&:before{
					float: left;
				}	
			}
			&:after{
				content: "";
				width: 25px;
				height: 2px; 			
				transition:$transition2;
				float: left;
				background-color: #fff;
			}
			&:before{
				content: "";
				transition:$transition2;
				float: right;
				width: 6px;
				height: 2px;
				background-color: #fff;
			}
		}
	}
}
body.menu--active{
	transition:$transition1;
	width: 100%;
	float: left;
}

.nav_wrapper{
	float: left;
	margin-top: 14px;
	li{
		float: left;
		margin-right: 20px;
		a{
			font-size: 14px;
			font-weight: 400;
			float: left;
			padding: 0 10px;
			color: #fff;
			text-decoration: none;
			letter-spacing: 0;
			&:after{
				content: "";
				height: 2px;
				width: 0;
				display: block;
				transition: all .35s cubic-bezier(0.860, 0.000, 0.070, 1.000);
				margin-top: 2px;
				background-color: #1a3347;
			}
			&:hover{ 
				&:after{
					width: 100%;
				}
			}
		}
	}
}
.list_links_header{
	float: right;
	a{
		text-decoration: none;
		letter-spacing: 0;
		float: left;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		padding: 11px 12px;
		color: #26415e;
		width: 153px;
		height: 42px;
		border-radius: 3px;
		border: solid 1px #26415e;
		transition: $transition1;
		&:before{
			content: "";
			background: url(../images/arrow_right_blue.svg) no-repeat; 
			width: 12px;
			height: 12px;
			float: right;
			margin-top: 3px;
			margin-left: 3px;
		}
		&:hover{
			background-color: #26415e;		
			color: #fff;
			&:before{
				background: url(../images/arrow_right_white_small.svg) no-repeat; 
			}
		}
	}
}
.white_header{
	.headerWrap{
		background: #fff;
	}
	.nav_wrapper {	
		li {
			a {
				color: #1A3347;
				font-weight: 500;
			}
		}
	}
	.list_links_header {
		a {
			font-weight: 500;
			&:after{
				background-color: rgba(60, 178, 184, 0.77);
			}
		}
	}
}
.main_header.header-up{
	.headerWrap{
	    transform: translateY(-100%);
	}
}

@include desktop{
	.headerWrap{
		.menu-item-has-children{
		    position: relative;
		    padding-bottom: 10px;
			&:hover{
				ul{
					visibility: visible;
					opacity: 1;
					z-index: 1;
					transition: none;
				}
			}
			ul{
				opacity: 0;
				visibility: hidden;
				width: 324px;
				float: left;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: 36px;
				box-shadow: 0px -5px 14px 0px rgba(0,0,0,.13);
				transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.0s, z-index 0s linear 0.00s;
				&:after{
					bottom: 100%;
					left: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(255, 255, 255, 0);
					border-bottom-color: #ffffff;
					border-width: 10px;
					margin-left: -10px;
				}
			}
			li{
				float: left;
				width: 100%;
				margin-right: 0;
				a{
					float: left;
					padding: 26px 20px;
					height: 67px;
					font-size: 14px;
					color: #1a3347;
					background-color: #fff;
					width: 100%;
					border-bottom: 1px solid #e7e7e7;
					padding-left: 74px;
					background-repeat: no-repeat;
				    background-position: 20px;
				    transition: $transition1;
					&:after{
						display: none;
					}
					&:hover{
						background-color: #f7f7f7;
					}
				}
			}
			.web_software_item{
				a{
					background-image: url(../images/web-dev-copy.svg);
				}
			} 
			.integrations_item{
				a{
					background-image: url(../images/integra.svg);
				}
			} 
			.automation_item{
				a{
					background-image: url(../images/settings-gears.svg);
				}
			} 
			.cloud_item{
				a{
					background-image: url(../images/cloud-computing.svg);
				}
			} 
		}
	}
}
@include tablet-big{
	.headerWrap{
		.menu-item-has-children{
		    position: relative;
		    padding-bottom: 10px;
			&:hover{
				ul{
					visibility: visible;
					opacity: 1;
					z-index: 1;
					transition: none;
				}
			}
			ul{
				opacity: 0;
				visibility: hidden;
				width: 324px;
				float: left;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: 36px;
				box-shadow: 0px -5px 14px 0px rgba(0,0,0,.13);
				transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.0s, z-index 0s linear 0.00s;
				&:after{
					bottom: 100%;
					left: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(255, 255, 255, 0);
					border-bottom-color: #ffffff;
					border-width: 10px;
					margin-left: -10px;
				}
			}
			li{
				float: left;
				width: 100%;
				margin-right: 0;
				a{
					float: left;
					padding: 26px 20px;
					height: 67px;
					font-size: 14px;
					color: #1a3347;
					background-color: #fff;
					width: 100%;
					border-bottom: 1px solid #e7e7e7;
					padding-left: 74px;
					background-repeat: no-repeat;
				    background-position: 20px;
				    transition: $transition1;
					&:after{
						display: none;
					}
					&:hover{
						background-color: #f7f7f7;
					}
				}
			}
			.web_software_item{
				a{
					background-image: url(../images/web-dev-copy.svg);
				}
			} 
			.integrations_item{
				a{
					background-image: url(../images/integra.svg);
				}
			} 
			.automation_item{
				a{
					background-image: url(../images/settings-gears.svg);
				}
			} 
			.cloud_item{
				a{
					background-image: url(../images/cloud-computing.svg);
				}
			} 
		}
	}
}
/* font-family: 'Rubik', sans-serif;
font-family: 'Source Sans Pro', sans-serif; */