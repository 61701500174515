.main_footer{
	position: relative;
	height: 500px;
	background-color: #fff;
	.home_banner_thumb{
		width: 100%;
	    height: 500px;
	    float: left;
	    position: absolute;
	    display: flex;
		img{
			@extend %imgCropped;
			font-family: 'object-fit:cover;';	
			opacity: 0.25;
			.no-objectfit & {
		    	opacity: 0.45;
		    }
		}
		.overlay{
	   	 	background-size: 100%;
			background-color: #101a33; 
		    mix-blend-mode: multiply;
		    -webkit-mix-blend-mode: multiply;
		    -moz-mix-blend-mode: multiply;
		    -ms-mix-blend-mode: multiply;
		    -o-mix-blend-mode: multiply;
		    .no-objectfit & {
		    	opacity: .92;
		    }
		}
	}
}
.footerWrap{
	@extend %fullBlock;
	padding: 136px 97px 38px;
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
	@include desktop{
	    .container{
	    	height: 100%;
	    }
	    .bottom_footer{
	    	position: absolute;
	    	bottom: 0;
	    }
	}
	@include tablet-big{
	    .container{
	    	height: 100%;
	    }
	    .bottom_footer{
	    	position: absolute;
	    	bottom: 0;
	    }
	}
}
.left_footer{
	width: 225px;
	float: left;
	img{
		width: 100%;
		object-fit:cover;
		font-family: 'object-fit:cover;';
	}
}
.right_footer{
	width: auto;
	float: right;
	@include desktop{
		margin-right: 85px;
	}
	.menu-item-has-children{
		width: auto;
		float: left;
		margin-left: 70px;
		a{
			font-family: 'Rubik', sans-serif;
			color: #fff;
			font-size: 18px;
			font-weight: 400;
		}
		ul{
			margin-top: 15px;
		}
		li{
			margin-top: 10px;
			a{
				font-family: 'Rubik', sans-serif;
				text-decoration: none;
				color:  #929AA6;
				font-size: 14px;
				font-weight: 400;
				transition:$transition1;
				&:hover{
					color: #fff;
				}
			}
		}
	}
	.dropdown{
		a{
			cursor: pointer;
			&:after{
				content: "";
				border: solid #fff;
				cursor: pointer;
				margin-left: 16px;
			    border-width: 0 1px 1px 0;
			    display: inline-block;
				float: right;
				margin-top: 4px;
			    padding: 3px;
			    transform: rotate(45deg);
			}
		}
		ul{
			display: none;
			a{
				&:after{
					display: none;
				}
			}
		}
	}
}
.bottom_footer{
	@extend %fullBlock;
	margin-top: 120px;
	p{
		font-family: 'Rubik', sans-serif;
		font-size: 14px;
		color: #fff;
		margin-top: 10px;
		float: left;
	}
	.line_gotop_holder{
		@extend %fullBlock;
	}
	.line{
		width: calc(100% - 84px);
		margin-right: 24px;
		height: 2px;
		opacity: 0.2;
		background-color: #FFFFFF
	}
	.go_top{
		width: 60px;
		float: right;
		height: 60px;
		border-radius:100%; 
		background-image: url(../images/arrow-up.svg);
		background-repeat: no-repeat;
		margin-top: -30px;
		z-index: 2;
		position: relative;
		background-position: center;
		&:after{
			content: "";
			width: 60px;
			height: 60px;
			float: left;
			z-index: 1;
			position: relative;
			border-radius: 100%;
			border: 1px solid transparent;
			background-image: linear-gradient(248deg, #00e3c8, #5f5aff);
			opacity: 0;
			transition: all .35s cubic-bezier(.86,0,.07,1);
		}
		&:before{
		    content: "";
		    width: 15px;
		    height: 15px;
		    background: url(../images/down-arrow-white.svg) no-repeat;
		    transform: rotate(-180deg);
		    background-size: 15px;
		    float: right;
		    position: absolute;
		    right: 22px;
		    bottom: 23px;
		    z-index: 3;
			transition: all .35s cubic-bezier(.86,0,.07,1);
			opacity: 0;
		}
		&:hover{
			&:before,
			&:after{
				opacity: 1;
			}
		}
	}
}